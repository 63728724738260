import { Component } from "react";
import { Panel } from "../../../../../components/design_library";
import {
  Heading,
  ListItem,
  OrderedList,
  Stack,
  Text,
  Box,
  Image,
  Tab,
  TabPanel,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  HStack,
  Divider,
  Input,
  InputRightAddon,
  InputGroup,
  InputRightElement,
  Button,
  UnorderedList,
} from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { AccountAtom, AppStateAtom } from "../../../../../store";

export default class FidelityInstructions extends Component {
  render() {
    return (
      <Panel>
        <Stack spacing="5">
          <Heading as="h3" size="lg">
            Instructions
          </Heading>

          <Text>
            Fidelity provides only the date and not the time of the trade.
            Therefore please only upload your trades at the end of the day after
            you've finished trading to avoid any trades being missed.
          </Text>

          <OrderedList pl="5">
            <ListItem>Log on to your Fidelity Investments account.</ListItem>
            <ListItem>
              Select your account and click Activity & Orders followed by
              History.
            </ListItem>
            <ListItem>Select a Time Period and click the Go button.</ListItem>
            <ListItem>
              Wait for the data to be displayed, then click the Download link to
              begin the csv file download.
            </ListItem>
            <ListItem>
              Click the Save button on the popup File Download dialog box.
            </ListItem>
          </OrderedList>
        </Stack>
      </Panel>
    );
  }
}

export const FidelityEmailForTradeConfirmations = () => {
  const accountState = useAtomValue(AccountAtom);
  const appState = useAtomValue(AppStateAtom);
  const accountId = accountState?.selectedAccount?.id;
  const userId = appState.user.id;
  const broker = "FIDELITY";
  const email = `ccf73397122224a7221c+${userId}+${accountId}+${broker}@cloudmailin.net`;

  return (
    <Box>
      <Heading size="md">Trade Confirmation Emails</Heading>
      <Text mt="2">
        It's possible to update your account automatically using Fidelity's
        trade confirmations emails. Please note that this contain limited
        information (Symbol, strike, expiry, price, quantity, action). (We
        recommend using the CSV upload method for accurate trade information)
      </Text>

      <OrderedList mt="4">
        <ListItem>Copy this email address</ListItem>

        <InputGroup size="md" mt="4">
          <Input pr="4.5rem" type={"text"} value={email} />
          <InputRightElement width="4.5rem">
            <Button
              h="1.75rem"
              size="sm"
              onClick={() => {
                navigator.clipboard.writeText(email);
              }}
            >
              Copy
            </Button>
          </InputRightElement>
        </InputGroup>

        <ListItem mt="2">
          Forward your trade confirmations to this address to automatically
          import new trades. (You can test it now, it takes 5 minutes to
          process)
        </ListItem>

        <ListItem mt="2">
          You can automate this by setting up rules in your email client. Here
          is a guide{" "}
          <a
            style={{ color: "var(--accent)" }}
            href="https://clean.email/blog/email-management/automatic-email-forwarding"
          >
            to set up forwarding.
          </a>
        </ListItem>
      </OrderedList>
    </Box>
  );
};
