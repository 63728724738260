import {
  Box,
  Divider,
  HStack,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import styles from "./Footer.module.css";
import { footerLinks } from "./_data";
import { Link } from "react-router-dom";
import moment from "moment";
import { Logo } from "../menu/Logo";
import React from "react";

const Footer = (props: any) => {
  return (
    <Box
      as="footer"
      id="footer"
      className={styles.footer}
      w={{ base: "100%", md: "100%", lg: "100%", xl: "100%" }}
      maxW={"8xl"}
      margin="auto"
      mt="40px"
    >
      <HStack
        display="flex"
        justifyContent={"space-between"}
        pb="12px"
        pl="20px"
        pr="20px"
        w="full"
        wrap={"wrap"}
      >
        <VStack justifyContent={"flex-start"} alignItems={"flex-start"}>
          <Box maxH="40px">
            <Logo />
          </Box>
          <Text color="var(--light-gray)" fontSize="14px">
            <a href="https://wealthbee.io">
              © {moment().format("YYYY")} WealthBee Ltd.
            </a>
          </Text>
          <Text fontSize="14px" maxW="600px" color="var(--light-gray)">
            WealthBee is your trading journal. Keep track of your investments
            and grow your wealth. Supporting stocks, options & futures.
            WealthBee was developed in London, UK by traders, for traders.
          </Text>
        </VStack>

        <Wrap>
          <WrapItem>
            <HStack
              w="500px"
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              textAlign={"left"}
            >
              <VStack alignItems={"flex-start"}>
                <Text
                  fontWeight="600"
                  fontSize="14px"
                  color="var(--light-gray)"
                >
                  Product
                </Text>
                <FooterLink href={"../app/register"}>{"Register"}</FooterLink>
                <FooterLink href={"../app/login"}>{"Log in"}</FooterLink>
              </VStack>
              <VStack alignItems={"flex-start"}>
                <Text
                  fontWeight="600"
                  fontSize="14px"
                  color="var(--light-gray)"
                >
                  Customer Support
                </Text>
                <FooterLink href={"../knowledgebase"}>{"FAQ"}</FooterLink>
                <FooterLink href={"https://discord.gg/5N9rJHhAbS"}>
                  {"Community"}
                </FooterLink>
                <FooterLink href={"../contact"}>{"Contact us"}</FooterLink>
              </VStack>
              <VStack alignItems={"flex-start"}>
                <Text
                  fontWeight="600"
                  fontSize="14px"
                  color="var(--light-gray)"
                >
                  Resources
                </Text>
                <FooterLink href={"../roadmap"}>{"Roadmap"}</FooterLink>
                <FooterLink href={"../blog"}>{"Blog"}</FooterLink>
                <FooterLink href={"../on-the-internet"}>{"Media"}</FooterLink>
              </VStack>
              <VStack alignItems={"flex-start"}>
                <Text
                  fontWeight="600"
                  fontSize="14px"
                  color="var(--light-gray)"
                >
                  Company
                </Text>
                <FooterLink href={"../about"}>{"About us"}</FooterLink>
              </VStack>
            </HStack>
          </WrapItem>
        </Wrap>
      </HStack>

      <Divider mt="10px" />

      <HStack>
        <Text fontSize="12px" p="20px" opacity="0.8">
          WealthBee does not provide investment advice and individual investors
          should make their own decisions or seek independent advice. The value
          of investments can go down as well as up and you may receive back less
          than your original investment. Copyright © 2024 WealthBee, All rights
          reserved.
        </Text>

        <Wrap>
          <WrapItem>
            <HStack
              w="full"
              justifyContent={"space-between"}
              alignItems={"flex-start"}
              textAlign={"left"}
              wrap={"wrap"}
            >
              <FooterLink href={"https://wealthbee.io/privacy"}>
                {"Privacy Policy"}
              </FooterLink>
              <FooterLink href={"https://wealthbee.io/terms"}>
                {"Terms & Conditions"}
              </FooterLink>
              <FooterLink href={"../sitemap.xml"}>{"Sitemap"}</FooterLink>
              <FooterLink href={"https://optiontracker.io/"}>
                {"Option Tracker"}
              </FooterLink>
            </HStack>
          </WrapItem>
        </Wrap>
      </HStack>
    </Box>
  );
};

const FooterLink = (props: any) => {
  return (
    <Link to={props.href} className={styles.footerLink}>
      <Text fontSize={"14px"}>{props.children}</Text>
    </Link>
  );
};

export default Footer;
