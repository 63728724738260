import { HStack, Text } from "@chakra-ui/react";
import { FaBookOpen } from "react-icons/fa";

const BookRender = (params: any) => {
  return (
    <HStack justifyContent={"space-between"} w="100%">
      <FaBookOpen color={params.value === "" ? "var(--red)" : "var(--white)"} />
      <Text
        color={params.value === "" ? "var(--red)" : "var(--white)"}
        fontWeight={600}
      >
        {params.value === ""
          ? `Unbooked (${params.node.allChildrenCount})`
          : `${params.value} (${params.node.allChildrenCount})`}
      </Text>
    </HStack>
  );
};

export default BookRender;
