export const defaultColumn = {
  flex: 1,
  sortable: true,
  resizable: true,
  // sideBar: true,
  enableValue: true,
  enableRowGroup: true,
  enablePivot: false,
  // suppressPivotMode: true,
  // suppressPivots: true,
  // enableCellTextSelection: false,
};
