import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { AppStateAtom } from "../../../store";
import { login } from "../../../api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GetGoogleOAuthLogin } from "../../../api/GetGoogleOAuthLogin";
import { useUser } from "../../../hooks/useUser";
import { useToast } from "@chakra-ui/react";
import mixpanel from "mixpanel-browser";
import { mix } from "framer-motion";

interface LoginController {
  email: string;
  formPasswordError: string;
  formEmailError: string;
  errorMessage: string;
  loading: boolean;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
  login: () => void;
}

export const useLoginController = () => {
  const [appState, setAppState] = useAtom(AppStateAtom);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [formEmailError, setFormEmailError] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formPasswordError, setFormPasswordError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useUser();
  const toast = useToast();

  const navigate = useNavigate();

  useEffect(() => {
    let code = searchParams.get("code");
    let scope = searchParams.get("scope");

    if (code && scope) {
      setLoading(true);
      GetGoogleOAuthLogin(code, scope).then((data) => {
        mixpanel.track("Google OAuth Login", {
          email: data.email,
          success: data.success,
        });

        mixpanel.identify(data.user.email);
        mixpanel.alias(data.user.email);

        if (data.success === true) {
          window.localStorage.setItem("token", data.token);
          window.location.href = "/app/dashboard";
        }
      });
    }
  }, []);

  const peformLogin = async () => {
    mixpanel.track("Login Attempt", {
      email: email,
    });

    setLoading(true);
    user.setLoading(true);

    setTimeout(() => {
      login(email, password)
        .then((res) => {
          setFormEmailError("");
          setFormPasswordError("");

          if (res.success === true) {
            mixpanel.identify(res.user.email);
            mixpanel.alias(res.user.email);
            mixpanel.people.set({
              $email: res.user.email,
              $name: res.user.email.split("@")[0],
            });
            mixpanel.track("Logged In", {
              email: res.user.email,
            });

            user.setLoginSettings(email, res.user.gridTheme, res.token);
            setTimeout(() => {
              navigate("/app/dashboard");
              setLoading(false);
            }, 200);
          } else {
            if (res.success === false) {
              mixpanel.track("Login Failed", {
                email: email,
              });

              if (res.errors) {
                res.errors.filter((e: any) => {
                  if (e.path === "email") {
                    setFormEmailError(e.msg);
                    toast({
                      title: "Error",
                      description: e.msg,
                      status: "error",
                      duration: 4000,
                      isClosable: true,
                    });
                  }
                  if (e.path === "password") {
                    setFormPasswordError(e.msg);
                    toast({
                      title: "Error",
                      description: e.msg,
                      status: "error",
                      duration: 4000,
                      isClosable: true,
                    });
                  }
                });
              } else {
                mixpanel.track("Login Failed", {
                  email: email,
                  error: res.message,
                });

                setErrorMessage(res.message);
                toast({
                  title: "Error",
                  description: res.message,
                  status: "error",
                  duration: 4000,
                  isClosable: true,
                });
              }
              setLoading(false);
            }
            user.logUserOut();
          }
        })
        .catch((e: any) => {
          mixpanel.track("Login Failed", {
            email: email,
            error: e.message,
          });

          console.log("API Call, error call login", e);
          user.logUserOut();
          setLoading(false);
        });
    }, 200);
  };

  return {
    email,
    setEmail,
    password,
    loading,
    setPassword,
    errorMessage,
    login: peformLogin,
    formEmailError,
    formPasswordError,
  } as LoginController;
};
