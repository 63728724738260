import React from "react";
import { Input, Stack, Text } from "@chakra-ui/react";
import { Required } from "./Required";

export const ReadOnly = (props: { label: string; value: string }) => {
  return (
    <Stack spacing="4" mt="2" mb="1">
      <Text>{props.label}</Text>
      <Text>{props.value}</Text>
    </Stack>
  );
};
