import React from "react";
import { Input, Stack, Text } from "@chakra-ui/react";
import { Required } from "./Required";

export const TextInput = (props: {
  label: string;
  value: string;
  field: string;
  required: boolean;
  error: string;
  onChange: (field: string, value: string) => void;
}) => {
  return (
    <Stack spacing="0">
      <Text>
        {props.label}
        {props.required && <Required />}
      </Text>
      <Input
        required={props.required}
        type="text"
        value={props.value}
        onChange={(e) => props.onChange(props.field, e.target.value)}
      />
      <Text color="red.500">{props.error}</Text>
    </Stack>
  );
};
