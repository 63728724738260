import { useAtom, useAtomValue } from "jotai";
import {
  createDashboardConfiguration,
  getInsightsDatasets,
  getLayoutForAccount,
  updateLayoutForAccount,
} from "../../../api";
import {
  AccountAtom,
  PositionStateAtom,
  TransactionsAtom,
} from "../../../store";
import { DashboardLayoutStateStore } from "../../../store/DashboardLayout";
import React, { useEffect, useCallback, useState } from "react";
import { Layout } from "react-grid-layout";
import { useFilters } from "../../../hooks/useFilters";
import moment from "moment";
import { useDebounce } from "../../hooks";

interface DashboardLayout {
  [key: string]: any[];
  [key: number]: any[];
}

interface LayoutConfig {
  config: {
    [key: string]: any[];
    [key: number]: any[];
  };
  enabledInsights: string[];
}

interface Insight {
  insightId: string;
  [key: string]: any;
}

export interface UseGridLayoutController {
  fetchLayouts: () => void;
  updateLayout: (updatedLayoutAtBreakpoint: Layout[]) => void;
  setBreakpoint: (breakpoint: string) => void;
  handleResize: () => void;
  loading: boolean;
}

export const useGridLayoutController = (): UseGridLayoutController => {
  const filters = useFilters();
  const [dashboardLayoutStore, setDashboardingLayoutState] = useAtom(
    DashboardLayoutStateStore
  );
  const [loading, setLoading] = useState<boolean>(false);

  const accountState = useAtomValue(AccountAtom);

  const setBreakpoint = useCallback(
    (breakpoint: string) => {
      setDashboardingLayoutState((prevState) => ({
        ...prevState,
        currentBreakpoint: breakpoint,
      }));
    },
    [setDashboardingLayoutState]
  );

  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    if (width >= 1200) {
      setBreakpoint("lg");
    } else if (width >= 992) {
      setBreakpoint("md");
    } else if (width >= 768) {
      setBreakpoint("sm");
    } else if (width >= 576) {
      setBreakpoint("xs");
    } else {
      setBreakpoint("xxs");
    }
  }, [setBreakpoint]);

  const getDashboardConfiguration = useCallback(async (): Promise<
    LayoutConfig | undefined
  > => {
    try {
      const result = await getLayoutForAccount(
        accountState?.selectedAccount?.id
      );
      if (result.success) {
        return result;
      } else {
        const createdLayout = await createDashboardConfiguration(
          accountState?.selectedAccount?.id
        );

        return createdLayout;
      }
    } catch (error) {
      console.error("Error fetching dashboard configuration:", error);
    }
  }, [accountState?.selectedAccount?.id]);

  const fetchInsightsDatasets = useCallback(async () => {
    try {
      const response = await getInsightsDatasets(
        accountState?.selectedAccount?.id,
        filters.selectedUnderlying,
        filters.selectedTag,
        filters.selectedStrategy,
        filters.selectedBook,
        filters.selectedPositionGroup,
        moment(filters.fromDate).format("YYYY-MM-DD").toString(), // Ensure fromDate filter is included
        moment(filters.toDate).format("YYYY-MM-DD").toString() // Ensure toDate filter is included
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching insights datasets:", error);
    }
  }, [
    accountState?.selectedAccount?.id,
    filters.selectedUnderlying,
    filters.selectedTag,
    filters.selectedStrategy,

    filters.selectedPositionGroup,
    filters.selectedBook,
    filters.fromDate, // Add fromDate to dependency array
    filters.toDate, // Add toDate to dependency array
  ]);

  const fetchLayouts = useCallback(async () => {
    setLoading(true);
    const layoutConfig = await getDashboardConfiguration();
    if (!layoutConfig || !layoutConfig.config) return;
    const datasets = await fetchInsightsDatasets();
    const newLayoutData: DashboardLayout = {
      lg: [],
      md: [],
      sm: [],
      xs: [],
      xxs: [],
    };
    dashboardLayoutStore.breakpoints.forEach((breakpoint) => {
      newLayoutData[breakpoint] = layoutConfig.config[breakpoint].map(
        (item: any) => {
          const insight = datasets.find(
            (i: Insight) => parseInt(i.insightId) === parseInt(item.i)
          );
          return { ...item, data: insight };
        }
      );
    });
    setDashboardingLayoutState((prevState) => ({
      ...prevState,
      layouts: newLayoutData,
      gridLoading: false,
      enabledInsights: layoutConfig.enabledInsights || [],
    }));

    setLoading(false);
  }, [
    accountState?.selectedAccount?.id,
    filters.selectedUnderlying,
    filters.selectedTag,
    filters.selectedStrategy,

    filters.selectedPositionGroup,
    filters.selectedBook,
  ]);

  const mergeDataIntoLayout = (layout: Layout[], data: Insight[]) => {
    return layout.map((item) => {
      try {
        const insight = data.find((i) => {
          if (i.insightId) {
            return parseInt(i.insightId) === parseInt(item.i);
          } else {
            console.log("Insight Id not found...");
          }
        });

        return { ...item, data: insight };
      } catch (e) {
        console.error("Error merging data into layout:", e);
        return item;
      }
    });
  };

  const updateLayout = useCallback(
    async (updatedLayoutAtBreakpoint: Layout[]) => {
      const updatedLayout = mergeDataIntoLayout(
        updatedLayoutAtBreakpoint,
        dashboardLayoutStore.layouts[
          dashboardLayoutStore.currentBreakpoint
        ].map((item: any) => item.data)
      );

      const update = await updateLayoutForAccount(
        accountState?.selectedAccount?.id,
        {
          layout: {
            ...dashboardLayoutStore.layouts,
            [dashboardLayoutStore.currentBreakpoint]: updatedLayout,
          },
        }
      );

      setDashboardingLayoutState({
        ...dashboardLayoutStore,
        layouts: {
          ...dashboardLayoutStore.layouts,
          [dashboardLayoutStore.currentBreakpoint]: updatedLayout,
        },
        enabledInsights: update.enabledInsights,
      });
    },
    [
      accountState?.selectedAccount?.id,
      dashboardLayoutStore,
      setDashboardingLayoutState,
    ]
  );

  return {
    fetchLayouts,
    updateLayout,
    setBreakpoint,
    handleResize,
    loading,
  };
};
