import React from "react";
import { Box, Flex } from "@chakra-ui/react";

export const TagsColumn = (props: any) => {
  return (
    <Flex
      justifyContent="flex-start"
      alignItems="center"
      height="36px"
      lineHeight="100%"
      pt="2px"
    >
      {props.value
        ? props.value.map((x: string, idx: number) => {
            const bgColour = idx % 2 === 0 ? "gray.600" : "blue.700";
            return (
              <Box
                key={x}
                height="20px"
                lineHeight="100%"
                fontSize="14px"
                fontWeight={400}
                display="flex"
                justifyContent="center"
                alignItems="center"
                color="var(--white)"
                padding="4px 12px 4px 12px"
                borderRadius="24px"
                bg={bgColour}
                mr="2px"
              >
                {x}
              </Box>
            );
          })
        : undefined}
    </Flex>
  );
};
