import React from "react";
import { InsightHeader } from "./InsightHeader";
import { InsightTemplate } from "../../types/Insight";
import { InsightRenderer } from "../../features/dashboard/canvas/insight/InsightRenderer";
import InsightWrapper from "./InsightWrapper";

export const InsightContainer = ({
  insight,
  demo,
}: {
  insight: InsightTemplate | null;
  demo?: boolean;
}) => {
  if (!insight) return null;

  return (
    <InsightWrapper>
      <InsightHeader insight={insight} />
      <InsightRenderer insight={insight} demo={demo} />
    </InsightWrapper>
  );
};
