import { useTour } from "@reactour/tour";
import { useEffect, useState } from "react";

export default function Tutorial() {
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } =
    useTour();

  useEffect(() => {
    setTimeout(() => {
      if (
        window.location.href.includes("positions?tutorial=true") ||
        window.location.href.includes("positions?result=paid")
      ) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }, 500);
  }, []);

  return <></>;
}
