import { Text } from "@chakra-ui/react";

interface FormTextProps {
  value?: string;
}

export const FormText = (props: FormTextProps) => {
  return (
    <Text
      size="sm"
      border="1px"
      borderColor="whiteAlpha.300"
      color="whiteAlpha.500"
      borderRadius={2}
      fontFamily="inter"
      lineHeight="32px"
      fontSize="14px"
      px="12px"
      height="32px"
      fontWeight={400}
    >
      {props.value ?? ""}
    </Text>
  );
};
