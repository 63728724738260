import React from "react";
import { useAtomValue } from "jotai";
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { Config } from "./config/Config";
import { Instruments } from "./instruments/Instruments";
import { AppStateAtom } from "../../store";
import Users from "./users/Users";
import Files from "./files/Files";
import WebsiteSettings from "./settings/WebsiteSettings";
import TradeConfirmation from "./tradeconfirmation/TradeConfirmation";
import KnowledgeBaseAdmin from "./knowledgebase/KnowledgeBaseAdmin";
import Images from "./images/Images";
import BlogAdmin from "./blogs/BlogAdmin";

export const Admin = () => {
  const appState = useAtomValue(AppStateAtom);

  if (!appState.authed || appState.user?.role !== "admin") {
    return null;
  }

  return (
    <Flex h="full" w="full" p={2}>
      <Tabs h="full" w="full" isLazy={true}>
        <TabList>
          <Tab>Instruments</Tab>
          <Tab>Config</Tab>
          <Tab>Users</Tab>
          <Tab>Uploads</Tab>
          <Tab>Settings</Tab>
          <Tab>Trade Confirmations</Tab>
          <Tab>Knowledge Base</Tab>
          <Tab>Blogs</Tab>
          <Tab>Images</Tab>
        </TabList>

        <TabPanels h="calc(100% - 35px)">
          <TabPanel h="full" w="full" p="0">
            <Instruments />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Config />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Users />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Files />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <WebsiteSettings />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <TradeConfirmation />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <KnowledgeBaseAdmin />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <BlogAdmin />
          </TabPanel>
          <TabPanel h="full" w="full" p="0">
            <Images />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
