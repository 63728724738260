import {
  Drawer,
  DrawerContent,
  DrawerBody,
  DrawerOverlay,
} from "@chakra-ui/react";
import styles from "./index.module.css";
import "./style.css";

export const CustomDrawer = (props: any) => {
  return (
    <Drawer
      size="lg"
      className={styles.customDrawer}
      // placement="bottom"

      {...props}
    >
      {props.overlayEnabled && <DrawerOverlay />}
      <DrawerContent className={styles.customDrawerContent}>
        <DrawerBody
          paddingLeft={props.paddingLeft ? props.paddingLeft : "32px"}
          paddingRight={props.paddingRight ? props.paddingRight : "32px"}
        >
          {props.body}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
