import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  VStack,
  HStack,
  Checkbox,
  Tag,
  TagLabel,
  TagCloseButton,
  Text,
  Select,
} from "@chakra-ui/react";
import { useFormik, FormikHelpers } from "formik";
import { createInsight, updateInsight } from "../../../../api";
import { useNotifications } from "../../../../hooks/useNotifications";
import { InsightTemplate, InsightType } from "../../../../types/Insight";
import { InsightContainer } from "../../../../components/insights/InsightContainer";

interface InsightsCreatorProps {
  insightsData: InsightTemplate;
  setInsightData: (insight: InsightTemplate) => void;
  setPage: (page: string) => void;
}

const InsightsCreator: React.FC<InsightsCreatorProps> = ({
  insightsData,
  setInsightData,
  setPage,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [mode, setMode] = useState<"create" | "update">("create");
  const notifications = useNotifications();

  useEffect(() => {
    setMode(insightsData.title === "" ? "create" : "update");

    if (JSON.stringify(insightsData) === "{}") {
      setInsightData({
        title: "XX INSIGHT",
        type: InsightType.Number,
        query: "SELECT * FROM example_table",
        description: "This is an example insight.",
        tags: ["example", "insight"],
        isPublic: true,
        official: false,
        version: 1,
        newTag: "",
        insightId: 0,
        userActivations: 0,
        createdDate: new Date(),
        updatedDate: new Date(),
        ownerUserId: 0,
        isDeleted: false,
        minw: 1,
        maxw: 4,
        minh: 1,
        maxh: 4,
        width: 1,
        height: 1,
        isDefaultInsight: false,
        defaultX: 0,
        defaultY: 0,
      });
    }
  }, [insightsData, setInsightData]);

  const formik = useFormik({
    initialValues: insightsData,
    onSubmit: async (
      values: InsightTemplate,
      { setFieldError }: FormikHelpers<InsightTemplate>
    ) => {
      setLoading(true);
      try {
        let result;
        if (mode === "create") {
          result = await createInsight(values);
        } else {
          result = await updateInsight(values);
        }

        if (result.success) {
          notifications.createNotification(
            `Insight ${mode === "create" ? "created" : "updated"}`,
            `Your insight has been ${
              mode === "create" ? "created" : "updated"
            } successfully`
          );
          setPage("insights");
        } else {
          notifications.createNotification(
            "Validation error",
            "There was an error with your input"
          );
          if (result.details) {
            result.details.forEach(
              (error: { path: string; message: string }) => {
                setFieldError(error.path, error.message);
              }
            );
          }
        }
      } catch (error) {
        notifications.createNotification(
          `Error ${mode === "create" ? "creating" : "updating"} insight`,
          `There was an error ${
            mode === "create" ? "creating" : "updating"
          } your insight`
        );
        console.error(
          `Error ${mode === "create" ? "creating" : "updating"} insight:`,
          error
        );
      } finally {
        setLoading(false);
      }
    },
  });

  const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue("newTag", e.target.value);
  };

  const addTag = () => {
    if (formik.values && formik.values.tags) {
      const newTag = (formik.values.newTag || "").trim();
      if (newTag && !formik.values.tags.includes(newTag)) {
        formik.setFieldValue("tags", [...formik.values.tags, newTag]);
        formik.setFieldValue("newTag", "");
      }
    }
  };

  const removeTag = (tag: string) => {
    if (
      tag &&
      formik.values &&
      formik.values.tags &&
      formik.values.tags.includes(tag)
    ) {
      formik.setFieldValue(
        "tags",
        formik.values.tags.filter((t: string) => t !== tag)
      );
    }
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box p={5}>
      <HStack
        spacing={4}
        mb={4}
        justifyContent={"flex-start"}
        alignItems={"flex-start"}
      >
        <Box flex={2}>
          <form onSubmit={formik.handleSubmit}>
            <VStack spacing={4} align="stretch">
              <FormControl
                isInvalid={formik.touched.title && !!formik.errors.title}
              >
                <FormLabel>Title</FormLabel>
                <Input
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.title && formik.errors.title ? (
                  <Text color="red.500">{formik.errors.title}</Text>
                ) : null}
              </FormControl>
              <FormControl
                isInvalid={formik.touched.type && !!formik.errors.type}
              >
                <FormLabel>Type</FormLabel>
                <Select
                  name="type"
                  value={formik.values.type}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  {Object.values(InsightType).map((type) => (
                    <option key={type} value={type}>
                      {type}
                    </option>
                  ))}
                </Select>
                {formik.touched.type && formik.errors.type ? (
                  <Text color="red.500">{formik.errors.type}</Text>
                ) : null}
              </FormControl>
              <FormControl
                isInvalid={formik.touched.query && !!formik.errors.query}
              >
                <FormLabel>Query </FormLabel>
                {formik.values.type === "Table" && (
                  <>
                    <Select
                      name="query"
                      value={formik.values.query}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value=""></option>

                      <option value="TABLE_OPEN_POSITIONS">
                        Open Positions
                      </option>
                    </Select>
                  </>
                )}

                {formik.values.type === "Number" && (
                  <>
                    <Select
                      name="query"
                      value={formik.values.query || ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value=""></option>
                      <option value="REALIZED_PNL">
                        Realized Profit & Loss
                      </option>
                      <option value="UNREALIZED_PNL">
                        Unrealized Profit & Loss
                      </option>
                      <option value="TOTAL_PNL">Total Profit & Loss</option>
                      <option value="TOTAL_GROSS_PROCEEDS">
                        Total Gross Proceeds
                      </option>
                      <option value="TOTAL_NET_PROCEEDS">
                        Total Net Proceeds
                      </option>
                      <option value="TOTAL_COSTS">Total Costs</option>
                      {/* <option value="TOTAL_PREMIUM_RECEIVED">
                        Total Premium Received
                      </option> */}
                      <option value="AVG_DAYS_IN_POSITION">
                        Average Days in Position
                      </option>
                      <option value="TOTAL_DEBITS">Total Debits</option>
                      <option value="TOTAL_CREDITS">Total Credits</option>
                    </Select>
                  </>
                )}

                {formik.values.type === "Comparison" && (
                  <>
                    <Select
                      name="query"
                      value={formik.values.query || "WIN_LOSS_PERCENTAGE"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value=""></option>
                      <option value="WIN_LOSS_PERCENTAGE">
                        Win Loss Ratio (%)
                      </option>
                      <option value="AVG_WIN_LOSS_CURRENCY">
                        Average Win Loss Ratio ($)
                      </option>
                    </Select>
                  </>
                )}
                {formik.values.type === "Barchart" && (
                  <>
                    <Select
                      name="query"
                      value={formik.values.query || "PNL_BY_DATE"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value=""></option>
                      <option value="PNL_BY_DATE">Profit & Loss By Date</option>
                      <option value="PNL_BY_UNDERLYING">
                        Profit & Loss By Underlying
                      </option>
                      <option value="CUMMULATIVE_PNL">
                        Cummulative Profit & Loss
                      </option>
                    </Select>
                  </>
                )}

                {formik.values.type === "Linechart" && (
                  <>
                    <Select
                      name="query"
                      value={formik.values.query || "CUMMULATIVE_PNL"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value=""></option>
                      <option value="CUMMULATIVE_PNL">
                        Cummulative Profit & Loss
                      </option>
                    </Select>
                  </>
                )}

                {formik.touched.query && formik.errors.query ? (
                  <Text color="red.500">{formik.errors.query}</Text>
                ) : null}
              </FormControl>
              <FormControl
                isInvalid={
                  formik.touched.description && !!formik.errors.description
                }
              >
                <FormLabel>Description</FormLabel>
                <Textarea
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  rows={2}
                />
                {formik.touched.description && formik.errors.description ? (
                  <Text color="red.500">{formik.errors.description}</Text>
                ) : null}
              </FormControl>
              <FormControl>
                <FormLabel>Tags</FormLabel>
                <HStack spacing={2} wrap="wrap">
                  {formik.values.tags?.map((tag: string) => (
                    <Tag
                      key={tag}
                      size="md"
                      borderRadius="full"
                      variant="solid"
                      colorScheme="teal"
                    >
                      <TagLabel>{tag}</TagLabel>
                      <TagCloseButton onClick={() => removeTag(tag)} />
                    </Tag>
                  )) ?? []}
                </HStack>
                <HStack spacing={2} mt={2}>
                  <Input
                    value={formik.values.newTag || ""}
                    onChange={handleTagChange}
                    placeholder="New tag"
                  />
                  <Button onClick={addTag} colorScheme="teal">
                    Add Tag
                  </Button>
                </HStack>
              </FormControl>
              <FormControl>
                <FormLabel>Public</FormLabel>
                <Checkbox
                  name="isPublic"
                  isChecked={formik.values.isPublic}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Official</FormLabel>
                <Checkbox
                  name="official"
                  isChecked={formik.values.official}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormControl>

              <FormControl>
                <FormLabel>
                  Is default insight? (Appears in default layout and displayed
                  as "Recommended")
                </FormLabel>
                <Checkbox
                  name="isDefaultInsight"
                  isChecked={formik.values.isDefaultInsight}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Default X position</FormLabel>
                <Input
                  type="number"
                  name="defaultX"
                  value={formik.values.defaultX || 0}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Default Y position</FormLabel>
                <Input
                  type="number"
                  name="defaultY"
                  value={formik.values.defaultY || 0}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormControl>

              <FormControl
                isInvalid={formik.touched.width && !!formik.errors.width}
              >
                <FormLabel>Width</FormLabel>
                <Input
                  name="width"
                  type="number"
                  value={formik.values.width || 1}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.width && formik.errors.width ? (
                  <Text color="red.500">{formik.errors.width}</Text>
                ) : null}
              </FormControl>
              <FormControl
                isInvalid={formik.touched.height && !!formik.errors.height}
              >
                <FormLabel>Height</FormLabel>
                <Input
                  name="height"
                  type="number"
                  value={formik.values.height || 2}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.height && formik.errors.height ? (
                  <Text color="red.500">{formik.errors.height}</Text>
                ) : null}
              </FormControl>
              <HStack spacing={4}>
                {mode === "create" ? (
                  <Button type="submit" colorScheme="teal">
                    Create Insight
                  </Button>
                ) : (
                  <Button type="submit" colorScheme="blue">
                    Update Insight
                  </Button>
                )}
              </HStack>
            </VStack>
          </form>
        </Box>
        <Box
          flex={2}
          pt="30px"
          maxH="400px"
          maxW="400px"
          minH={"200px"}
          minW={"200px"}
          h={formik.values?.height * 100 + "px" || "400px"}
          w={formik.values?.width * 100 + "px" || "400px"}
        >
          <InsightContainer insight={formik.values} demo={true} />
        </Box>
      </HStack>
    </Box>
  );
};

export default InsightsCreator;
