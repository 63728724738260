import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  Heading,
  HStack,
  Stack,
  TabPanel,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import * as React from "react";
import styles from "./FeatureContainer.module.css";
import "./style.css";
const FeatureContainer = ({
  title,
  description,
  component,
  direction,
  color,
  cPosition,
}) => {
  let blurContainer = "blurContainerLeft";

  let className = "rightSide";
  let className2 = "rightSideText";
  // if (direction === "left") {
  //   className = "leftSide";
  //   className2 = "leftSideText";

  //   blurContainer = "blurContainerRight";
  // }

  let blurClass = "";
  if (color === "blue") {
    blurClass = "blurBlue";
  } else if (color === "yellow") {
    blurClass = "blurYellow";
  } else {
    blurClass = "blurGreen";
  }

  return (
    <TabPanel w="6xl" maxW="90vw">
      <Box as="section" maxW={"6xl"} margin="auto" minW="100%" maxW="90vw">
        <HStack className={className}>
          <Box className={styles.componentBox} flex={1}>
            <div className={blurContainer}>
              <div className={blurClass + " " + cPosition}></div>
            </div>
            <div className="componentContainer">{component}</div>
          </Box>
          <Stack
            justifyContent="flex-end"
            flex={1}
            className={"stacKTexText" + " " + className2}
          >
            <Heading
              as="h3"
              fontSize="28px"
              pb="20px"
              color={"var(--white)"}
              fontWeight="700"
              textTransform={"uppercase"}
            >
              {title}
            </Heading>

            <VStack alignItems={"flex-start"}>
              {description.map((value, index) => {
                return (
                  <HStack>
                    <CheckIcon width="20px" stroke="var(--accent)" />
                    <Text
                      key={index}
                      color={"var(--white)"}
                      fontSize="14px"
                      opacity={0.8}
                      fontWeight={"400"}
                    >
                      {value}
                    </Text>
                  </HStack>
                );
              })}
            </VStack>
          </Stack>
        </HStack>
      </Box>
    </TabPanel>
  );
};

export default FeatureContainer;
