import { APIEndpoint } from ".";

export const getStripePortal = async () => {
  return fetch(APIEndpoint + "/subscriptions/portal", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      console.log("API Call, error getting stripe portal", e);
    });
};
