import { useCallback, useRef } from "react";
import {
  ColumnEverythingChangedEvent,
  ColumnResizedEvent,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { useDebounce } from "../../features/hooks";

interface UseGridStateProps {
  gridName: string;
}

interface UseGridStateResponse {
  onGridReady: (e: GridReadyEvent) => void;
  onFirstDataRendered: () => void;
  onColumnChanged: (e: ColumnEverythingChangedEvent) => void;
  onColumnResized: (e: ColumnResizedEvent) => void;
}

export const useGridState = (
  props: UseGridStateProps
): UseGridStateResponse => {
  const { gridName } = props;

  const gridRef = useRef<GridApi | null>(null);

  const onGridReady = useCallback((e: GridReadyEvent) => {
    gridRef.current = e.api;
  }, []);

  const onFirstDataRendered = useCallback(() => {
    const savedColumnState = localStorage.getItem(`${gridName}_ColumnState`);
    if (savedColumnState) {
      gridRef.current?.applyColumnState({
        state: JSON.parse(savedColumnState),
      });
    }
  }, []);

  const ignoreSources = ["api", "gridInitializing", "flex"];

  const performColumnChanged = useCallback(
    (e: ColumnEverythingChangedEvent | ColumnResizedEvent) => {
      if (e && !ignoreSources.includes(e.source)) {
        const columnState = gridRef.current?.getColumnState();
        if (columnState) {
          localStorage.setItem(
            `${gridName}_ColumnState`,
            JSON.stringify(columnState)
          );
        }
      }
    },
    []
  );

  const onColumnChanged = useDebounce(performColumnChanged, 200);
  const onColumnResized = useDebounce(performColumnChanged, 200);

  return {
    onGridReady,
    onFirstDataRendered,
    onColumnChanged,
    onColumnResized,
  };
};
