import moment from "moment";
import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum, createGroupColumn } from "../../../../components";

export const columnDefinitions: ColDef[] = [
  createGroupColumn("Price Date", "priceDate", ColumnTypeEnum.Date, {
    valueFormatter: (params) => {
      return params.value ? moment(params.value).format("DD-MMM-YYYY") : "";
    },
  }),
  createGroupColumn("Timestamp", "timestamp", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
    sort: "desc",
  }),
  createGroupColumn("Price Type", "priceType", ColumnTypeEnum.Text),
  createGroupColumn("Price", "price", ColumnTypeEnum.Numeric, {
    aggFunc: "distinct",
  }),
  createGroupColumn("Source", "source", ColumnTypeEnum.Text),
];
