import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum, createGroupColumn } from "../../components";
import convertSymbolToReadable from "../../components/grid/utils/convertSymbolToReadable";

export const columnDefinitions: ColDef[] = [
  createGroupColumn(
    "Underlying Symbol",
    "underlyingSymbol",
    ColumnTypeEnum.Logo,
    {
      suppressColumnsToolPanel: true,
      hide: true,
      rowDrag: true,
    }
  ),

  createGroupColumn("Instrument", "instrument.symbol", ColumnTypeEnum.Text, {
    valueGetter: (params) => {
      return convertSymbolToReadable(params.data.instrument.symbol);
    },
    aggFunc: "distinct",
    minWidth: 200,
    hide: true,
  }),

  createGroupColumn("Book", "book", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    aggFunc: "distinct",
    rowDrag: true,
    sort: "asc",
    suppressColumnsToolPanel: true,
  }),

  createGroupColumn("Underlying", "underlyingName", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    rowDrag: true,
    suppressColumnsToolPanel: true,
  }),
  createGroupColumn("Position Name", "positionName", ColumnTypeEnum.Text, {
    rowGroup: true,
    hide: true,
    sort: "asc",
    rowDrag: true,
    suppressColumnsToolPanel: true,
  }),

  createGroupColumn("Strategy", "strategy", ColumnTypeEnum.Text, {
    aggFunc: "distinct",
  }),

  createGroupColumn("Date", "positionDate", ColumnTypeEnum.Date, {
    sort: "asc",
    aggFunc: "distinct",
    minWidth: 140,
    hide: true,
  }),
  createGroupColumn("Type", "instrument.instrumentType", ColumnTypeEnum.Text, {
    aggFunc: "none",
    hide: true,
  }),
  // createGroupColumn("Buy/Sell", "buySell", ColumnTypeEnum.Text),
  createGroupColumn("Put/Call", "instrument.putCall", ColumnTypeEnum.Pill, {
    hide: true,
    cellRendererParams: {
      exactMatch: true,

      colourMap: {
        Put: "var(--trade-call)",
        Call: "var(--trade-put)",
      },
      defaultColour: "var(--accent)",
    },
    aggFunc: "distinct",
  }),
  createGroupColumn("Strike", "instrument.strike", ColumnTypeEnum.Numeric, {
    aggFunc: "none",
    hide: true,
  }),
  createGroupColumn("Expiry Date", "instrument.expiry", ColumnTypeEnum.Date, {
    aggFunc: "distinct",
    hide: true,
  }),
  createGroupColumn("Quantity", "quantity", ColumnTypeEnum.Numeric, {
    aggFunc: "sum",
    hide: true,
  }),
  createGroupColumn(
    "Multiplier",
    "instrument.multiplier",
    ColumnTypeEnum.Numeric,
    {
      aggFunc: "distinct",
      hide: true,
    }
  ),
  createGroupColumn("Price", "price", ColumnTypeEnum.Currency, {
    cellRendererParams: { noFormatting: true },
    hide: true,
  }),
  createGroupColumn("Realized P&L", "realisedPnl", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn(
    "Gross proceeds",
    "grossProceeds",
    ColumnTypeEnum.Currency,
    { cellRendererParams: { showDirection: true }, aggFunc: "sum" }
  ),
  createGroupColumn("Net proceeds", "netProceeds", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn("Costs", "costs", ColumnTypeEnum.Currency, {
    hide: true,
    aggFunc: "sum",
    valueGetter: (params) => {
      return params.data.costs * -1;
    },
  }),

  createGroupColumn(
    "Unrealized P&L$",
    "unrealisedPnl",
    ColumnTypeEnum.CurrencyWithPercentage,
    {
      hide: false,
      cellRendererParams: {
        showDirection: true,
        percentageField: "unRealisedPnLPercentage",
      },
      aggFunc: "sum",
      valueGetter: (params) => {
        if (params.data.priceDate === null) {
          return null;
        } else {
          return params.data.unrealisedPnl;
        }
      },
    }
  ),

  createGroupColumn("Underlying Price", "underlyingPrice"),

  createGroupColumn("Price", "price", ColumnTypeEnum.PriceDetail),

  createGroupColumn(
    "Premiums recieved",
    "premiumReceived",
    ColumnTypeEnum.Currency,
    {
      cellRendererParams: { showDirection: true },
      aggFunc: "sum",
      hide: true,
    }
  ),

  createGroupColumn("Total Credits", "totalCredits", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  createGroupColumn("Total Debits", "totalDebits", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
    aggFunc: "sum",
  }),

  ///Custom Field, not on api
  createGroupColumn("Win Rate", "winLoss", ColumnTypeEnum.Numeric, {
    cellRendererParams: { showDirection: true },
    aggFunc: "avg",
    hide: true,
  }),

  // tags
  createGroupColumn("Tags", "tags", ColumnTypeEnum.Tags, {
    hide: true,
    aggFunc: "none",
  }),

  // daysInPosition
  createGroupColumn(
    "Days in Position",
    "daysInPosition",
    ColumnTypeEnum.Numeric,
    {
      aggFunc: "avg",
      hide: true,
    }
  ),

  // annualizedReturnPercentage
  // createGroupColumn(
  //   "Annualized Return",
  //   "annualizedReturnPercentage",
  //   ColumnTypeEnum.Percentage,
  //   {
  //     aggFunc: "none",
  //     hide: true,
  //   }
  // ),

  // // breakevenPoint
  // createGroupColumn(
  //   "Breakeven Point",
  //   "breakevenPoint",
  //   ColumnTypeEnum.Currency,
  //   {
  //     aggFunc: "none",
  //     hide: true,
  //   }
  // ),

  // createGroupColumn("Risk", "risk", ColumnTypeEnum.Currency, {
  //   aggFunc: "sum",
  //   hide: true,
  // }),
];
