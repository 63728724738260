import React, { useMemo } from "react";
import {
  Text,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useInstrumentIssuesController } from "./useInstrumentIssuesController";
import { GridContainer, columnTypes } from "../../../../components";
import { AgGridReact } from "ag-grid-react";
import { pricesColumnDefinitions } from "./pricesColumnDefinitions";
import { errorsColumnDefinitions } from "./errorsColumnDefinitions";

export const InstrumentIssues = () => {
  const controller = useInstrumentIssuesController();

  return (
    <Flex h="100%" w="100%" direction="column" px={2} py={1}>
      <Text mb="4px">Issues</Text>

      <Tabs h="full">
        <TabList>
          <Tab>Prices ({controller.missingPrices?.length})</Tab>
          <Tab>EOD Prices ({controller.missingEODPrices?.length})</Tab>
          <Tab>Errors ({controller.pricingErrors?.length})</Tab>
        </TabList>

        <TabPanels h="calc(100vh - 270px)" w="100%" overflowY="auto">
          <TabPanel h="full" w="full">
            <GridContainer>
              <AgGridReact
                rowData={controller.missingPrices} // Row Data for Rows
                columnDefs={pricesColumnDefinitions} // Column Defs for Columns
                columnTypes={columnTypes} // Optional - custom column types
                suppressMultiSort={false}
                suppressAggFilteredOnly={false}
                rowSelection="single"
                rowHeight={31}
                suppressAggFuncInHeader={true}
                getRowId={(params) => {
                  return params.data?.id;
                }}
                onGridReady={(params) => {
                  params.api.autoSizeAllColumns();
                }}
                suppressChangeDetection={false}
                onFirstDataRendered={(params) => {
                  params.api.expandAll();
                  params.api.sizeColumnsToFit();
                }}
              />
            </GridContainer>
          </TabPanel>
          <TabPanel h="full" w="full">
            <GridContainer>
              <AgGridReact
                rowData={controller.missingEODPrices} // Row Data for Rows
                columnDefs={pricesColumnDefinitions} // Column Defs for Columns
                columnTypes={columnTypes} // Optional - custom column types
                suppressMultiSort={false}
                suppressAggFilteredOnly={false}
                rowSelection="single"
                rowHeight={31}
                suppressAggFuncInHeader={true}
                onGridReady={(params) => {
                  params.api.autoSizeAllColumns();
                }}
                suppressChangeDetection={false}
                onFirstDataRendered={(params) => {
                  params.api.expandAll();
                  params.api.sizeColumnsToFit();
                }}
              />
            </GridContainer>
          </TabPanel>
          <TabPanel h="full" w="full">
            <GridContainer>
              <AgGridReact
                rowData={controller.pricingErrors} // Row Data for Rows
                columnDefs={errorsColumnDefinitions} // Column Defs for Columns
                columnTypes={columnTypes} // Optional - custom column types
                suppressMultiSort={false}
                suppressAggFilteredOnly={false}
                rowSelection="single"
                rowHeight={31}
                suppressAggFuncInHeader={true}
                onGridReady={(params) => {
                  params.api.autoSizeAllColumns();
                }}
                suppressChangeDetection={false}
                onFirstDataRendered={(params) => {
                  params.api.expandAll();
                  params.api.sizeColumnsToFit();
                }}
              />
            </GridContainer>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};
