export interface BlogArticle {
  articleId?: number;
  title: string;
  content: string;
  dateCreated?: Date;
  dateUpdated?: Date;
  slug?: string;
  category?: string;
  status: BlogArticleStatus;
  image?: string;
  excerpt?: string;
}

export enum BlogArticleStatus {
  Draft = "Draft",
  Published = "Published",
}
