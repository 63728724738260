import moment from "moment";
import { Instrument } from "../../../types";

export const calculateDaysUntilExpiry = (instrument: Instrument) => {
  let daysUntilExpiry = moment
    .utc(instrument?.expiry)
    .add(1, "days")
    .diff(moment(), "days", true);
  return daysUntilExpiry;
};
