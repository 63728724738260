import { atom } from "jotai";
import { Position } from "../types/Position";

export interface PositionState {
  positions: Position[];
  openPositions: number[]; // Position IDs
  closedPositions: number[]; // Position IDs
}

const buildInitialState = (): PositionState => {
  const state: any = {
    positions: [],
    openPositions: [],
    closedPositions: [],
  };

  return state;
};

export const PositionStateAtom = atom<PositionState>(buildInitialState());
