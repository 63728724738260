import { APIEndpoint } from ".";

export const ChangePassword = async (password: string) => {
  return fetch(APIEndpoint + "/users/password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      password: password,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      console.log("API Call, error changing password", e);
    });
};
