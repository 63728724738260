import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  Title,
  TriangleDown,
  TriangleUp,
} from "../../../../../components/design_library";
import styles from "../../../../../components/design_library/index.module.css";
import CountUp from "react-countup";

export const RandomValuesDemo = ({
  defaultNumber,
  defaultText,
  arrayOfTextValues,
  prefix,
  suffix,
}: {
  defaultNumber: number;
  defaultText: string;
  arrayOfTextValues: string[];
  prefix?: string;
  suffix?: string;
}) => {
  const [number, setNumber] = useState(defaultNumber);
  const [text, setTextValue] = useState(defaultText);

  useEffect(() => {
    setInterval(() => {
      setNumber(
        number +
          Math.floor(
            Math.random() * (number * 1.5 - number * 0.5 + 1) + number * 0
          )
      );
    }, 4000);

    setInterval(() => {
      setTextValue(
        arrayOfTextValues[Math.floor(Math.random() * arrayOfTextValues.length)]
      );
    }, 4000);
  }, []);

  return (
    <Box
      paddingLeft="20px"
      paddingRight={"20px"}
      display="flex"
      justifyContent={"center"}
      flexDir="column"
      alignItems={"center"}
      textAlign={"center"}
    >
      <Title>{text} </Title>
      {number > 60 ? (
        <HStack>
          <Icon className={styles.positiveIcon} as={TriangleUp}></Icon>
          <Text className={styles.statChangePositive} fontSize="20px">
            <CountUp
              end={number}
              decimals={2}
              decimal="."
              prefix={prefix}
              suffix={suffix}
            />
          </Text>
        </HStack>
      ) : (
        <HStack>
          <Icon className={styles.negativeIcon} as={TriangleDown}></Icon>
          <Text className={styles.statChangeNegative} fontSize="20px">
            <CountUp
              end={number}
              decimals={2}
              decimal="."
              prefix={prefix}
              suffix={suffix}
            />
          </Text>
        </HStack>
      )}
    </Box>
  );
};
