import {
  HStack,
  Image,
  Stack,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import FeatureContainer from "./component/FeatureContainer";
import { RandomValuesDemo } from "./component/demos/RandomValues";
import { FloatingIcons } from "./component/demos/FloatingIcons";
import UploadingExplosion from "./component/demos/UploadingExplosion";
import FakeCalendarDate from "./component/demos/FakeCalendarDate";

const CustomTab = (props: any) => {
  return (
    <Tab
      _selected={{
        color: "var(--white)",
        background: "var(--accent)",
        borderRadius: "12px",
      }}
      _hover={{
        background: "var(--accent)",
        borderRadius: "12px",
      }}
      borderRadius="12px"
      color="var(--white)"
      background="var(--dark-gray)"
      px="20px"
      py="14px"
      mx="10px"
      w="full"
    >
      {props.children}
    </Tab>
  );
};

export default function FeatureList() {
  return (
    <VStack>
      <Stack>
        <Tabs isLazy variant="custom" borderRadius={"12px"} p="6px">
          <TabList
            justifyContent={"space-between"}
            background={"var(--dark-gray)"}
            borderRadius={"12px"}
          >
            <CustomTab>Profit & Loss</CustomTab>
            <CustomTab>Key Metrics</CustomTab>
            <CustomTab>Trade Grouping</CustomTab>
            <CustomTab>Trade Import</CustomTab>
            <CustomTab>Trading Journal</CustomTab>
          </TabList>
          <TabIndicator />
          <TabPanels>
            <FeatureContainer
              component={
                <Image
                  src="https://cdn.wealthbee.io/main/app/homepage/PNL.png"
                  alt="feature1"
                  borderRadius={"8px"}
                  border="solid 1px var(--accent)"
                />
              }
              color="yellow"
              direction="left"
              title={`Profit & Loss Tracking`}
              description={[
                `With our real-time P&L tracking, you can see how your trades are performing at any time`,
                `Track prices for stocks/equities, equity options, futures and futures options`,
                `When prices are not available, we have our proprietary pricing model to estimate prices`,
                `Quickly see how all your accounts are performing in one place`,
              ]}
            />

            <FeatureContainer
              component={
                <Frame>
                  <RandomValuesDemo
                    defaultNumber={56}
                    defaultText={"Breakeven"}
                    arrayOfTextValues={["Breakeven"]}
                    prefix={"$"}
                    suffix={""}
                  />
                </Frame>
              }
              color="yellow"
              direction="right"
              title={"Track Key Metrics"}
              description={[
                `Track key metrics like break-even, max profit, max loss, and more`,
                `All calculations come with an audit log so you can see how we arrived at the numbers`,
                `Metrics are calculated in real-time and are always up-to-date`,
              ]}
            />

            <FeatureContainer
              component={
                <Frame>
                  <FloatingIcons
                    textList={[
                      { value: "Stangle", color: "var(--accent)", offset: 5 },
                      {
                        value: "Wheeling",
                        color: "var(--chart-blue)",
                        offset: 10,
                      },
                      {
                        value: "Iron Condor",
                        color: "var(--green)",
                        offset: 15,
                      },
                      {
                        value: "Covered Call",
                        color: "var(--red)",
                        offset: 8,
                      },
                      {
                        value: "Straddle",
                        color: "var(--chart-orange)",
                        offset: 15,
                      },
                      {
                        value: "Strangle",
                        color: "var(--chart-dark-blue)",
                        offset: 10,
                      },
                      {
                        value: "Butterfly",
                        color: "var(--chart-baby-blue)",
                        offset: 5,
                      },
                      {
                        value: "Custom tag...",
                        color: "var(--gray)",
                        offset: 5,
                      },
                    ]}
                  />
                </Frame>
              }
              cPosition="blurBottomRight"
              color="yellow"
              direction="left"
              title={`Intelligent trade grouping`}
              description={[
                `Group trades however you like, by portfolio, strategy, or any other way and track metrics for each group`,
                `Quickly see how each group is performing and compare groups against each other`,
                `View metrics at any level of granularity, from the entire group to individual trades`,
                `Group trades at Book level, position group level, or individual trade level`,
              ]}
            />

            <FeatureContainer
              component={
                <Frame>
                  <UploadingExplosion />
                </Frame>
              }
              color="yellow"
              direction="right"
              title={"Automatically import trades"}
              description={[
                `Automatically import trades from your broker (Schwab, Fidelity, more coming soon)`,
                `CSV import supported for other brokers such as TastyWorks, E*Trade, Fidelity, Schwab.`,
                `No more manual entry of trades, all trades are imported as soon as your broker messages us`,
                `All trades are imported with the correct prices and fees directly from your borker`,
              ]}
            />

            <FeatureContainer
              component={
                <Frame>
                  <FakeCalendarDate />
                </Frame>
              }
              cPosition="blurTopRight"
              color="blue"
              direction="left"
              title={"Journal your trades"}
              description={[
                `Journal your trades and add notes to each trade`,
                `Add tags to each trade to categorize them however you like`,
                `Quickly search your trades by tags, notes, or any other criteria`,
              ]}
            />
          </TabPanels>
        </Tabs>
      </Stack>
    </VStack>
  );
}

const Frame = (props: any) => {
  return (
    <HStack
      width={"450px"}
      maxH="90%"
      maxW="90%"
      borderRadius={"12px"}
      paddingLeft="20px"
      paddingRight={"20px"}
      justifyContent={"center"}
    >
      {props.children}
    </HStack>
  );
};
