import React from "react";
import {
  Box,
  BoxProps,
  Center,
  HStack,
  HTMLChakraProps,
  Heading,
  Text,
} from "@chakra-ui/react";
import { BiRightArrowAlt } from "react-icons/bi";
import { PrimaryButton } from "../../../components/design_library";

export const CTA = () => {
  return (
    <Box
      maxW="6xl"
      w="100%"
      mb="200px"
      display={"flex"}
      justifyContent={"center"}
      flexDir={"column"}
      m="auto"
      textAlign={"center"}
      pb="160px"
      pt="120px"
    >
      <Heading>Sign up today</Heading>
      <Text m="auto" mb="20px" mt="16px" opacity="0.8" maxW="400px">
        Access to all of the features. No contracts required. Lifetime access is
        available for a one time purchase. Trial can be cancelled with
        one-click.
      </Text>

      <HStack justify={"center"} alignItems="center">
        <PrimaryButton
          id="cta_1"
          as="a"
          href="../app/register"
          onClick={() => {
            localStorage.setItem("ftk", "true");
          }}
          color={"var(--white)"}
          border="none !important"
          bg="var(--accent-dark) !important"
          mt="20px"
          fontSize="18px !important"
          minW="300px"
          h="60px"
        >
          Start 30 day free trial now
          <Box pl="6px">
            <BiRightArrowAlt />
          </Box>
        </PrimaryButton>
      </HStack>
    </Box>
  );
};
