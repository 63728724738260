import { useAtom, useAtomValue } from "jotai";
import { AccountAtom, TransactionsAtom } from "../../../store";
import { GetContextMenuItemsParams, MenuItemDef } from "ag-grid-community";
import { useTransactions } from "../../../hooks/useTransactions";
import { getAllChildrenOfGroup } from "../..";
import { useNotifications } from "../../../hooks/useNotifications";
import { usePositions } from "../../../hooks";

interface UseGridContextMenuController {
  getContextMenuItems: (
    params: GetContextMenuItemsParams
  ) => (string | MenuItemDef)[];
}

export const useGridContextMenuController =
  (): UseGridContextMenuController => {
    const useTransactionsHook = useTransactions();
    const accountState = useAtomValue(AccountAtom);
    const transactions = useAtomValue(TransactionsAtom);
    const usePositionsHook = usePositions();
    const notifications = useNotifications();

    const getContextMenuItems = (
      params: GetContextMenuItemsParams
    ): (string | MenuItemDef)[] => {
      var result: (string | MenuItemDef)[] = [
        {
          name: "Books",
          disabled: true,
        },
        "separator",
        {
          name: "Allocate to new book",
          action: () => {
            let prompt = window.prompt("Enter new book name:");

            if (prompt) {
              const children = getAllChildrenOfGroup(params, transactions);

              notifications.createTradeChangeNotification(
                `Trades allocated to new book: ${prompt}`
              );

              useTransactionsHook.updateBook(
                children,
                prompt,
                accountState?.selectedAccount?.id
              );
            }
          },
        },
        {
          name: "Allocate to existing book",
          disabled: transactions.books.length === 0 ? true : false,
          subMenu: transactions.books.map((book) => {
            const children = params.api.getSelectedRows();

            return {
              name: book,
              action: () => {
                notifications.createTradeChangeNotification(
                  `Trades allocated to existing book: ${book}`
                );

                const children = getAllChildrenOfGroup(params, transactions);
                useTransactionsHook.updateBook(
                  children,
                  book,
                  accountState?.selectedAccount?.id
                );
              },
            };
          }),
        },
        {
          name: "Clear book",
          disabled: transactions.books.length === 0 ? true : false,
          action: () => {
            const children = getAllChildrenOfGroup(params, transactions);

            notifications.createTradeChangeNotification(
              `Book cleared from trades`
            );
            useTransactionsHook.updateBook(
              children,
              "",
              accountState?.selectedAccount?.id
            );
          },
        },
        "separator",
        {
          name: "Positions",
          disabled: true,
        },
        "separator",
        {
          name: "Allocate to new position group",
          action: () => {
            let prompt = window.prompt("Enter new position group name:");
            notifications.createTradeChangeNotification(
              `Trades allocated to group: ${prompt}`
            );
            if (prompt) {
              const children = getAllChildrenOfGroup(params, transactions);

              useTransactionsHook.updateGroupNames(
                children,
                prompt,
                accountState?.selectedAccount?.id
              );
            }
          },
        },
        {
          name: "Allocate to existing position group",
          disabled: transactions.groupNames.length === 0 ? true : false,
          subMenu: transactions.groupNames.map((groupName) => {
            return {
              name: groupName,
              action: () => {
                notifications.createTradeChangeNotification(
                  `Trades allocated to group: ${groupName}`
                );
                const children = getAllChildrenOfGroup(params, transactions);

                useTransactionsHook.updateGroupNames(
                  children,
                  groupName,
                  accountState?.selectedAccount?.id
                );
              },
            };
          }),
        },
        {
          name: "Clear position group name",
          disabled: transactions.groupNames.length === 0 ? true : false,

          action: () => {
            const children = getAllChildrenOfGroup(params, transactions);

            notifications.createTradeChangeNotification(
              `Group name cleared from trades`
            );

            useTransactionsHook.updateGroupNames(
              children,
              "",
              accountState?.selectedAccount?.id
            );
          },
        },
        "separator",
        {
          name: "Advanced",

          subMenu: [
            // {
            //   name: "Close position",
            //   disabled: !params?.node?.data,
            //   action: () => {
            //     notifications.createTradeChangeNotification(`Trade closed`);
            //     useTransactionsHook.createClosingTrade(
            //       params.node.data,
            //       getAllChildrenOfGroup(params, transactions)[0],
            //       accountState?.selectedAccount?.id
            //     );
            //   },
            // },
            // {
            //   name: "Use this to close positions upon import",
            //   disabled: true,
            // },
            // "separator",

            {
              name: "Hide trades",
              action: () => {
                if (
                  window.confirm(
                    "This marks trades as hidden, they won't appear on the table or in any calculations and they won't be imported again. Are you sure you want to hide these trades?"
                  ) === true
                ) {
                  const selectedTrades = getAllChildrenOfGroup(
                    params,
                    transactions
                  );
                  notifications.createTradeChangeNotification(`Trades hidden`);
                  useTransactionsHook.hideTrades(
                    selectedTrades,
                    accountState?.selectedAccount?.id
                  );
                }
              },
            },
            {
              name: "Use this to hide trades from your view, they won't appear on the table or in any calculations and they won't be reimported.",
              disabled: true,
            },
            "separator",
            {
              name: "Delete trade(s)",
              action: async () => {
                if (
                  window.confirm(
                    "This will delete the trades from the database. Are you sure you want to delete this trade?"
                  ) === true
                ) {
                  const selectedTrades = getAllChildrenOfGroup(
                    params,
                    transactions
                  );

                  const tradeIds = selectedTrades.map((trade) => trade.id);

                  useTransactionsHook.deleteManyTrades(
                    tradeIds,
                    accountState?.selectedAccount?.id
                  );

                  // delay calling this until previous call is doen FIX this later
                  setTimeout(async () => {
                    usePositionsHook.fetchPositions(
                      accountState?.selectedAccount?.id
                    );
                    useTransactionsHook.getTradesAndCashflows(
                      accountState?.selectedAccount?.id
                    );
                  }, 400);
                }
              },
            },
          ],
        },
      ];

      return result;
    };

    return {
      getContextMenuItems,
    };
  };
