import React from "react";
import { Link, Text } from "@chakra-ui/react";

export interface ComplexColumnValue {
  value: any;
  formatting: {
    fontColour?: string;
    underlined?: boolean;
    fontWeight?: string;
  };
  link?: string;
}
export const ComplexColumn = (props: any) => {
  const value: ComplexColumnValue = props.value as ComplexColumnValue;

  if (!value) return null;

  if (value.link) {
    return (
      <Link target="_blank" color="var(--accent)" href={value.link}>
        {value.value}
      </Link>
    );
  }

  return (
    <Text fontWeight={`${value.formatting?.fontWeight ?? "400"}`} color={`${value.formatting?.fontColour ?? "white"}`}>
      {value.value}
    </Text>
  );
};
