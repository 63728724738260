import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import styles from "./index.module.css";
import "./style.css";

export const TextInput = (props: any) => {
  let overrideStyleString = `${styles.textInput}`;

  if (props.dark === true) {
    overrideStyleString = overrideStyleString + ` ${styles.textInputDark}`;
  }

  return (
    <InputGroup className={styles.textInputFrame}>
      <Input className={overrideStyleString} {...props} />

      {props.leftComponent}

      {props.toggleShow === true && (
        <InputRightElement
          className={styles.showInputElement}
          // minW="110px"
        >
          <Button
            className={styles.showButton}
            h="1.75rem"
            onClick={props.toggleShowFunc}
          >
            {props.toggleShowContents}
          </Button>
        </InputRightElement>
      )}
      {props.children}

      {props.rightComponent}
    </InputGroup>
  );
};
