import { BlogArticle } from "../types/BlogArticle";
import APIEndpoint from "./APIEndpoint";

export const getBlogsArticles = async (
  publishedOnly: Boolean,
  category?: string,
  page: number = 1, // Added page parameter with default value
  pageSize: number = 10 // Added pageSize parameter with default value
) => {
  return fetch(
    `${APIEndpoint}/blogs?publishedOnly=${publishedOnly}&category=${category}&page=${page}&pageSize=${pageSize}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => console.error("Error retrieving Blogs articles:", error));
};
export const getSpecificBlogsArticle = async (id: number) => {
  return fetch(`${APIEndpoint}/blogs/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error retrieving specific Blogs article:", error)
    );
};

export const createBlogsArticle = async (article: BlogArticle) => {
  return fetch(`${APIEndpoint}/blogs`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(article),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating Blogs article:", error));
};

export const editBlogsArticle = async (id: number, article: BlogArticle) => {
  return fetch(`${APIEndpoint}/blogs/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(article),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error editing Blogs article:", error));
};

export const deleteBlogsArticle = async (id: number) => {
  return fetch(`${APIEndpoint}/blogs/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error deleting Blogs article:", error));
};
