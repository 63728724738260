import { useState } from "react";
import { useAtom } from "jotai";
import { AppStateAtom } from "../../../store";
import { register } from "../../../api";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../hooks/useUser";
import { useToast } from "@chakra-ui/react";
import mixpanel from "mixpanel-browser";

interface RegisterController {
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
  register: () => void;
  loading: boolean;
  formEmailError: string;
  formPasswordError: string;
  errorMessage: string;
}

export const useRegisterController = () => {
  const [appState, setAppState] = useAtom(AppStateAtom);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [formEmailError, setFormEmailError] = useState<string>("");
  const [formPasswordError, setFormPasswordError] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const user = useUser();
  const toast = useToast();

  const navigate = useNavigate();

  const performRegister = async () => {
    mixpanel.track("Register Attempt", {
      email: email,
    });
    mixpanel.alias(email);
    setLoading(true);
    setFormEmailError("");
    setFormPasswordError("");

    user.setLoading(true);
    setTimeout(() => {
      register(email, password)
        .then((res) => {
          if (res.success === true) {
            mixpanel.track("Register Success", {
              email: email,
              success: res.success,
            });
            user.setLoginSettings(email, "default", res.token);
            mixpanel.identify(res.user.email);
            localStorage.setItem("token", res.token);
            setTimeout(() => {
              navigate("/app/positions?tutorial=true");
              setLoading(false);
            }, 200);
          } else {
            mixpanel.track("Register Failure", {
              email: email,
              success: res.success,
            });
            if (res.success === false) {
              if (res.errors) {
                console.log(res.errors);
                res.errors.filter((e: any) => {
                  if (e.path === "email") {
                    setFormEmailError(e.msg);
                    toast({
                      title: "Error",
                      description: e.msg,
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                  }
                  if (e.path === "password") {
                    setFormPasswordError(e.msg);
                    toast({
                      title: "Error",
                      description: e.msg,
                      status: "error",
                      duration: 5000,
                      isClosable: true,
                    });
                  }
                });
              } else {
                setErrorMessage(res.message);
                toast({
                  title: "Error",
                  description: res.message,
                  status: "error",
                  duration: 5000,
                  isClosable: true,
                });
              }
              setLoading(false);
            }

            user.logUserOut();
            setLoading(false);
          }
        })
        .catch((e: any) => {
          mixpanel.track("Register Failure", {
            email: email,
            error: e.message,
          });
          console.log("API Call, error call login", e);
          user.logUserOut();
          setLoading(false);
        });
    }, 200);
  };

  return {
    email,
    setEmail,
    password,
    formEmailError,

    formPasswordError,
    errorMessage,
    setPassword,
    loading,
    register: performRegister,
  } as RegisterController;
};
