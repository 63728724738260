import type { Post } from "./data";
import { BlogHeader } from "./BlogHeader";
import React from "react";

interface BlogPostProps {
  post: Post;
}

export const BlogPost = (props: BlogPostProps) => {
  const { post } = props;
  return <BlogHeader post={post} />;
};
