import { Component } from "react";
import { Panel } from "../../../../../components/design_library";
import {
  Heading,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Text,
} from "@chakra-ui/react";

export default class ETradeInstructions extends Component {
  render() {
    return (
      <Panel w="100%">
        <Stack spacing="5">
          <Heading as="h3" size="lg">
            Instructions
          </Heading>

          <OrderedList pl="5">
            <ListItem>
              Log into your <a href="https://us.etrade.com/">E*Trade account</a>
            </ListItem>
            <ListItem mt="2">Select accounts</ListItem>
            <ListItem mt="2">Select transactions</ListItem>
            <ListItem mt="2">Select data</ListItem>
            <ListItem mt="2">Select all transactions</ListItem>
            <ListItem mt="2">Select date range</ListItem>
            <Image
              mt="2"
              src="https://cdn.wealthbee.io/main/brokers/instructions/etrade/etrade-modern.png"
              alt="E*Trade CSV"
              style={{ maxWidth: "600px" }}
            />
            <ListItem mt="2">
              The top of your exported file will look like this
            </ListItem>
            <Image
              mt="2"
              src="https://cdn.wealthbee.io/main/brokers/instructions/etrade/e-trade-table-header.png"
              alt="E*Trade CSV"
              style={{ maxWidth: "600px" }}
            />
          </OrderedList>
        </Stack>
      </Panel>
    );
  }
}
