import { KnowledgeBaseArticle } from "../types/KnowledgeBaseArticle";
import APIEndpoint from "./APIEndpoint";

export const getKnowledgeBaseArticles = async (
  publishedOnly: Boolean,
  category?: string
) => {
  return fetch(
    `${APIEndpoint}/knowledge-base?publishedOnly=${publishedOnly}&category=${category}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `${localStorage.getItem("token")}`,
      },
    }
  )
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error retrieving knowledgebase artciles:", error)
    );
};

export const getSpecificKnowledgeBaseArticle = async (id: number) => {
  return fetch(`${APIEndpoint}/knowledge-base/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error retrieving specific knowledgebase article:", error)
    );
};

export const createKnowledgeBaseArticle = async (
  article: KnowledgeBaseArticle
) => {
  return fetch(`${APIEndpoint}/knowledge-base`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(article),
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error creating knowledgebase article:", error)
    );
};

export const editKnowledgeBaseArticle = async (id: number, article: any) => {
  return fetch(`${APIEndpoint}/knowledge-base/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify(article),
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error editing knowledgebase article:", error)
    );
};

export const deleteKnowledgeBaseArticle = async (id: number) => {
  return fetch(`${APIEndpoint}/knowledge-base/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error deleting knowledgebase article:", error)
    );
};
