import * as React from "react";

export interface Link {
  label: string;
  href?: string;
  children?: Array<{
    label: string;
    description?: string;
    href: string;
    icon?: React.ReactElement;
  }>;
}

export const links: Link[] = [
  // { label: "Features", href: "../" },
  { label: "Blog", href: `${window.location.origin}/blog` },
  { label: "Roadmap", href: `${window.location.origin}/roadmap` },
  { label: "FAQ", href: `${window.location.origin}/knowledgebase` },
  { label: "About Us", href: `${window.location.origin}/about` },
  // { label: "Pricing", href: "../#pricing" },
];
