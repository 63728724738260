import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { ToggleButton } from "./ToggleButton";
import { useNavigate } from "react-router";
import AccountManager from "../../components/account/Account";

export const MobileDrawer = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const navigate = useNavigate();
  return (
    <>
      <ToggleButton
        isOpen={isOpen}
        onClick={onToggle}
        aria-label="Open menu"
        display={{ base: "inline-flex", lg: "none" }}
      />
      <Drawer placement="top" isOpen={isOpen} onClose={onClose}>
        <DrawerContent>
          <DrawerBody p="4" backgroundColor={"var(--bg)"}>
            <Stack spacing="1">
              <Button
                size="lg"
                variant="tertiary"
                justifyContent="start"
                onClick={() => {
                  navigate("/app/dashboard");
                  onToggle();
                }}
                color={
                  window.location.pathname === "/app/dashboard"
                    ? "var(--accent)"
                    : ""
                }
              >
                Dashboard
              </Button>
              <Button
                size="lg"
                variant="tertiary"
                justifyContent="start"
                onClick={() => {
                  navigate("/app/positions");
                  onToggle();
                }}
                color={
                  window.location.pathname === "/app/positions"
                    ? "var(--accent)"
                    : ""
                }
              >
                Positions
              </Button>
              <Button
                size="lg"
                variant="tertiary"
                justifyContent="start"
                onClick={() => {
                  navigate("/app/transactions");
                  onToggle();
                }}
                color={
                  window.location.pathname === "/app/transactions"
                    ? "var(--accent)"
                    : ""
                }
              >
                Transactions
              </Button>
              <Button
                size="lg"
                variant="tertiary"
                justifyContent="start"
                onClick={() => {
                  navigate("/app/settings");
                  onToggle();
                }}
                color={
                  window.location.pathname === "/app/settings"
                    ? "var(--accent)"
                    : ""
                }
              >
                Settings
              </Button>
              <AccountManager />
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};
