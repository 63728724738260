import {
  Box,
  Center,
  Divider,
  HStack,
  Heading,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import NavBar from "../homepage/menu/App";
import Footer from "../homepage/footer/App";
import { Helmet } from "react-helmet";
import { useKnowledgeBaseController } from "./useKnowledgebaseController";

export const KnowledgeBase = () => {
  const controller = useKnowledgeBaseController();

  return (
    <Box background={"var(--bg)"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WealthBee Knowledge Base</title>
        <link rel="canonical" href="https://wealthbee.io/knowledgebase" />
      </Helmet>
      <NavBar />

      <Center w="100vw">
        <Box w="8xl">
          <HStack
            w="full"
            h="200px"
            justifyContent={"flex-start"}
            textAlign={"left"}
          >
            <VStack alignItems={"flex-start"} px="10">
              <Heading as="h1">WealthBee Knowledge Base</Heading>
              <Text as="h2" fontSize="26px" opacity="0.6">
                Help for wherever you are on your investment journey
              </Text>
            </VStack>
          </HStack>

          <Box
            h={{
              base: "60px",
              md: "0px",
              lg: "0px",
            }}
          />

          <HStack
            w="full"
            minH="650px"
            wrap="wrap"
            justifyContent={"flex-start"}
            px="10"
            alignItems={"flex-start"}
            mb="100px"
          >
            {controller.categories?.map((category) => (
              <Box key={category} w="260px" bg="var(--blue)">
                <VStack
                  h="full"
                  alignItems={"flex-start"}
                  justifyContent={"flex-start"}
                >
                  <Text color="var(--white)" fontWeight={600} fontSize={"20px"}>
                    {category}
                  </Text>

                  {controller.articles?.map((article) => {
                    if (article.category !== category) return null;
                    return (
                      <Link
                        href={`/knowledgebase/${article.slug}`}
                        color="var(--chart-blue)"
                      >
                        {article.title}
                      </Link>
                    );
                  })}
                </VStack>
              </Box>
            ))}
          </HStack>
        </Box>
      </Center>
      <Footer />
    </Box>
  );
};

const Subtitle = ({ children }: { children: string }) => (
  <Text fontSize="md" color="var(--white)">
    {children}
  </Text>
);
