import { atom } from "jotai";
import { Account } from "../types/Account";

export interface AccountState {
  accounts: Account[];
  selectedAccount: any;
}

const buildInitialState = (): AccountState => {
  const state: any = {
    accounts: [],
    selectedAccount: null,
  };

  return state;
};

export const AccountAtom = atom<AccountState>(buildInitialState());
