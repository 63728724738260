import { RowDoubleClickedEvent } from "ag-grid-enterprise";

export const progressTourOneStep = (setCurrentStep, currentStep, setIsOpen) => {
  if (
    window.location.href.includes("tutorial=true") ||
    window.location.href.includes("result=paid")
  ) {
    setCurrentStep(currentStep + 1);
  }
};

export const progressTour = (
  params: RowDoubleClickedEvent<any, any>,
  setCurrentStep,
  currentStep: number,
  setIsOpen
) => {
  if (
    window.location.href.includes("tutorial=true") ||
    window.location.href.includes("result=paid")
  ) {
    const fields = ["book", "underlyingName", "positionName"];
    if (fields.includes(params.node.field)) {
      setCurrentStep(currentStep + 1);
    }
  }
};
