import { HStack, Text } from "@chakra-ui/react";
import { StockLogo } from "../../design_library";

export const StockLogoRender = (params: any) => {
  return (
    <HStack>
      <StockLogo size="20" stock={params.value} />
      <Text fontWeight={600}>{params.value}</Text>
    </HStack>
  );
};
