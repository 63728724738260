import APIEndpoint from "./APIEndpoint";

export const resetPassword = async (email: string) => {
  return fetch(APIEndpoint + "/users/reset", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email: email }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      console.log("API Call, error resetting password", e);
    });
};
