import { GetContextMenuItemsParams } from "ag-grid-community";

export const getAllChildrenOfOverNode = (
  params: GetContextMenuItemsParams,
  transactions: any
) => {
  const children = [];

  if (!params.overNode?.allLeafChildren) {
    params.api.getSelectedNodes().map((node) => {
      children.push(node.data);
    });

    if (params?.api?.getSelectedNodes().length === 0)
      children.push(params?.overNode?.data);
  } else if (params?.overNode?.allLeafChildren) {
    params?.overNode?.allLeafChildren?.forEach((child) => {
      if (child.data.tradeIds) {
        child.data.tradeIds.forEach((tradeId) => {
          const trade = transactions.trades.find((transaction) => {
            return transaction.id === tradeId;
          });

          if (trade) {
            children.push(trade);
          }
        });
      } else {
        children.push(child.data);
      }
    });
  }

  return children;
};
