// IMPORTANT NOTE: Do not change teh order of the enum values as they relate to market conventions
export const defaultCurrency: string = "USD";
export enum Currency {
  XAG = "XAG",
  XAU = "XAU",
  XPD = "XPD",
  XPT = "XPT",
  EUR = "EUR",
  GBP = "GBP",
  AUD = "AUD",
  NZD = "NZD",
  USD = "USD",
  USB = "USB",
  CAD = "CAD",
  CHF = "CHF",
  NOK = "NOK",
  SEK = "SEK",
  DKK = "DKK",
  JPY = "JPY",
  MXN = "MXN",
  SGD = "SGD",
  SAR = "SAR",
  ZAR = "ZAR",
  ISK = "ISK",
  CNY = "CNY",
  AED = "AED",
  HKD = "HKD",
  KRW = "KRW",
  ARS = "ARS",
  BRL = "BRL",
  BRO = "BRO",
  CLP = "CLP",
  CNA = "CNA",
  CNH = "CNH",
  COP = "COP",
  CZK = "CZK",
  EGP = "EGP",
  GRK = "GRK",
  HRK = "HRK",
  HUF = "HUF",
  IDR = "IDR",
  ILS = "ILS",
  INA = "INA",
  INR = "INR",
  KRA = "KRA",
  KWD = "KWD",
  KZT = "KZT",
  MAD = "MAD",
  MYR = "MYR",
  NTD = "NTD",
  PEN = "PEN",
  PHP = "PHP",
  PLN = "PLN",
  QAR = "QAR",
  RON = "RON",
  RSD = "RSD",
  RUB = "RUB",
  RUD = "RUD",
  SKK = "SKK",
  THA = "THA",
  THB = "THB",
  TRL = "TRL",
  TRY = "TRY",
  TWD = "TWD",
  UAH = "UAH",
  VND = "VND",
}
