import { Box, Button, Heading, Input, Stack, Text } from "@chakra-ui/react";
import { PrimaryButton } from "../../components/design_library";

export const BlogPageHeader = () => {
  return (
    <Box pt="8" pb="8">
      <Stack spacing={{ base: "8", md: "10" }} align="center">
        <Stack spacing={{ base: "4", md: "6" }} textAlign="center">
          <Stack spacing="4">
            <Text
              fontWeight="semibold"
              color="accent"
              fontSize={{ base: "sm", md: "md" }}
            >
              Our Blog
            </Text>
            <Heading size={{ base: "md", md: "lg" }}>Latest Updates</Heading>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
