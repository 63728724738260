import React, { useEffect, useRef } from "react";
import { Stack } from "@chakra-ui/react";
import { CreatableSelect } from "chakra-react-select";
import { Trade } from "../../types";
import { useAtomValue } from "jotai";
import { AccountAtom, TransactionsAtom } from "../../store";
import { useTransactions } from "../../hooks/useTransactions";

export const TagEntry = (props: { trade: Trade }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const transactionState = useAtomValue(TransactionsAtom);
  const accountState = useAtomValue(AccountAtom);
  const [options, setOptions] = React.useState(transactionState.tags);
  const initialised = useRef<boolean>(false);
  const transactions = useTransactions();

  useEffect(() => {
    if (initialised.current === false) {
      initialised.current = true;
    }
  });

  const onChange = (value: string) => {
    const tags = value.map((item: any) => item.value);
    transactions.setTags(
      tags,
      [props.trade],
      accountState?.selectedAccount?.id
    );
  };

  return (
    <Stack spacing="4" mt="2" mb="1">
      <CreatableSelect
        tagVariant="solid"
        value={props.trade.tags.map((item) => {
          return { value: item, label: item };
        })}
        placeholder="Add Tags"
        isMulti={true}
        options={
          options &&
          options.map((item) => {
            return { value: item, label: item };
          })
        }
        onChange={(e) => onChange(e)}
      />
    </Stack>
  );
};
