import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  HStack,
  Heading,
  Stack,
  Text,
  createIcon,
} from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useNavigate } from "react-router";
import {
  CustomInputGroup,
  ItemHeading,
  PrimaryButton,
  TextInput,
} from "../../../components/design_library";
import { AppStateAtom } from "../../../store";
import { useLoginController } from "./useLoginController";
import { AuthenticationFrame } from "../components/AuthenticationFrame";
import { HeaderContainer, Meta } from "../../../components";

/* eslint-disable @typescript-eslint/no-unused-vars */
export const Login = (props: any) => {
  const [showPassword, setShowPassword] = useState(false);
  const appState = useAtomValue(AppStateAtom);
  const controller = useLoginController();
  const navigate = useNavigate();

  return (
    <AuthenticationFrame>
      <Meta
        title={"Login"}
        description={"Login to WealthBee"}
        canonical={"https://wealthbee.io/app/login"}
      />
      <HeaderContainer>
        <Heading mb="20px" fontSize={"3xl"}>
          Sign in
        </Heading>
      </HeaderContainer>
      <FormControl>
        <Stack spacing={5}>
          <FormControl
            id="login"
            isRequired
            isInvalid={controller.formEmailError ? true : false}
          >
            <Stack spacing={4}>
              <CustomInputGroup>
                <ItemHeading>Email</ItemHeading>
                <TextInput
                  bg={"var(--bg)"}
                  type={"text"}
                  placeholder="Enter email"
                  disabled={controller.loading}
                  onChange={(e: any) => {
                    controller.setEmail(e.target.value);
                  }}
                  value={controller.email}
                  isRequired={true}
                  autoComplete="email"
                  id="usernamelogin"
                  autocomplete="email"
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") {
                      controller.login();
                    }
                  }}
                />
                <FormErrorMessage>{controller.formEmailError}</FormErrorMessage>
              </CustomInputGroup>
              <CustomInputGroup>
                <ItemHeading>Password</ItemHeading>
                <TextInput
                  bg={"var(--bg)"}
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  id="passwordlogin"
                  autoComplete="current-password"
                  disabled={controller.loading}
                  autocomplete="current-password"
                  onChange={(e: any) => {
                    controller.setPassword(e.target.value);
                  }}
                  value={controller.password}
                  isRequired={true}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") {
                      controller.login();
                    }
                  }}
                  toggleShow={true}
                  toggleShowContents={showPassword ? "Hide" : "Show"}
                  toggleShowFunc={() => setShowPassword(!showPassword)}
                />

                <FormErrorMessage>
                  {controller.formPasswordError}
                </FormErrorMessage>
              </CustomInputGroup>

              <FormHelperText>
                <Text align="left" textDecor={"underline"} mb="10px">
                  <Box
                    as="a"
                    href="#"
                    onClick={() => {
                      navigate("/app/reset");
                    }}
                    color={"var(--accent)"}
                  >
                    Forgot password
                  </Box>
                </Text>
              </FormHelperText>

              <PrimaryButton
                isLoading={controller.loading}
                colorScheme="blue"
                variant="solid"
                onClick={() => controller.login()}
                disabled={controller.loading}
                isFullWidth
                loadingText="Logging in..."
                color="var(--white)"
                id="signinbutton"
              >
                Sign in
              </PrimaryButton>
              {controller.errorMessage && (
                <Text size="xs" color="var(--red)">
                  {controller.errorMessage}
                </Text>
              )}

              <HStack>
                <Divider />
                <Text textStyle="sm" whiteSpace="nowrap" color="fg.muted">
                  or sign in with
                </Text>
                <Divider />
              </HStack>

              <Stack spacing="4" mt="">
                <Button
                  colorScheme="gray"
                  leftIcon={<GoogleIcon />}
                  onClick={() => {
                    if (process.env.NODE_ENV === undefined) {
                      window.location.href =
                        "http://localhost:3123/api/v1/users/auth/google";
                    } else if (process.env.NODE_ENV === "production_beta") {
                      window.location.href =
                        "https://beta-api.wealthbee.io/api/v1/users/auth/google";
                    } else {
                      window.location.href =
                        "https://api.wealthbee.io/api/v1/users/auth/google";
                    }
                  }}
                >
                  Google
                </Button>
              </Stack>

              <Text
                mt="4"
                align="center"
                maxW="md"
                fontWeight="medium"
                color="white"
              >
                <span>Don't have an account?</span>
                <Box
                  as="a"
                  href="#"
                  marginStart="2"
                  onClick={() => {
                    navigate("/app/register");
                  }}
                  color={"var(--accent)"}
                  textDecor={"underline"}
                >
                  Get started
                </Box>
              </Text>
            </Stack>
          </FormControl>
        </Stack>
      </FormControl>
    </AuthenticationFrame>
  );
};

const GoogleIcon = createIcon({
  displayName: "GoogleIcon",
  path: (
    <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
      <path
        fill="#4285F4"
        d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
      />
      <path
        fill="#34A853"
        d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
      />
      <path
        fill="#FBBC05"
        d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
      />
      <path
        fill="#EA4335"
        d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
      />
    </g>
  ),
});
