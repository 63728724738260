import { APIEndpoint } from ".";

// Create a new account
export const uploadImage = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);

  return fetch(`${APIEndpoint}/assets/upload`, {
    method: "POST",
    headers: {
      Authorization: localStorage.getItem("token"),
    },
    body: formData,
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error uploading image:", error));
};
