import React, { useMemo } from "react";
import { Instrument, InstrumentType } from "../../../../types";
import { Box, Button, Flex } from "@chakra-ui/react";
import { useInstrumentFormController } from "./useInstrumentFormController";
import FormBase from "../../../../components/forms/Form";

interface InstrumentFormProps {
  instrument?: Instrument;
  volatility?: number;
  updateInstrument: (instrument: Instrument) => void;
}

export const InstrumentForm = (props: InstrumentFormProps) => {
  const controller = useInstrumentFormController(props);

  const canCalcTheoreticalPrice = useMemo(() => {
    if (!props.instrument) return false;
    if (props.instrument.instrumentType !== InstrumentType.Option) return false;
    if (props.volatility === undefined) return false;
    return true;
  }, [props.instrument, props.volatility]);

  const form = useMemo(() => {
    return (
      <Flex
        display="block"
        overflowY="auto"
        h="100%"
        w="100%"
        direction="column"
        px={2}
        py={1}
      >
        {canCalcTheoreticalPrice && (
          <Flex float="right" mr="4px" mt="20px">
            <Button
              colorScheme="green"
              size="sm"
              onClick={() => {
                controller.calculateTheoreticalPrice();
              }}
            >
              Calculate Theoretical Price
            </Button>
          </Flex>
        )}
        <FormBase
          isLoading={controller.isLoading}
          title="Instrument Details"
          subtitle=""
          onSubmit={controller.submitPayload}
          submitText="Apply Changes"
          schema={controller.schema}
        />
      </Flex>
    );
  }, [controller.schema]);

  return <Box>{form}</Box>;
};
