import { Box, Stack, HStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom"; // Import useParams and Link
import { BlogPageHeader } from "./BlogPageHeader";
import { BlogPost } from "./BlogPost";
import NavBar from "../homepage/menu/App";
import Footer from "../homepage/footer/App";
import { Helmet } from "react-helmet";
import React from "react";
import { getBlogsArticles } from "../../api";

const POSTS_PER_PAGE = 5;

export const Blog = () => {
  const { page } = useParams(); // Get page number from URL
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(parseInt(page) || 1); // Use page from URL or default to 1
  const [totalPages, setTotalPages] = useState(1);

  const getTheBlogPosts = async (page: number) => {
    setLoading(true);
    try {
      const data = await getBlogsArticles(
        true,
        undefined,
        page,
        POSTS_PER_PAGE
      );
      setPosts(data.articles);
      setTotalPages(
        Math.ceil(
          data.metadata.pagination.totalArticles /
            data.metadata.pagination.pageSize
        )
      );
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTheBlogPosts(currentPage);
  }, [currentPage]);

  const renderPaginationLinks = () => {
    const paginationLinks = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationLinks.push(
        <a key={i} href={`/blog/page/${i}`}>
          <Box
            as="span"
            padding="8px"
            margin="4px"
            bg={i === currentPage ? "var(--accent)" : "var(--light-grey)"}
            color={i === currentPage ? "var(--white)" : "var(--light-grey)"}
            borderRadius="4px"
            cursor="pointer"
          >
            {i}
          </Box>
        </a>
      );
    }
    return paginationLinks;
  };

  return (
    <Box background={"var(--bg)"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blog</title>
        <link rel="canonical" href="https://wealthbee.io/blog" />
        <meta
          name="keywords"
          content="investment performance analysis, track investment returns, investment metrics, options trading "
        />
      </Helmet>

      <NavBar />
      <Stack spacing="10" shouldWrapChildren mb="40px" px="20px">
        <BlogPageHeader />

        <Box maxW="2xl" mx="auto">
          <Stack spacing="16">
            {loading && <div>Loading...</div>}
            {error && <div>Error: {error}</div>}
            {!loading &&
              !error &&
              posts.map((post) => {
                return <BlogPost key={post.articleId} post={post} />;
              })}
          </Stack>

          {!isNaN(totalPages) && totalPages > 1 && (
            <HStack justifyContent="center" mt="10" spacing="4">
              {currentPage > 1 && (
                <a href={`/blog/page/${currentPage - 1}`}>
                  <Box
                    as="span"
                    padding="8px"
                    margin="4px"
                    borderRadius="4px"
                    cursor="pointer"
                  >
                    Previous
                  </Box>
                </a>
              )}
              {renderPaginationLinks()}
              {currentPage < totalPages && (
                <a href={`/blog/page/${currentPage + 1}`}>
                  <Box
                    as="span"
                    padding="8px"
                    margin="4px"
                    borderRadius="4px"
                    cursor="pointer"
                  >
                    Next
                  </Box>
                </a>
              )}
            </HStack>
          )}
        </Box>
      </Stack>
      <Footer />
    </Box>
  );
};
