import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Heading,
  HStack,
  Tag,
  Text,
  VStack,
  Spinner,
  Switch,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, EditIcon, MinusIcon } from "@chakra-ui/icons";
import { useInsightsController } from "../useInsightsController";
import {
  SecondaryButton,
  TextInput,
} from "../../../../components/design_library";
import { useAtomValue } from "jotai";
import { AppStateAtom } from "../../../../store";
import { DashboardLayoutStateStore } from "../../../../store/DashboardLayout";
import { InsightTemplate } from "../../../../types/Insight";
import { BiBarChart, BiData, BiLineChart, BiTable } from "react-icons/bi";
import { useLayout } from "../../helpers/Layout";
import { FaBatteryEmpty, FaHashtag, FaSearchMinus } from "react-icons/fa";
import {
  MdCompare,
  MdNotificationsPaused,
  MdOutlineNotificationsPaused,
  MdOutlineSignalCellularNodata,
  MdSignalCellularNodata,
  MdSignalWifiStatusbarNotConnected,
} from "react-icons/md";
import { RiCashLine } from "react-icons/ri";
import { TbChartInfographic } from "react-icons/tb";

interface InsightSearchListProps {
  setPage: (page: string) => void;
  setInsightData: (insight: InsightTemplate) => void;
}

const InsightSearchList: React.FC<InsightSearchListProps> = (
  props: InsightSearchListProps
) => {
  const controller = useInsightsController();
  const { addInsightToLayouts, removeInsightFromLayouts } = useLayout();
  const dashboardLayoutState = useAtomValue(DashboardLayoutStateStore);
  const appState = useAtomValue(AppStateAtom);
  const timer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    timer.current = setTimeout(() => {
      controller.fetchInsights(controller.displaysPage, controller.searchQuery);
    }, 400);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [controller.searchQuery]);

  const isInsightEnabled = (insightId: number) =>
    dashboardLayoutState.enabledInsights?.some(
      (id) => parseInt(id) === (insightId as number)
    );

  const handleToggleInsight = (insight: InsightTemplate) => {
    if (isInsightEnabled(insight.insightId)) {
      removeInsightFromLayouts(insight);
    } else {
      addInsightToLayouts(insight);
    }
  };

  return (
    <Box flex="1" fontSize="14px" minH="300px">
      <Box px="3" pb="2">
        <TextInput
          placeholder="Search insights"
          bg="var(--bg)"
          onChange={(e) => controller.setSearchQuery(e.target.value)}
          value={controller.searchQuery}
        />
      </Box>

      <HStack px="3" alignItems="flex-start">
        <VStack flex="4" maxH="1200px" overflow="auto">
          {controller.loading && controller.insights.length === 0 && (
            <VStack
              h="100%"
              minH="300px"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Spinner />
            </VStack>
          )}
          {!controller.loading && controller.insights.length === 0 && (
            <VStack h="160px" justifyContent="center" mt="40px">
              <FaSearchMinus fontSize="40px" />
              <Text fontSize="lg" maxW="200px" textAlign={"center"}>
                No insights found, try a different search term...
              </Text>
            </VStack>
          )}
          {controller.insights.map((insight) => {
            const enabled = isInsightEnabled(insight.insightId as number);
            const isOwner =
              parseInt(appState.user?.id) === parseInt(insight.ownerUserId);

            return (
              <Box
                key={insight.insightId}
                px={5}
                py={3}
                borderRadius={5}
                border="solid 1px var(--gray)"
                w="full"
              >
                <HStack justifyContent={"space-between"}>
                  <HStack alignItems={"center"} justifyContent={"center"}>
                    <Box
                      w="50px"
                      h="50px"
                      mr="10px"
                      borderRadius="6px"
                      bg={enabled ? "var(--green)" : "var(--gray)"}
                      color="var(--white)"
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {insight.type === "Number" && (
                        <FaHashtag fontSize="20px" />
                      )}
                      {insight.type === "Barchart" && (
                        <BiBarChart fontSize="20px" />
                      )}
                      {insight.type === "Comparison" && (
                        <MdCompare fontSize="20px" />
                      )}
                      {insight.type === "Linechart" && (
                        <BiLineChart fontSize="20px" />
                      )}
                      {insight.type === "ProfitAndLossLineChart" && (
                        <RiCashLine fontSize="20px" />
                      )}
                      {insight.type === "Dendrogram" && (
                        <TbChartInfographic fontSize="20px" />
                      )}
                      {insight.type === "Table" && <BiTable fontSize="20px" />}
                    </Box>

                    <VStack alignItems={"flex-start"}>
                      <HStack>
                        <Heading
                          size="xs"
                          maxW="300px"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {insight.title}
                        </Heading>
                        {insight.official && (
                          <Tag colorScheme="green">Official</Tag>
                        )}

                        {insight.isDefaultInsight && (
                          <Tag colorScheme="blue">Recommended</Tag>
                        )}
                      </HStack>

                      <HStack justifyContent="space-between">
                        <Text
                          size="xs"
                          maxW="300px"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {insight.description}
                        </Text>
                      </HStack>
                    </VStack>
                  </HStack>

                  <HStack
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    {isOwner && (
                      <>
                        <SecondaryButton
                          bg="transparent"
                          onClick={() => {
                            props.setInsightData(insight as InsightTemplate);
                            props.setPage("creator");
                          }}
                          px="2px"
                        >
                          <EditIcon fontSize="20px" />
                        </SecondaryButton>
                        <SecondaryButton
                          bg="transparent"
                          onClick={() =>
                            controller.handleDelete(insight.insightId as number)
                          }
                          px="2px"
                        >
                          <DeleteIcon fontSize="20px" />
                        </SecondaryButton>
                      </>
                    )}

                    <Switch
                      colorScheme={enabled ? "green" : "red"}
                      size="lg"
                      isChecked={enabled}
                      onChange={() => handleToggleInsight(insight)}
                    />
                  </HStack>
                </HStack>
              </Box>
            );
          })}
        </VStack>
      </HStack>
    </Box>
  );
};

export default InsightSearchList;
