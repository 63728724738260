import React from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Flex, Tag } from "@chakra-ui/react";

// !BP

export const IndicatorColumn = (props: any) => {
  if (!props.value) return null;

  switch (props.value) {
    case "Rising":
      return (
        <Flex justifyContent="flex-start" alignItems="center" height="36px" lineHeight="100%">
          <Tag size={"sm"} variant="solid" borderRadius="full" colorScheme="green">
            Rising
          </Tag>
        </Flex>
      );
    case "Decreasing":
      return (
        <Flex justifyContent="flex-start" alignItems="center" height="36px" lineHeight="100%">
          <Tag size={"sm"} variant="solid" borderRadius="full" colorScheme="red">
            Decreasing
          </Tag>
        </Flex>
      );
    case "UP_DOWN":
      return (
        <Flex justifyContent="flex-start" alignItems="center" height="36px" lineHeight="100%">
          <Tag
            size={"xs"}
            pl="1"
            pr="1"
            variant="solid"
            borderRadius="full"
            colorScheme="grey"
            bg="black"
            border="solid 1px black"
          >
            {props.label1}
            <ChevronUpIcon color="green" />
            {props.label2}
            {props.type === "JOINT" && props.label2 && <ChevronDownIcon color="red" />}
          </Tag>
        </Flex>
      );
    case "Flat":
      return (
        <Flex justifyContent="flex-start" alignItems="center" height="36px" lineHeight="100%">
          <Tag size={"sm"} variant="solid" borderRadius="full" colorScheme="gray">
            Flat
          </Tag>
        </Flex>
      );
    case "DOWN_UP":
      return (
        <Flex justifyContent="flex-start" alignItems="center" height="36px" lineHeight="100%">
          <Tag
            size={"xs"}
            pl="1"
            pr="1"
            variant="solid"
            borderRadius="full"
            colorScheme="grey"
            bg="black"
            border="solid 1px black"
          >
            {props.label1}
            <ChevronDownIcon color="red" />
            {props.type === "JOINT" && props.label2}
            {props.type === "JOINT" && props.label2 && <ChevronUpIcon color="green" />}
          </Tag>
        </Flex>
      );
  }

  return "";
};
