import React from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  HStack,
  Box,
} from "@chakra-ui/react";
import { CustomTag, StockLogo } from "../design_library";
import convertSymbolToReadable from "../grid/utils/convertSymbolToReadable";
import NoDataAvailable from "../insights/NoDataAvailable";

const currencyFormat = (num: string) => {
  return (
    "$" +
    parseFloat(num)
      .toFixed(2)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  );
};

const formatValue = (key: string, value: any) => {
  if (!value) return null;

  switch (key) {
    case "positionId":
      return "";
    case "quantity":
      return (
        <Text color={value > 0 ? "var(--green)" : "var(--red)"}>
          {parseFloat(value).toFixed(2)}
        </Text>
      );
    case "unrealisedPnl":
      return (
        <Text color={value > 0 ? "var(--green)" : "var(--red)"}>
          {currencyFormat(value)}
        </Text>
      );
    case "expiry":
      return new Date(value).toLocaleDateString();
    case "strike":
      return parseFloat(value).toFixed(2);
    case "price":
      return currencyFormat(value);
    case "underlyingSymbol":
      return (
        <Box
          border={" solid 1px var(--gray)"}
          h="40px"
          w="40px"
          borderRadius={"50%"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          overflow={"hidden"}
        >
          <StockLogo stock={value} />
        </Box>
      );

    case "symbol":
      return (
        <HStack>
          <Text fontWeight={600}>{convertSymbolToReadable(value)}</Text>
        </HStack>
      );
    case "putCall":
      return value ? (
        <CustomTag
          h="4px"
          bg={value === "Put" ? "var(--trade-put)" : "var(--trade-call)"}
        >
          {value}
        </CustomTag>
      ) : null;
    default:
      return value;
  }
};

const formatKey = (key: string) => {
  switch (key) {
    case "positionId":
      return "";
    case "quantity":
      return "Quantity";
    case "unrealisedPnl":
      return "Unrealised PnL";
    case "expiry":
      return "Expiry";
    case "strike":
      return "Strike";
    case "price":
      return "Price";
    case "underlyingSymbol":
      return "";
    case "symbol":
      return "Symbol";
    case "putCall":
      return "Put/Call";
    case "instrumentType":
      return "Type";
    default:
      return key;
  }
};

export const DynamicTable = ({
  data,
}: {
  data: { [key: string]: string | number }[];
}) => {
  if (!data || data.length === 0) {
    return <NoDataAvailable title="this table." />;
  }

  const keys = Object.keys(data[0]);

  return (
    <TableContainer
      h="100%"
      justifySelf={"flex-start"}
      w="full"
      maxW="100%"
      overflowY={"scroll"}
      alignContent={"flex-start"}
    >
      <Table size="sm">
        <Thead bg="var(--table-header-bar)">
          <Tr h="50px" fontSize="12px" textAlign={"left"}>
            {keys.map((key) => (
              <Th
                key={key}
                style={{
                  padding: "0 0px",
                }}
              >
                {formatKey(key)}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((item, index) => {
            return (
              <Tr key={index} p="0" m="0">
                {keys.map((key) => (
                  <Td px="0" key={key} textAlign={"left"}>
                    {formatValue(key, item[key])}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
