import React, { useEffect } from "react";
import { Responsive, WidthProvider, Layouts } from "react-grid-layout";
import "./GridLayout.css";
import { Spinner } from "../../../components";
import { useAtom, useAtomValue } from "jotai";
import { DashboardLayoutStateStore } from "../../../store/DashboardLayout";
import { InsightContainer } from "../../../components/insights/InsightContainer";
import { useGridLayoutController } from "./useGridLayoutController";
import { Center, Heading, Text, VStack } from "@chakra-ui/react";
import {
  AccountAtom,
  PositionStateAtom,
  TransactionsAtom,
} from "../../../store";
import { useFilters } from "../../../hooks/useFilters";
import { FaRegLightbulb } from "react-icons/fa";
import InsightsManager from "../insights/InsightsManager";
import Uploads from "../../uploads/Uploads";
import { Toolbar } from "../../../components/toolbar/Toolbar";
import { KnowledgeBaseHelperTopics, ToolbarFeatures } from "../../../types";

const ResponsiveGridLayout = WidthProvider(Responsive);

interface GridLayoutProps {}

interface DashboardLayoutState {
  layouts: Layouts;
  gridLoading: boolean;
  currentBreakpoint: string;
}

interface InsightItem {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  data?: any;
}

const GridLayout: React.FC<GridLayoutProps> = () => {
  const dashboardLayoutState = useAtomValue<DashboardLayoutState>(
    DashboardLayoutStateStore
  );
  const controller = useGridLayoutController();
  const filters = useFilters();
  const [dashboardLayoutStore, setDashboardingLayoutState] = useAtom(
    DashboardLayoutStateStore
  );
  const accountState = useAtomValue(AccountAtom);
  const positionsState = useAtomValue(PositionStateAtom);
  const transactionsState = useAtomValue(TransactionsAtom);

  useEffect(() => {
    controller.fetchLayouts();
    controller.handleResize();
    window.addEventListener("resize", controller.handleResize);
    return () => {
      window.removeEventListener("resize", controller.handleResize);
    };
  }, [
    accountState?.selectedAccount?.id,
    filters.selectedUnderlying,
    filters.selectedTag,
    filters.selectedStrategy,
    filters.selectedPositionGroup,
    filters.selectedBook,
    positionsState.positions,
  ]);

  if (controller.loading) {
    return (
      <VStack h="80%" justifyContent={"center"}>
        <Spinner w="80px" h="80px" />
      </VStack>
    );
  }

  if (positionsState.positions.length === 0) {
    return (
      <Center h="70%">
        <VStack>
          <FaRegLightbulb size="50" mb="10px" />
          <Heading size="md">No Positions Found</Heading>
          <Text maxW="300px" textAlign={"center"} mb="20px">
            Please add your trades to see insights.
          </Text>
          <Uploads />
        </VStack>
      </Center>
    );
  }

  return (
    <>
      {dashboardLayoutState?.enabledInsights?.length === 0 && (
        <Center h="70%">
          <VStack>
            <FaRegLightbulb size="50" mb="10px" />
            <Heading size="md">No Insights Enabled</Heading>
            <Text maxW="300px" textAlign={"center"}>
              Please enable insights using the button below.
            </Text>
            <InsightsManager />
          </VStack>
        </Center>
      )}
      <ResponsiveGridLayout
        useCSSTransforms={false}
        className="layout"
        style={{ width: "100%", padding: "5px", margin: "5px" }}
        layouts={dashboardLayoutState.layouts}
        breakpoints={{
          lg: 1200,
          md: 996,
          sm: 768,
          xs: 480,
          xxs: 0,
        }}
        cols={{
          lg: 12,
          md: 10,
          sm: 6,
          xs: 4,
          xxs: 2,
        }}
        autoSize
        margin={[10, 10]}
        containerPadding={[0, 0]}
        allowOverlap={false}
        onBreakpointChange={controller.setBreakpoint}
        onDragStop={controller.updateLayout}
        onResizeStop={controller.updateLayout}
        compactType="vertical"
        draggableHandle=".drag-handle"
      >
        {dashboardLayoutState.layouts[
          dashboardLayoutState.currentBreakpoint
        ].map((item: InsightItem) => {
          if (item.data) {
            return (
              <div key={item.i}>
                <InsightContainer insight={item?.data} />
              </div>
            );
          } else {
            return null;
          }
        })}
      </ResponsiveGridLayout>
    </>
  );
};

export default GridLayout;
