import { Stack, Text } from "@chakra-ui/react";

export const FormHeader = (props: { title: string; subtitle: string }) => {
  return (
    <Stack spacing="4">
      <Text fontSize="2xl" fontWeight="bold">
        {props.title}
      </Text>
      <Text fontSize="md">{props.subtitle}</Text>
    </Stack>
  );
};
