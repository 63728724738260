import {
  Box,
  Center,
  FormErrorIcon,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { FaSadCry } from "react-icons/fa";
import DiscordInvite from "../misc/DiscordInvite";
import { PrimaryButton, SecondaryButton } from "../design_library";
import { BiError } from "react-icons/bi";
import { MdError } from "react-icons/md";

export class ErrorBoundary extends React.Component {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box pt="60px">
          <Center>
            <VStack>
              <MdError size="100px" />
              <Heading>Something went wrong!</Heading>
              <Text>
                This error has been reported. Please refresh the page and try
                again.
              </Text>
              <Text pb="20px">If this persists please contact support!</Text>
              <DiscordInvite />
              <Text fontSize="xs" color="var(--red)" pt="40px">
                {this.state.error && this.state.error.toString()}
              </Text>
              <PrimaryButton onClick={() => window.location.reload()}>
                Click here to reload the website
              </PrimaryButton>

              <Text mt="20px" fontSize="10px">
                {JSON.stringify(this.state, null, 2)}
              </Text>
            </VStack>
          </Center>
        </Box>
      );
    }

    return this.props.children;
  }
}
