import { VStack } from "@chakra-ui/react";
import styles from "./index.module.css";
import "./style.css";

export const CustomInputGroup = (props: any) => {
  return (
    <VStack className={styles.inputGroup} {...props}>
      {props.children}
    </VStack>
  );
};
