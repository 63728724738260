import { useEffect, useRef, useState } from "react";
import { Instrument } from "../../../types";
import { AccountAtom } from "../../../store";
import { useAtomValue } from "jotai";
import { updateInstrument } from "../../../api";

interface UseInstrumentsController {
  selectedInstrument?: Instrument;
  volatility?: number;
  instrument?: Instrument;
  children?: Instrument[];
  loadInstrument: (instrument: Instrument, children?: Instrument[]) => void;
  selectInstrument: (instrument: Instrument) => void;
  applyChanges: (instrument: Instrument) => void;
}

interface ILocalState {
  selectedInstrument?: Instrument;
  volatility?: number;
  instrument?: Instrument;
  children?: Instrument[];
}

export const useInstrumentsController = (): UseInstrumentsController => {
  const initialised = useRef(false);

  const [localState, setLocalState] = useState<ILocalState>({
    instrument: undefined,
    children: [],
  });

  const account = useAtomValue(AccountAtom);

  const loadInstrument = (instrument: Instrument, children?: Instrument[]) => {
    setLocalState((prevState) => {
      return {
        ...prevState,
        selectedInstrument: instrument,
        volatility: instrument.volatility,
        instrument,
        children,
      };
    });
  };

  const selectInstrument = (instrument: Instrument) => {
    setLocalState((prevState) => {
      return {
        ...prevState,
        selectedInstrument: { ...instrument, volatility: prevState.volatility },
      };
    });
  };

  const applyChanges = async (instrument: Instrument) => {
    const result = await updateInstrument(instrument);
    if (result.success && result.data) {
      setLocalState((prevState) => {
        return {
          ...prevState,
          selectedInstrument: result.data,
        };
      });
    }
  };

  useEffect(() => {
    if (initialised.current) return;
    initialised.current = true;
  }, [account]);

  return {
    selectedInstrument: localState.selectedInstrument,
    instrument: localState.instrument,
    volatility: localState.volatility,
    children: localState.children,
    loadInstrument,
    selectInstrument,
    applyChanges,
  };
};
