import {
  AspectRatio,
  Avatar,
  Box,
  Heading,
  HStack,
  Image,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { Meta } from "../../components";
import React from "react";
import { BlogArticle } from "../../types/BlogArticle";

interface BlogPostProps {
  post: BlogArticle;
}

export const BlogHeader = (props: BlogPostProps) => {
  const post = props.post;
  return (
    <Stack spacing="6">
      <Meta
        title={post.title}
        description={
          post.excerpt.length > 160
            ? post.excerpt.substring(0, 160).replace(/<[^>]*>?/gm, "") + "..."
            : post.excerpt.replace(/<[^>]*>?/gm, "")
        }
        canonical={
          window.location.href.includes("/blog/post/")
            ? `https://wealthbee.io/blog/post/${post.articleId}/${post.slug}`
            : `https://wealthbee.io/blog`
        }
      />

      <a
        href={`${window.location.origin}/blog/post/${post.articleId}/${post.slug}`}
      >
        <AspectRatio ratio={3 / 2} mt="60px">
          <Image
            src={post.image}
            objectPosition="top"
            objectFit="cover"
            border="solid 1px var(--accent) !important"
            fallback={
              <Box
                bg="var(--dark-gray)"
                borderRadius="xl"
                textAlign={"center"}
                px="20px"
              >
                <Heading>{post.title}</Heading>
              </Box>
            }
            alt={post.title}
            borderRadius="xl"
          />
        </AspectRatio>
      </a>
      <Stack spacing="3">
        <Stack spacing="1">
          <a
            href={`${window.location.origin}/blog/post/${post.articleId}/${post.slug}`}
          >
            <Text fontSize="sm" fontWeight="semibold" color="accent">
              News
            </Text>
            <Heading
              size="xs"
              fontWeight="semibold"
              fontSize={{ base: "xl", lg: "2xl" }}
              lineHeight={{ base: "1.5", lg: "2rem" }}
            >
              {post.title}
            </Heading>
          </a>
        </Stack>
        <Text>{post.excerpt}</Text>
      </Stack>
      {post.author && (
        <HStack spacing="3" mt="6px">
          <Avatar
            size="md"
            name={post.author.name}
            src={post.author.avatarUrl}
          />
          <Box lineHeight="1.25rem">
            <Text fontSize="sm" color="default">
              {post.author.name}
            </Text>
            <Text fontSize="sm"> {post.publishedAt}</Text>
          </Box>
        </HStack>
      )}
    </Stack>
  );
};
