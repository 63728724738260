import React from "react";
import { HStack, Stack, Text } from "@chakra-ui/react";
import { Required } from "./Required";
import { RadioButton, RadioButtonGroup } from "../../design_library";

export const RadioInput = (props: {
  label: string;
  value: string;
  field: string;
  required: boolean;
  enums: string[];
  error: string;
  onChange: (field: string, value: string) => void;
}) => {
  return (
    <Stack spacing="4" mt="2" mb="1">
      <Text>
        {props.label}
        {props.required && <Required />}
      </Text>
      <HStack>
        <Stack spacing="5">
          <RadioButtonGroup
            key={"md"}
            defaultValue="left"
            size={"md"}
            onChange={(e) => props.onChange(props.field, e)}
          >
            {props.enums.map((item) => {
              return (
                <RadioButton key={item} value={item} flex="1">
                  {item}
                </RadioButton>
              );
            })}
          </RadioButtonGroup>
        </Stack>
      </HStack>
      <Text color="red.500">{props.error}</Text>
    </Stack>
  );
};
