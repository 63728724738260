import React, { useEffect, useState } from "react";
import { getThemes, setTheme } from "../../api/Theme";
import {
  Box,
  Button,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useAtom, useAtomValue } from "jotai";
import { AppStateAtom } from "../../store";
import { SecondaryButton } from "../design_library";

export default function Themes() {
  const [themes, setThemes] = useState({});
  const [loading, setLoading] = useState(true);
  const [appState, setAppState] = useAtom(AppStateAtom);

  useEffect(() => {
    if (Object.keys(themes).length === 0) {
      getThemes().then((res) => {
        setThemes(res.themes);
        setLoading(false);
      });
    }
  }, []);

  const selectTheme = (theme: string) => {
    setTheme(theme).then((res) => {
      if (res.success) {
        setAppState((prev) => {
          return {
            ...prev,
            gridTheme: theme,
          };
        });
        setTimeout(() => {
          window.location.reload();
        }, 50);
      }
    });
  };

  return (
    <Box>
      <VStack spacing="5" mt="5">
        <Box
          width="xl"
          maxW="100vw"
          rounded={{ md: "lg" }}
          bg={"var(--dark-gray)"}
          shadow="base"
          overflow="hidden"
          padding="5"
        >
          <Heading as="h3" size="lg">
            Themes
          </Heading>

          <Text fontSize="sm">Choose a theme for your account</Text>
          <Box pt="20px">
            {Object.keys(themes).map((theme) => {
              return (
                <HStack
                  key={theme}
                  display={"flex"}
                  justifyContent={"space-between"}
                  mb="6px"
                >
                  <Text
                    color={
                      appState.gridTheme.toLowerCase() ===
                      themes[theme].toLowerCase()
                        ? "var(--green)"
                        : "var(--white)"
                    }
                  >
                    {theme}
                  </Text>
                  <SecondaryButton
                    minWidth="100px"
                    isDisabled={
                      appState.gridTheme.toLowerCase() ===
                      themes[theme].toLowerCase()
                        ? true
                        : false
                    }
                    onClick={() => {
                      selectTheme(themes[theme]);
                    }}
                  >
                    {appState.gridTheme === theme ? "Selected" : "Set"}
                  </SecondaryButton>
                </HStack>
              );
            })}
          </Box>
        </Box>
      </VStack>
    </Box>
  );
}
