import moment from "moment";
import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum, createGroupColumn } from "../../../../components";

export const columnDefinitions: ColDef[] = [
  createGroupColumn("Symbol", "symbol", ColumnTypeEnum.Text),
  createGroupColumn("Strike", "strike", ColumnTypeEnum.Text),
  createGroupColumn("Put/Call", "putCall", ColumnTypeEnum.Text),
  createGroupColumn("Expiry", "expiry", ColumnTypeEnum.Date, {
    valueFormatter: (params) => {
      return params.value ? moment(params.value).format("DD-MMM-YYYY") : "";
    },
  }),
];
