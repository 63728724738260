import { useAtom, useAtomValue } from "jotai";
import { AccountAtom, InstrumentStateAtom } from "../store";
import { getInstruments } from "../api";
import { Instrument } from "../types";
import { useEffect } from "react";

export interface UseInstruments {
  instruments: Instrument[];
  refresh: () => void;
}

export const useInstruments = (): UseInstruments => {
  const account = useAtomValue(AccountAtom);
  const [instruments, setInstruments] = useAtom(InstrumentStateAtom);

  const loadInstruments = async (accountId: number) => {
    const instruments = await getInstruments(accountId);
    if (instruments) {
      setInstruments(instruments);
    }
  };

  const refresh = async () => {
    if (account.selectedAccount) {
      await loadInstruments(account.selectedAccount.id);
    }
  };

  useEffect(() => {
    if (account.selectedAccount) {
      loadInstruments(account.selectedAccount.id);
    }
  }, [account.selectedAccount]);

  return {
    instruments,
    refresh,
  };
};
