import { APIEndpoint } from ".";

export const getCurrencies = async () => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/metadata/currencies`, {
    method: "GET",
    headers: {
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error getting currencies:", error));
};

export const getServiceStatus = async () => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/metadata/service-status`, {
    method: "GET",
    headers: {
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error getting service status:", error));
};
