import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, HStack, Image, Text } from "@chakra-ui/react";
import { useTour } from "@reactour/tour";

const SearchItem = ({ item, setSelectedbroker }) => {
  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } =
    useTour();
  return (
    <Box
      id={item.id}
      key={item.id}
      width="100%"
      background="var(--dark-gray)"
      border="1px solid var(--gray)"
      borderRadius={"8px"}
      padding="12px"
      onClick={() => {
        setSelectedbroker(item.id);

        if (
          window.location.href.includes("tutorial=true") ||
          window.location.href.includes("result=paid")
        ) {
          setCurrentStep(currentStep + 1);
        }
      }}
      cursor={"pointer"}
    >
      <HStack justifyContent={"space-between"}>
        <HStack>
          <Image
            src={item.imageUrl}
            width="46px"
            height="46px"
            borderRadius={"6px"}
          />
          <Box>
            <Text fontSize="20px" fontWeight={500}>
              {item.name}
            </Text>
            <Text fontSize="17px" color="var(--semi-white)" fontWeight={400}>
              {item.subtitle}
            </Text>
          </Box>
        </HStack>
        <ChevronRightIcon w="40px" h="40px" />
      </HStack>
    </Box>
  );
};

export default SearchItem;
