import { atom } from "jotai";
import { Trade } from "../types/Trade";
import { CashFlow } from "../types/Cashflow";

export interface TransactionsState {
  trades: Trade[];
  cashflows: CashFlow[];
  books: string[];
  groupNames: string[];
  tags: string[];
  strategies: string[];
}

const buildInitialState = (): TransactionsState => {
  const state: any = {
    trades: [],
    cashflows: [],
    books: [],
    groupNames: [],
    tags: [],
    strategies: [],
  };

  return state;
};

export const TransactionsAtom = atom<TransactionsState>(buildInitialState());
