import {
  Box,
  Center,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Spinner } from "../misc";
import { useEffect, useState } from "react";
import { usePositions } from "../../hooks";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../store";

export const AuditPageContent = (props: any) => {
  const positions = usePositions();
  const accountState = useAtomValue(AccountAtom);

  const [auditAvaiable, setAuditAvailable] = useState(false);

  const [auditState, setAuditState] = useState({
    isLoading: false,
    log: [],
    price: [],
    trades: [],
    position: {},
  });

  useEffect(() => {
    let tradeIds = props.trades;
    if (props.isDisabled) {
      setAuditAvailable(false);
    } else {
      generateAudit(tradeIds);
    }
  }, [props.trades]);

  const generateAudit = async (tradeIds: []) => {
    setAuditState({ ...auditState, isLoading: true });
    const result = await positions.fetchSinglePosition(
      tradeIds,
      accountState?.selectedAccount?.id
    );
    const newState = {
      isLoading: false,
      log: result.audit.log,
      price: JSON.parse(result.audit.price),
      trades: result.audit.trades.map((trade: any) => {
        return JSON.parse(trade);
      }),
      position: result.position,
    };

    console.log(newState);
    setAuditAvailable(true);
    setAuditState(newState);
  };

  if (auditAvaiable === false) {
    return (
      <Center>
        <Text fontSize={"sm"} fontWeight={400}>
          Audit not available for groups. Please select a single position.
        </Text>
      </Center>
    );
  }

  return (
    <>
      {auditState.isLoading ? (
        <Center>
          <Spinner />
        </Center>
      ) : (
        <Box maxH="100%" h="100%" overflow={"scroll"} pb="200px">
          <Table p={0} m={0}>
            <Thead p={0} m={0}>
              <Th>
                <Text fontSize={"sm"} fontWeight={700}>
                  Field
                </Text>
              </Th>
              <Th>
                <Text fontSize={"sm"} fontWeight={700}>
                  Log
                </Text>
              </Th>
              <Th>
                <Text fontSize={"sm"} fontWeight={700}>
                  Equation
                </Text>
              </Th>
            </Thead>

            <Tbody>
              {auditState.log.map((log, index) => (
                <Tr key={index}>
                  <Td>
                    <Text fontSize={"sm"} fontWeight={400}>
                      {log.field}
                    </Text>
                  </Td>
                  <Td>
                    <Text fontSize={"sm"} fontWeight={400}>
                      {log.message}
                    </Text>
                  </Td>
                  <Td>
                    {log.equation && (
                      <Text fontSize={"sm"} fontWeight={400}>
                        {log.equation}
                      </Text>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </>
  );
};
