import { APIEndpoint } from ".";

export const checkToken = async () => {
  return fetch(APIEndpoint + "/users/profile", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      return { success: false, title: "Server error", message: e.message };
    });
};
