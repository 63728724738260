import React, { useEffect, useRef, useState } from "react";
import { GetFullListOfUsers, GetImpersonateToken } from "../../../api";
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  HStack,
  Heading,
  Divider,
  Stack,
} from "@chakra-ui/react";
import moment from "moment";
import StatContainer from "../../../components/design_library/StatContainer";
import { useDebounce } from "../../hooks";
import { Step } from "../../../components/design_library/Progress/Steps";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState("");
  const initialised = useRef<boolean>(false);
  const [subscribed, setSubscribed] = useState([]);
  const [notSubscribed, setNotSubscribed] = useState([]);
  const [admins, setAdmins] = useState([]);

  const getUsers = async () => {
    const fullListOfUsers = await GetFullListOfUsers();
    setUsers(fullListOfUsers);
    setFilteredUsers(fullListOfUsers);

    //filter out admins into own list
    const admins = fullListOfUsers.filter((user) => user.role === "admin");

    //filter out subscribed users into own list
    const subscribed = fullListOfUsers.filter(
      (user) => user.subscriptionStatus
    );

    //filter out not subscribed users into own list
    const notSubscribed = fullListOfUsers.filter(
      (user) => !user.subscriptionStatus
    );

    setAdmins(admins);
    setSubscribed(subscribed);
    setNotSubscribed(notSubscribed);
  };

  const updateUsers = useDebounce(() => {
    getUsers();
  }, 400);

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Box bg="var(--dark-gray)" p={4} borderRadius={8}>
      <HStack mb="20px">
        <StatContainer title="Users" stat={users.length} type="NUMERIC" />
        <StatContainer
          title="Subscribed"
          stat={users.filter((user) => user.subscriptionStatus).length}
          type="NUMERIC"
        />

        <StatContainer
          title="Not Subscribed"
          stat={users.filter((user) => !user.subscriptionStatus).length}
          type="NUMERIC"
        />

        <StatContainer
          title="Admins"
          stat={users.filter((user) => user.role === "admin").length}
          type="NUMERIC"
        />

        <StatContainer
          title="Sign ups in last week"
          stat={
            users.filter((user) =>
              moment(user.createdAt).isAfter(moment().subtract(7, "days"))
            ).length
          }
          type="NUMERIC"
          change={
            // calculate change vs previous week
            (users.filter((user) =>
              moment(user.createdAt).isAfter(
                moment().subtract(7, "days").subtract(7, "days")
              )
            ).length /
              users.filter((user) =>
                moment(user.createdAt).isAfter(moment().subtract(7, "days"))
              ).length) *
            100
          }
          changeSuffix="%"
        />

        <StatContainer
          title="Sign ups in last month"
          stat={
            users.filter((user) =>
              moment(user.createdAt).isAfter(moment().subtract(1, "months"))
            ).length
          }
          type="NUMERIC"
          change={
            // calculate change vs previous month
            (users.filter((user) =>
              moment(user.createdAt).isAfter(
                moment().subtract(1, "months").subtract(1, "months")
              )
            ).length /
              users.filter((user) =>
                moment(user.createdAt).isAfter(moment().subtract(1, "months"))
              ).length) *
            100
          }
          changeSuffix="%"
        />

        <StatContainer
          title="Sign ups in last year"
          stat={
            users.filter((user) =>
              moment(user.createdAt).isAfter(moment().subtract(1, "years"))
            ).length
          }
          change={
            // calculate change vs previous year
            (users.filter((user) =>
              moment(user.createdAt).isAfter(
                moment().subtract(1, "years").subtract(1, "years")
              )
            ).length /
              users.filter((user) =>
                moment(user.createdAt).isAfter(moment().subtract(1, "years"))
              ).length) *
            100
          }
          changeSuffix="%"
          type="NUMERIC"
        />
      </HStack>
      <Input
        placeholder="Filter by email"
        value={filter}
        onChange={(e) => {
          setFilter(e.target.value);

          setFilteredUsers(
            users.filter((user) =>
              user.email.toLowerCase().includes(e.target.value.toLowerCase())
            )
          );
        }}
        mb={4}
      />

      <TableStructure users={filteredUsers} />
    </Box>
  );
}

const TableStructure = ({ users }) => {
  return (
    <Table
      variant="striped"
      colorScheme="gray"
      size="sm"
      backgroundColor={"var(--bg)"}
    >
      <Thead backgroundColor={"var(--bg)"}>
        <Tr>
          <Th>Role</Th>
          <Th>Email</Th>
          <Th>Last active</Th>
          <Th>Steps taken</Th>

          <Th>Actions</Th>
        </Tr>
      </Thead>
      <Tbody>
        {users
          .sort((a, b) => {
            if (a.createdAt !== null && b.createdAt !== null) {
              return moment(b.createdAt).diff(moment(a.createdAt), "second");
            }
            return 0;
          })
          .map((user: any) => (
            <Row key={user.id} {...user} />
          ))}
      </Tbody>
    </Table>
  );
};

const Row = (user: any) => {
  const [clicked, setClicked] = useState(false);

  return (
    <Tr>
      <Td fontWeight={"700"}>
        {user.role === "admin" ? (
          <span style={{ color: "red" }}>Admin</span>
        ) : (
          <span style={{ color: "green" }}>User</span>
        )}
      </Td>
      <Td>{user.email}</Td>
      <Td>{user.lastActive && moment(user.lastActive).calendar()}</Td>

      <Td>
        <Stack spacing="0" direction={{ base: "column", md: "row" }}>
          <Step
            cursor="pointer"
            title={`Registered: ${moment(user.createdAt).calendar()}`}
            description=""
            isActive={true}
            isCompleted={true}
            isFirstStep={true}
            isLastStep={false}
          />

          <Step
            cursor="pointer"
            title={"Stripe"}
            isActive={user.stripeId === undefined}
            isCompleted={user.stripeId !== undefined}
            isFirstStep={false}
            isLastStep={false}
          />

          <Step
            cursor="pointer"
            title={"Has Sub'd"}
            isActive={user.hasUsedPremiumSubscription === true}
            isCompleted={user.hasUsedPremiumSubscription === true}
            isFirstStep={false}
            isLastStep={false}
          />

          <Step
            cursor="pointer"
            title={"Sub Active"}
            isActive={user.subscriptionStatus === true}
            isCompleted={user.subscriptionStatus === true}
            isFirstStep={false}
            isLastStep={false}
          />

          <Step
            cursor="pointer"
            title={"Sub Canc."}
            isActive={
              user.subscriptionStatus === false &&
              user.hasUsedPremiumSubscription === true
            }
            isCompleted={
              user.subscriptionStatus === false &&
              user.hasUsedPremiumSubscription === true
            }
            isFirstStep={false}
            isLastStep={true}
          />
        </Stack>
      </Td>

      <Td>
        <Button
          size="sm"
          onClick={async () => {
            setClicked(true);
            const token = await GetImpersonateToken(user.id);
            navigator.clipboard.writeText(
              `${window.location.origin}/23rjfisf4d3-234f4-fdsfnfj4fndc/token?token=${token.token}`
            );

            setTimeout(() => {
              setClicked(false);
            }, 1000);
          }}
        >
          {clicked ? "Copied link!" : "Impersonate"}
        </Button>
      </Td>
    </Tr>
  );
};
