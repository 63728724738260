import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";
import { Instrument, InstrumentPrice, PricingError } from "../../../../types";
import { AccountAtom } from "../../../../store";
import {
  getInstrumentPrices,
  getInstrumentHistoricalPrices,
  getPricingErrors,
} from "../../../../api";

interface UseInstrumentPrices {
  prices: InstrumentPrice[];
  errors: PricingError[];
}

export const useInstrumentPrices = (
  instrument: Instrument | undefined
): UseInstrumentPrices => {
  const account = useAtomValue(AccountAtom);
  const [prices, setPrices] = useState<InstrumentPrice[]>([]);
  const [errors, setErrors] = useState<PricingError[]>([]);

  const getPrices = async () => {
    const current = await getInstrumentPrices(
      account.selectedAccount.id,
      instrument!.id as string
    );
    const historical = await getInstrumentHistoricalPrices(
      account.selectedAccount.id,
      instrument!.id as string
    );

    setPrices([...current, ...historical]);
  };

  const getErrors = async () => {
    const result = await getPricingErrors(instrument!.id as string);
    setErrors(result ?? []);
  };

  useEffect(() => {
    if (instrument && instrument.id && account) {
      setPrices([]);
      getPrices();
      getErrors();
    }
  }, [instrument, account]);

  return { prices, errors: errors ?? [] };
};
