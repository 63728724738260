import { ChevronDown, ChevronUp } from "./Icons";
import styles from "./index.module.css";
import Select, { components } from "react-select";

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        {props.selectProps.menuIsOpen ? <ChevronUp /> : <ChevronDown />}
      </components.DropdownIndicator>
    )
  );
};

const customStylesDarker = {
  menu: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    color: "var(--light-gray)",
    border: "none",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    border: "none",
    color: "var(--light-gray)",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    border: "none",
    color: "var(--light-gray)",
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    width: "auto",
    minWidth: "200px",
    height: "40px",
    border: "none",
    color: "var(--light-gray)",
    marginRight: "5px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    backgroundColor: "var(--bg)",
    border: "none",
    color: "var(--white)",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

export const DropDown = (props: any) => {
  //   placeholder="All"
  //   options={[
  //     { value: "ALL", label: "All" },
  //     { value: "TEST", label: "Test" },
  //   ]}

  return (
    <Select
      isLoading={props.loading}
      id={props.id}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      className={styles.dropdown}
      styles={customStylesDarker}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
        ...props.components,
      }}
      {...props}
    />
  );
};
