import { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import moment from "moment-timezone";
import { ChakraProvider, Box, Center, Heading } from "@chakra-ui/react";
import theme from "./themeConfig";
import { MainApp } from "./features/main-app/MainApp";
import { Dashboard } from "./features/dashboard/Dashboard";
import { Login } from "./features/authentication/login/Login";
import FourOhFour from "./404";
import { Register } from "./features/authentication/register/Register";
import { ResetPassword } from "./features/authentication/reset/ResetPassword";
import { useAppController } from "./useAppController";
import Settings from "./features/settings/Settings";
import Homepage from "./features/homepage/Homepage";
import { Blog } from "./features/blog/Blog";
import { BlogSingle } from "./features/blog/BlogSingle";

import Positions from "./features/positions/Positions";
import Transactions from "./features/transactions/Transactions";

import { useAtomValue } from "jotai";
import { AppStateAtom } from "./store";
import ThemeSwitcher from "./components/themes/ThemeSwitcher";
import { Admin } from "./features/admin/Admin";
import React from "react";
import FormExample from "./components/forms/FormExample";
import TokenAssignment from "./features/admin/users/TokenAssignment";
import { Roadmap } from "./features/roadmap/Roadmap";
import Privacy from "./features/policy/Privacy";
import Terms from "./features/policy/Terms";
import Cashflows from "./features/cashflows/Cashflows";
import { KnowledgeBase } from "./features/knowledgebase/Knowledgebase";
import { KnowledgeBaseArticle } from "./features/knowledgebase/KnowledgebaseArticle";
import OnTheInternet from "./features/backlinks/OnTheInternet";
import AboutUs from "./features/aboutus/AboutUs";
import Contact from "./features/contact/Contact";

const Loading = () => (
  <Box>
    <Center height="100vh" flexDir="column">
      <Heading mt="2" size="md">
        Loading...
      </Heading>
    </Center>
  </Box>
);

const App = () => {
  const controller = useAppController();
  moment.tz.setDefault("Europe/London");

  useEffect(() => {
    localStorage.setItem("chakra-ui-color-mode", "dark");
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <ThemeSwitcher />
      <ChakraProvider theme={theme}>
        {controller.initialised && (
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route
                index
                path="/23rjfisf4d3-234f4-fdsfnfj4fndc/token"
                element={<TokenAssignment />}
              />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/on-the-internet" element={<OnTheInternet />} />
              <Route path="/terms" element={<Terms />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/blog/page/:page" element={<Blog />} />
              <Route path="/blog/post/:id/:slug" element={<BlogSingle />} />

              <Route path="/knowledgebase" element={<KnowledgeBase />} />
              <Route
                path="/knowledgebase/:id"
                element={<KnowledgeBaseArticle />}
              />
              <Route path="/roadmap" element={<Roadmap />} />
              <Route path="/app/login" element={<Login />} />
              <Route path="/app/register" element={<Register />} />
              <Route path="/app/reset" element={<ResetPassword />} />
              <Route path="/app" element={<MainApp />}>
                <Route index path="/app/admin" element={<Admin />} />

                <Route
                  index
                  path="/app/admin/forms"
                  element={<FormExample />}
                />

                <Route index path="/app/dashboard" element={<Dashboard />} />
                <Route index path="/app/positions" element={<Positions />} />

                <Route
                  index
                  path="/app/transactions"
                  element={<Transactions />}
                />
                <Route index path="/app/cashflows" element={<Cashflows />} />
                <Route index path="/app/settings" element={<Settings />} />
              </Route>
              <Route
                path="*"
                element={<Navigate to="/app/dashboard" replace />}
              />
              <Route path="/404" element={<FourOhFour />} />
            </Routes>
          </BrowserRouter>
        )}
      </ChakraProvider>
    </Suspense>
  );
};

export default App;
