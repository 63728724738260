import React from "react";
import { HStack, Text } from "@chakra-ui/react";
import { StockLogo } from "../../design_library";

export const SymbolColumn = (props: any) => {
  return (
    <HStack>
      <StockLogo stock={props.value ?? ""} />
      <Text fontWeight={"600"}>{props.value}</Text>
    </HStack>
  );
};
