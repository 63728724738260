import { Heading, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { TbDatabaseExclamation } from "react-icons/tb";

export default function NoDataAvailable({ title }: { title: string }) {
  return (
    <VStack
      h="80%"
      justifyContent={"center"}
      alignItems={"center"}
      textAlign={"center"}
      px="20px"
    >
      <TbDatabaseExclamation fontSize="40px" />
      <Heading fontSize="md">No data available</Heading>
      <Text fontSize="sm">
        There is currently no data available for {title}
      </Text>
    </VStack>
  );
}
