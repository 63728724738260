import React from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";

// !BP
import { ChevronDown, ChevronUp } from "../../design_library";

export const PercentageColumn = (props: any) => {
  if (!props.value) return null;

  if (props.noFormatting) {
    return (
      <VStack alignItems="flex-start">
        <Text> {props.value.toFixed(2)}%</Text>
      </VStack>
    );
  }
  if (props.value < 0) {
    return (
      <HStack>
        {props.showDirection && (
          <ChevronDown
            backgroundColor={"transparent"}
            fill="var(--red)"
            strokeWidth={"1.5px"}
            stroke="var(--red)"
          />
        )}
        <VStack alignItems="flex-start">
          <Text color="var(--red)"> {(props.value * -1).toFixed(2)}%</Text>
        </VStack>
      </HStack>
    );
  } else {
    return (
      <HStack>
        {props.showDirection && (
          <ChevronUp
            backgroundColor={"transparent"}
            fill="var(--green)"
            strokeWidth={"1.5px"}
            stroke="var(--green)"
          />
        )}
        <VStack alignItems="flex-start">
          <Text color="var(--green)"> {props.value.toFixed(2)}%</Text>
        </VStack>
      </HStack>
    );
  }
};
