import BookRender from "../../../components/grid/renderers/BookRender";
import { UnderlyingRender } from "../../../components/grid/renderers/UnderlyingRender";
import { PositionGroupRender, TradeDetailsRender } from "../../../components";

export const PositionsInnerRender = (params: any) => {
  if (params.node.field === "book") {
    return BookRender(params);
  } else if (params.node.field === "underlyingName") {
    return UnderlyingRender(params);
  } else if (params.node.field === "positionName") {
    return PositionGroupRender(params);
  } else {
    return TradeDetailsRender(params);
  }
};
