import {
  Box,
  Center,
  HStack,
  Heading,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import NavBar from "../homepage/menu/App";
import Footer from "../homepage/footer/App";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import {
  getKnowledgeBaseArticles,
  getSpecificKnowledgeBaseArticle,
} from "../../api";
import { useParams } from "react-router-dom";
import { Spinner } from "../../components";
import "../../components/design_library/WYSIWIGEditorStyling.css";
export const KnowledgeBaseArticle = () => {
  const [article, setArticle] = useState({});
  const [articles, setArticles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loadingArticles, setLoadingArticles] = useState(true);
  const [loadingArticle, setLoadingArticle] = useState(true);
  const { id } = useParams();
  const [markdownContent, setMarkdownContent] = useState("");

  const getSpecificArticle = async () => {
    setLoadingArticle(true);
    const article = await getSpecificKnowledgeBaseArticle(id);
    setArticle(article);
    setMarkdownContent(article.content);
    setLoadingArticle(false);
  };

  const getAllArticles = async () => {
    setLoadingArticles(true);
    const data = await getKnowledgeBaseArticles(true);
    setArticles(data.articles);
    setCategories(data.metadata.categories);
    setLoadingArticles(false);
  };

  useEffect(() => {
    getAllArticles();
    getSpecificArticle();
  }, []);

  return (
    <Box bg="var(--bg)" minH={"100vh"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {article && article.title
            ? `WealthBee Knowledge Base - ${article.title}`
            : "WealthBee Knowledge Base"}
        </title>
        <meta
          name="description"
          content={
            article && article.content
              ? article.content.substring(0, 100)
              : "WealthBee Knowledge Base"
          }
        />
        <link
          rel="canonical"
          href={
            article && article.slug
              ? `https://wealthbee.io/knowledgebase/${article.slug}`
              : "https://wealthbee.io/knowledgebase"
          }
        />
      </Helmet>
      <NavBar />

      <Center w="full">
        <HStack w="8xl" minH={"80vh"} alignItems={"flex-start"} px="10">
          <VStack>
            {categories && (
              <Center>
                <Box
                  display={{
                    base: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  }}
                >
                  {categories.map((category: any, index: number) => (
                    <Box key={index}>
                      <Heading size="sm" mt="20px">
                        {category}
                      </Heading>
                      {articles &&
                        articles
                          .filter(
                            (article: any) => article.category === category
                          )
                          .map((article: any) => (
                            <Link
                              color="var(--chart-blue)"
                              key={article.articleId}
                              href={`${article.slug}`}
                            >
                              <Text pt="4px">{article.title}</Text>
                            </Link>
                          ))}
                    </Box>
                  ))}
                </Box>
              </Center>
            )}
          </VStack>
          <VStack flex="6">
            {article && (
              <Center>
                <Box>
                  <HStack justifyContent={"flex-start"} textAlign={"left"}>
                    <VStack alignItems={"flex-start"}>
                      <Heading as="h1">{article.title}</Heading>
                    </VStack>
                  </HStack>
                  <HStack
                    w="full"
                    minH="650px"
                    wrap="wrap"
                    alignItems={"flex-start"}
                  >
                    {loadingArticle && (
                      <Center w="full">
                        <Spinner />
                      </Center>
                    )}
                    <div>
                      <div
                        className="prose"
                        dangerouslySetInnerHTML={{ __html: markdownContent }}
                      ></div>

                      <Text
                        mt="600px"
                        fontSize={"12px"}
                        opacity={0.8}
                        mb="100px"
                      >
                        WealthBee is an trading journal and journal that helps
                        you track your investments, monitor your portfolio, and
                        analyze your performance. It is a platform that helps
                        you make better investment decisions by providing you
                        with the tools and insights you need to succeed in the
                        stock market. WealthBee is designed to be easy to use,
                        intuitive, and powerful. It is the perfect tool for both
                        beginners and experienced investors who want to take
                        control of their investments and achieve their financial
                        goals. It supports equities, options and futures.
                      </Text>
                    </div>
                  </HStack>
                </Box>
              </Center>
            )}
          </VStack>
        </HStack>
      </Center>

      <Footer />
    </Box>
  );
};
