import { useEffect, useState } from "react";
import {
  deleteInsight,
  getInsights,
  getInsightsDatasets,
  updateInsight,
} from "../../../api";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../../store";
import { useNotifications } from "../../../hooks/useNotifications";
import { InsightSearchPages } from "../../../types";
import { useFilters } from "../../../hooks/useFilters";
import { InsightTemplate } from "../../../types/Insight";
import { DashboardLayoutStateStore } from "../../../store/DashboardLayout";

interface Insight {
  insightId?: number;
  [key: string]: any;
}

interface UseInsightsController {
  handleToggle: () => void;
  handleDelete: (insightId: number) => void;
  fetchInsights: (page: InsightSearchPages, search: string) => void;
  fetchInsightsDatasets: () => void;
  isOpen: boolean;
  insights: Insight[];
  insightsDataset: Insight[];
  insightData: Insight | null;
  insightId: number | null;
  setInsightId: (id: number | null) => void;
  loading: boolean;
  displaysPage: InsightSearchPages;
  setDisplayedPage: (page: InsightSearchPages) => void;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
  selectedInsight: Insight | null;
  setSelectedInsight: (insight: Insight | null) => void;
  page: string;
  setPage: (page: "insights" | "creator") => void;
  setInsightData: (insight: InsightTemplate) => void;
}

export const useInsightsController = (): UseInsightsController => {
  const [page, setPage] = useState("insights");
  const [insightData, setInsightData] = useState<Insight | null>(null);
  const [insights, setInsights] = useState<Insight[]>([]);
  const [insightsDataset, setInsightsDataset] = useState<Insight[]>([]);
  const [insightId, setInsightId] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedInsight, setSelectedInsight] = useState<Insight | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [displaysPage, setDisplayedPage] = useState(InsightSearchPages.ALL);

  const notifications = useNotifications();
  const accountState = useAtomValue(AccountAtom);
  const filters = useFilters();
  const [enabledInsights, setEnabledInsights] = useState<string[]>([]);

  const dashboardLayoutStore = useAtomValue(DashboardLayoutStateStore);

  const handleToggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    fetchInsightsDatasets();
  }, [
    filters.selectedUnderlying,
    filters.selectedTag,
    filters.selectedStrategy,
    filters.selectedPositionGroup,

    dashboardLayoutStore.gridLoading,
  ]);

  const handleDelete = async (insightId: number) => {
    if (window.confirm("Are you sure you want to delete this insight?")) {
      if (insightId !== null) {
        setLoading(true);
        try {
          await deleteInsight(insightId);
          notifications.createNotification(
            "Insight deleted",
            "Your insight has been deleted successfully"
          );
          fetchInsights(displaysPage, searchQuery);
        } catch (error) {
          notifications.createNotification(
            "Error deleting insight",
            "There was an error deleting your insight"
          );
          console.error("Error deleting insight:", error);
        } finally {
          setLoading(false);
        }
      } else {
        notifications.createNotification(
          "Insight ID is required",
          "Please provide an insight ID to delete an insight"
        );
      }
    }
  };

  const fetchInsights = async (page?: InsightSearchPages, search?: string) => {
    setLoading(true);
    try {
      const response = await getInsights(page, search);
      setInsights(response.data);
    } catch (error) {
      notifications.createNotification(
        "Error fetching insights",
        "There was an error fetching insights"
      );
      console.error("Error fetching insights:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchInsightsDatasets = async () => {
    setLoading(true);
    try {
      const response = await getInsightsDatasets(
        accountState?.selectedAccount?.id,
        filters.selectedUnderlying,
        filters.selectedTag,
        filters.selectedStrategy,
        filters.selectedBook,
        filters.selectedPositionGroup
      );

      setInsightsDataset(response);
      // notifications.createNotification(
      //   "Insights datasets fetched",
      //   "Insights datasets have been fetched successfully"
      // );

      setTimeout(() => {
        notifications.clearNotifications();
      }, 3000);
    } catch (error) {
      notifications.createNotification(
        "Error fetching insights datasets",
        "There was an error fetching insights datasets"
      );
      console.error("Error fetching insights datasets:", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleToggle,
    handleDelete,
    fetchInsights,
    fetchInsightsDatasets,
    isOpen,
    insights,
    insightsDataset,
    insightData,
    setInsightData,
    insightId,
    setInsightId,
    loading,
    displaysPage,
    setDisplayedPage,
    searchQuery,
    setSearchQuery,
    selectedInsight,
    setSelectedInsight,
    page,
    setPage,
  };
};
