import React, { Component, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import styles from "./SimpleHeader.module.css";

export const Cookies = () => {
  const [visible, setVisible] = React.useState(false);
  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 5000);
    if (document.cookie.includes("CookieConsent=false")) {
      setTimeout(() => {
        console.log("Clearing cookies!");
        var cookies = document.cookie.split("; ");
        for (var c = 0; c < cookies.length; c++) {
          if (cookies[c] !== "CookieConsent=false") {
            var d = window.location.hostname.split(".");
            while (d.length > 0) {
              var cookieBase =
                encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
                "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
                d.join(".") +
                " ;path=";
              var p = window.location.pathname.split("/");
              document.cookie = cookieBase + "/";
              while (p.length > 0) {
                document.cookie = cookieBase + p.join("/");
                p.pop();
              }
              d.shift();
            }
          }
        }
      }, 1500);
    }
  }, []);

  if (!visible) {
    return null;
  }
  return (
    <CookieConsent
      location="bottom"
      declineButtonText="Decline"
      buttonText="Accept"
      buttonStyle={{
        backgroundColor: "var(--accent)",
        color: "var(--white)",
        fontSize: "14px",
      }}
      declineButtonStyle={{
        backgroundColor: "var(--gray)",
        color: "var(--white)",
        fontSize: "14px",
      }}
      style={{
        background: "var(--dark-gray)",
        padding: 0,
        margin: 0,
        flexDirection: "row-reverse",
        paddingRight: "40px",
        fontSize: "14px",
      }}
      expires={150}
      enableDeclineButton
      onDecline={() => {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i];
          var eqPos = cookie.indexOf("=");
          var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }
      }}
    >
      This website uses cookies to enhance the user experience. By continuing to
      use this site, you consent to the use of cookies. You can disable cookies
      by clicking decline.
    </CookieConsent>
  );
};
