import { APIEndpoint } from ".";
import { DashboardConfiguration } from "../types/DashboardConfiguration";

export const createDashboardConfiguration = async (accountId: string) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/dashboard/layouts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
      AccountId: accountId,
    },
    body: JSON.stringify({}),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating layout:", error));
};

export const getLayoutForAccount = async (accountId: string) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/dashboard/layouts`, {
    method: "GET",
    headers: {
      Authorization: `${token || ""}`,
      AccountId: accountId,
    },
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error getting layout for account:", error)
    );
};

export const updateLayoutForAccount = async (
  accountId: string,
  layout: any
) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/dashboard/layouts`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
      AccountId: accountId,
    },
    body: JSON.stringify(layout),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating layouts:", error));
};
