import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { SecondaryButton } from "../../../../../components/design_library";
import { Spinner } from "../../../../../components";
import Confetti from "react-dom-confetti";

export default function UploadingExplosion() {
  const [showConfetti, setShowConfetti] = React.useState(false);

  //if component is visible on screen, show confetti
  useEffect(() => {
    setTimeout(() => {
      if (showConfetti === false) {
        setShowConfetti(true);
      }
    }, 600);
  }, []);

  return (
    <Box w="100%" justifyContent={"center"}>
      <SecondaryButton
        w="100%"
        backgroundColor="var(--bg)"
        border="solid var(--accent) 1px"
        onClick={() => {
          setShowConfetti(true);

          setTimeout(() => {
            setShowConfetti(false);
          }, 3000);
        }}
        color="var(--accent)"
      >
        {showConfetti === false && (
          <>
            <Spinner mr="2" size="md" />
            Uploading...
          </>
        )}
        {showConfetti === true && <>Upload complete.</>}
      </SecondaryButton>

      <Box transform={"translateX(180px) translateY(-10px)"}>
        <Confetti
          active={showConfetti}
          config={{
            spread: 100,
            width: "5px",
            height: "5px",
            dragFriction: 0.05,
            stagger: 10.5,
            startVelocity: 15,
            elementCount: 200,
            colors: ["var(--accent)", "var(--white)"],
          }}
        />
      </Box>
    </Box>
  );
}
