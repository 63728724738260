import { Spinner as ChakraSpinner, Center } from "@chakra-ui/react";

export const Spinner = (props: any) => {
  return (
    <Center>
      <ChakraSpinner
        thickness="2px"
        speed="0.65s"
        // emptyColor="gray.200"
        color="var(--accent)"
        size="xl"
        {...props}
      />
    </Center>
  );
};
