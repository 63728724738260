export default {
  marketing: {
    button: {
      primary: {
        background: { dark: "rgb(0, 212, 255)", light: "rgb(0, 212, 255)" },
        text: { dark: "black", light: "black" },
      },
    },
    background: {
      primary: { dark: "#0a2540", light: "white" },
      marketing: { dark: "#0a2540", light: "white" },
      panel: { dark: "#e2e8f0", light: "white" },
    },
    text: {
      primary: { dark: "white", light: "black" },
      accent: { dark: "rgb(0, 212, 255)", light: "#0093eb" },
      subtitle: { dark: "rgb(173, 189, 204)", light: "black" },
    },
    icons: {
      primary: { dark: "white", light: "black" },
    },
  },

  // platform: {
  //   button: {
  //     primary: {
  //       background: ("red", "white"),
  //       text: { dark: "#0a2540", light: "white" },
  //     },
  //     blue: {
  //       background: { dark: "#90cdf4", light: "#3182ce" },
  //       inactive: { dark: "#313640", light: "#e2e8f0" },
  //       text: { dark: "white", light: "black" },
  //       inactiveText: { dark: "black", light: "white" },
  //     },
  //   },
  //   background: {
  //     primary: ("#0a2540", "white"),
  //     marketing: { dark: "#0a2540", light: "white" },
  //     panel: { dark: "#2D3748", light: "#ffffff" },
  //     menu: { dark: "#171923", light: "#1b202d" },
  //     mainBody: { dark: "#1A202C", light: "rgb(247, 250, 252)" },
  //     table: { dark: "#2D3748", light: "#ffffff" },
  //     tableHeader: { dark: "#4A5568", light: "#CBD5E0" },
  //   },
  //   text: {
  //     primary: { dark: "white", light: "black" },
  //     subtitle: { dark: "white", light: "black" },
  //     green: { dark: "#37a169", light: "#37a169" },
  //     red: { dark: "#e53e3e", light: "#e53e3e" },
  //   },
  //   icons: {
  //     primary: { dark: "white", light: "black" },
  //   },
  //   input: {
  //     phoneNumber: {
  //       background: { dark: "#0a2540", light: "white" },
  //       text: { dark: "white", light: "black" },
  //     },
  //     dropDown: {
  //       background: { dark: "transparent", light: "transparent" },
  //       text: { dark: "white", light: "black" },
  //     },
  //     dropDownList: {
  //       background: { dark: "#1A202C", light: "#F7FAFC" },
  //       text: { dark: "white", light: "black" },
  //     },
  //   },
  //   select: {
  //     background: { dark: "#313640", light: "white" },
  //     text: { dark: "white", light: "black" },
  //   },
  //   charts: {
  //     dark: {
  //       labels: {
  //         text: {
  //           fontWeight: "600",
  //           fill: "white",
  //           fontSize: 14,
  //           fontFamily: "Roboto, sans-serif",
  //           color: "white",
  //           "text-shadow": "0 0 3px black, 0 0 5px black",
  //         },
  //       },
  //       textColor: "white",
  //       annotations: {
  //         text: {
  //           fontSize: 14,
  //           fill: "white",
  //           outlineWidth: 0,
  //           outlineColor: "#ffffff",
  //           outlineOpacity: 1,
  //         },
  //       },
  //       tooltip: {
  //         container: {
  //           background: "black",
  //           color: "white",
  //           fontSize: 16,
  //         },
  //       },
  //       dots: {
  //         text: {
  //           fill: "#ffffff",
  //         },
  //       },
  //       grid: {
  //         line: {
  //           stroke: "#4d4d4d",
  //         },
  //       },
  //       label: {
  //         text: "white",
  //       },
  //     },
  //     light: {
  //       textColor: "black",
  //       labels: {
  //         text: {
  //           fontWeight: "600",
  //           fontSize: 14,
  //           fontFamily: "Roboto, sans-serif",
  //           "text-shadow": "0 0 3px white, 0 0 5px white",
  //         },
  //       },
  //       annotations: {
  //         text: {
  //           fontSize: 14,
  //           fill: "white",
  //           outlineWidth: 0,
  //           outlineColor: "black",
  //           outlineOpacity: 1,
  //         },
  //       },
  //       tooltip: {
  //         container: {
  //           background: "white",
  //           color: "black",
  //           fontSize: 16,
  //         },
  //       },
  //       dots: {
  //         text: {
  //           fill: "black",
  //         },
  //       },
  //       grid: {
  //         line: {
  //           stroke: "#e3e3e3",
  //         },
  //       },
  //       label: {
  //         text: "black",
  //       },
  //     },
  //   },
  // },
};
