import styles from "./index.module.css";
// Structure

import { Tab, TabList, TabPanels, Tabs } from "@chakra-ui/react";

export const CustomTabsContainer = (props) => {
  return (
    <Tabs className={styles.tabs} {...props} mt="2" isLazy colorScheme={"blue"}>
      {props.children}
    </Tabs>
  );
};

export const CustomTab = (props) => {
  return (
    <Tab className={styles.tab} id={props.id} {...props}>
      {props.children}
    </Tab>
  );
};

export const CustomTabPanels = (props) => {
  return (
    <TabPanels
      // width="100%"
      height="100%"
      className={styles.tabPanels}
    >
      {props.children}
    </TabPanels>
  );
};

export const CustomTabList = (props) => {
  return (
    <TabList
      sx={{
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
      }}
      {...props}
      className={styles.tabList}
    >
      {props.children}
    </TabList>
  );
};
