import { Box, Image, Stack, Text, useColorMode } from "@chakra-ui/react";
import * as React from "react";
import "./FeatureDark.css";

interface FeatureProps {
  title: string;
  children: React.ReactNode;
  classNameCustom: string;
}
export const FeatureDark = (props: FeatureProps) => {
  const { title, children, classNameCustom } = props;

  let image = "";
  let blurClass = "";
  if (classNameCustom === "blue") {
    blurClass = "blurBlue";
    image = "https://cdn.wealthbee.io/main/icons/Calendar.png";
  } else if (classNameCustom === "yellow") {
    image = "https://cdn.wealthbee.io/main/icons/Activity.png";
    blurClass = "blurYellow";
  } else if (classNameCustom === "green") {
    image = "https://cdn.wealthbee.io/main/icons/Chart.png";
    blurClass = "blurGreen";
  } else if (classNameCustom === "pink") {
    image = "https://cdn.wealthbee.io/main/icons/Cloud.png";
    blurClass = "blurBlue";
  } else if (classNameCustom === "filter") {
    image = "https://cdn.wealthbee.io/main/icons/Filter.png";
    blurClass = "blurGreen";
  } else if (classNameCustom === "plates") {
    image = "https://cdn.wealthbee.io/main/icons/Plates.png";
    blurClass = "blurYellow";
  }

  return (
    <Box
      mb="40px"
      mt="40px"
      display="flex"
      justifyContent={"flex-start"}
      alignItems="center"
      flexDir={"column"}
    >
      <div className={blurClass} />

      <Box className={"featureDarkImageBox " + classNameCustom}>
        <Image src={image} width="58px" height="auto" alt="icon" />
      </Box>
      <Stack mt="6">
        <Text
          as="h3"
          fontSize="18px"
          fontWeight="700"
          textAlign={"center"}
          textTransform="uppercase"
          color={"var(--white)"}
        >
          {title}
        </Text>
        <Text
          lineHeight="1.4"
          opacity={0.8}
          color={"var(--white)"}
          textAlign={"center"}
          maxWidth="292px"
          fontSize="14px"
        >
          {children}
        </Text>
      </Stack>
    </Box>
  );
};

// pr="6"
