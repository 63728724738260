import React, { useEffect } from "react";

export default function TokenAssignment() {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    localStorage.setItem("token", "bearer " + urlParams.get("token"));
    window.location.href = "../app/position";
  }, []);

  return <div></div>;
}
