import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./app.css";

import mixpanel from "mixpanel-browser";
import TourWrapper from "./components/tutorial/TourWrapper";
import * as Sentry from "@sentry/react";

if (process.env.NODE_ENV === "production") {
  mixpanel.init("e4f5abcdecbf83b881d5638e02353780", { track_pageview: true });
  Sentry.init({
    dsn: "https://ebbfb45db6a3c6cfbc41a234ff1ab34b@o4507301978308608.ingest.de.sentry.io/4507301980209232",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    tracePropagationTargets: ["localhost", /^https:\/\/wealthbee\.io\/api/],
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} else {
  mixpanel.init("XXXX");
}

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <TourWrapper>
      <App />
    </TourWrapper>
  </React.StrictMode>
);
