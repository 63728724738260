import { Box, Center, Stack } from "@chakra-ui/react";
import NavBar from "../homepage/menu/App";
import Footer from "../homepage/footer/App";
import Markdown from "react-markdown";
import styles from "./markdown.module.css";
import { BlogHeader } from "./BlogHeader";
import { CTA } from "../homepage/CTA/App";
import { useEffect, useState } from "react";
import { getSpecificBlogsArticle } from "../../api";
import React from "react";
import { BlogArticle } from "../../types/BlogArticle";
export const BlogSingle = () => {
  const articleNumber = window?.location?.pathname
    ?.replace("/blog/post/", "")
    .split("/")[0];

  const [post, setPost] = useState<BlogArticle | null>(null);
  const [loading, setLoading] = useState(true);

  const getBlogArticle = async () => {
    const data = await getSpecificBlogsArticle(articleNumber);
    setPost(data);
    setLoading(false);
  };

  useEffect(() => {
    getBlogArticle();
  }, []);

  if (loading) {
    return <Box minH={"800px"}></Box>;
  }

  return (
    <Box>
      <NavBar />
      <Center mb="120px" p="20px" pt="0px">
        <Stack spacing="6" maxW="800px">
          <BlogHeader post={post} />
          {post?.content.includes("<") ? (
            <div
              className={styles.reactMarkDown}
              dangerouslySetInnerHTML={{ __html: post.content }}
            ></div>
          ) : (
            <Markdown className={styles.reactMarkDown}>{post.content}</Markdown>
          )}
          <CTA />
        </Stack>
      </Center>
      <Footer />
    </Box>
  );
};
