import { Box } from "@chakra-ui/react";
import styles from "./Homepage.module.css";
import NavBar from "./menu/App";
import Hero from "./Hero/App";
import Footer from "./footer/App";
import { Cookies } from "./components/Cookies";
import { Features } from "./Features/App";
import { CTA } from "./CTA/App";
import { Meta } from "../../components";
import Pricing from "./Pricing/Pricing";
import { useEffect, useRef } from "react";
import { checkToken } from "../../api";
import { useNavigate } from "react-router";
import Arcade from "./Arcade/Arcade";
import FeatureList from "./FeatureList/FeatureList";
import IndustryProfessionals from "./IndustryProfessionals/IndustryProfessionals";
import React from "react";
import Explainer from "./Explainer/Explainer";
import WallOfLove from "../../components/homepage/walloflove/WallOfLove";

const Homepage = () => {
  const initialised = useRef<boolean>(false);
  const navigate = useNavigate();
  const performTokenCheck = async () => {
    const res = await checkToken();
    if (res.success === true) {
      navigate("/app/dashboard");
    }
  };

  useEffect(() => {
    if (initialised.current === false) {
      initialised.current = true;
      performTokenCheck();
    }
  }, []);

  return (
    <Box className={styles.mainAppContainer}>
      <Meta
        title={"💰 WealthBee - Trading Journal 💰"}
        description={
          "💰 WealthBee is a simple, easy-to-use trading journal that helps you keep track of your investments and grow your wealth."
        }
        canonical={"https://wealthbee.io"}
      />
      <NavBar />

      <Hero />
      <Section>
        <IndustryProfessionals />
      </Section>
      {/* <Arcade /> */}
      <Section>
        <Features />
      </Section>
      <Section>
        <FeatureList />
      </Section>

      {/* <Section>
        <Explainer />
      </Section> */}
      <Section>
        <Pricing />
      </Section>
      {/* <Section>
        <CTA />
      </Section> */}
      <Footer />

      <Cookies />
    </Box>
  );
};

const Section = (props: any) => {
  return (
    <Box as="section" id={props.id} mt="100px">
      {props.children}
    </Box>
  );
};
export default Homepage;
