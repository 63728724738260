import { useEffect, useRef, useState } from "react";
import { Instrument, InstrumentType } from "../../types";
import { AccountAtom } from "../../store";
import { useAtomValue } from "jotai";
import { getInstruments } from "../../api";

interface UseInstrumentSearchController {
  instruments: InstrumentEx[];
  instrumentType: InstrumentType;
  setInstrumentType: (instrumentType: InstrumentType) => void;
  selectedInstrument?: Instrument;
  setSelectedInstrument: (instrument: Instrument) => void;
}

// extending the base type with a mapping to its underlying
export interface InstrumentEx extends Instrument {
  underlyingName?: string;
}

export const useInstrumentSearchController = (
  onInstrumentSelected: (
    instrument: Instrument,
    children?: Instrument[]
  ) => void
): UseInstrumentSearchController => {
  const initialised = useRef(false);
  const [instrumentType, setInstrumentType] = useState<InstrumentType>(
    InstrumentType.Equity
  );
  const [instruments, setInstruments] = useState<InstrumentEx[]>([]);
  const [selectedInstrument, setSelectedInstrument] = useState<
    Instrument | undefined
  >(undefined);

  const account = useAtomValue(AccountAtom);

  const loadInstruments = async () => {
    if (account.selectedAccount) {
      const result = await getInstruments(account.selectedAccount.id);

      // mapping the underlying instrument name
      const enhancedInstruments = result.map((instrument) => {
        return {
          ...instrument,
          underlyingName: !instrument.underlyingInstrumentId
            ? instrument.name
            : result.find((i) => i.id === instrument.underlyingInstrumentId)
                ?.name ?? undefined,
        };
      });

      setInstruments(enhancedInstruments);
    }
  };

  const filterUnderlyings = (): InstrumentEx[] => {
    const result = instruments.filter(
      (instrument) => instrument.instrumentType === instrumentType
    );
    return result;
  };

  useEffect(() => {
    if (selectedInstrument) {
      const children = instruments.filter(
        (x) => x.underlyingInstrumentId === selectedInstrument.id
      );
      onInstrumentSelected(selectedInstrument, children);
    }
  }, [selectedInstrument]);

  useEffect(() => {
    if (initialised.current) {
      setSelectedInstrument(undefined);
    }
  }, [instrumentType]);

  useEffect(() => {
    if (initialised.current) return;
    initialised.current = true;
    loadInstruments();
  }, [account]);

  return {
    instruments: filterUnderlyings(),
    instrumentType,
    setInstrumentType,
    selectedInstrument,
    setSelectedInstrument,
  };
};
