import { Heading, Text } from "@chakra-ui/react";
import styles from "./index.module.css";

export const Title = (props) => {
  return (
    <Heading className={`${styles.title}`} {...props}>
      {props.children}
    </Heading>
  );
};

export const CustomLink = (props) => {
  if (props.onClick !== undefined) {
    return (
      <Text className={`${styles.link}`} {...props}>
        {props.children}
      </Text>
    );
  } else {
    return (
      <Text
        className={`${styles.link}`}
        {...props}
        onClick={() => window.open(props.href, "_blank")}
      >
        {props.children}
      </Text>
    );
  }
};

export const ItemHeading = (props: any) => {
  return (
    <Text className={`${styles.itemHeading}`} {...props}>
      {props.children}
    </Text>
  );
};

export const Notes = (props) => {
  return (
    <Text className={`${styles.notes}`} {...props}>
      {props.children}
    </Text>
  );
};

export const CustomText = (props) => {
  return (
    <Text className={`${styles.innerText}`} {...props}>
      {props.children}
    </Text>
  );
};
