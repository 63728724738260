import { APIEndpoint } from ".";
import { Trade } from "../types/Trade";

const token = localStorage.getItem("token");

// Create a new trade
export const createTrade = async (trade: Trade, accountId: number) => {
  return fetch(`${APIEndpoint}/trades`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(trade),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating trade:", error));
};

// Bulk create a new trade
export const createManyTrades = async (trades: Trade[], accountId: number) => {
  return fetch(`${APIEndpoint}/trades/bulk`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(trades),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating trade:", error));
};

// // Read (get) all trades
export const getTrades = async (
  accountId: number,
  toDate: Date,
  fromDate: Date
  // book?: string,
  // positionGroup?: string,
  // status?: string,
  // underlying?: string,
  // tag?: string
) => {
  return fetch(
    `${APIEndpoint}/trades?toDate=${toDate}&fromDate=${fromDate}
    `,
    {
      method: "GET",
      headers: {
        Authorization: token || "",
        AccountId: accountId as any,
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => console.error("Error getting trades:", error));
};

// // Update an existing trade
export const updateTrade = async (
  tradeId: number,
  trade: Trade,
  accountId: number
) => {
  return fetch(`${APIEndpoint}/trades/${tradeId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(trade),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating trade:", error));
};

export const updateManyTrades = async (trades: Trade[], accountId: number) => {
  return fetch(`${APIEndpoint}/trades/bulk`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(trades),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating trade:", error));
};

// Delete an existing trade
export const deleteTrade = async (tradeId: number, accountId: number) => {
  return fetch(`${APIEndpoint}/trades/${tradeId}`, {
    method: "DELETE",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error deleting trade:", error));
};

//bulk delete trade with ids
export const deleteBulkTrades = async (
  tradeIds: number[],
  accountId: number
) => {
  return fetch(`${APIEndpoint}/trades/bulk`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(tradeIds),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error deleting trades:", error));
};

// Delete all trades
export const deleteAllTrades = async (accountId: number) => {
  return fetch(`${APIEndpoint}/trades`, {
    method: "DELETE",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error deleting all trades:", error));
};
