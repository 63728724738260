import {
  Box,
  Divider,
  Heading,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { KnowledgeBaseArticle } from "../../../../types/KnowledgeBaseArticle";
import moment from "moment";
import React from "react";

export const Sidebar = ({
  articles,
  categories,
  setSelectedArticle,
  selectedArticle,
}: {
  articles: {
    articleId: string;
    title: string;
    excerpt: string;
    publishedAt: string;
    dateUpdated: string;
    order: number;
    category: string;
  }[];
  selectedArticle: KnowledgeBaseArticle | null;
  setSelectedArticle: (article: any) => void;
}) => {
  return (
    <Stack spacing={{ base: "1px", lg: "1" }} px={{ lg: "3" }} py="3" w="300px">
      {categories.map((category) => {
        return (
          <RenderList
            key={category}
            articles={articles}
            category={category}
            selectedArticle={selectedArticle}
            setSelectedArticle={setSelectedArticle}
          />
        );
      })}

      {categories.length === 0 && (
        <RenderList
          articles={articles}
          category="All"
          selectedArticle={selectedArticle}
          setSelectedArticle={setSelectedArticle}
        />
      )}

      {articles.length === 0 && (
        <Link
          w="300px"
          _hover={{ textDecoration: "none", bg: mode("gray.100", "gray.700") }}
          _activeLink={{ bg: "gray.700", color: "white" }}
          borderRadius={{ lg: "lg" }}
        >
          No articles found
        </Link>
      )}
    </Stack>
  );
};

const RenderList = ({
  articles,
  category,
  setSelectedArticle,
  selectedArticle,
}: {
  articles: {
    articleId: string;
    title: string;
    excerpt: string;
    publishedAt: string;
    dateUpdated: string;
    order: number;
    category: string;
  }[];
  category: string;
  selectedArticle: KnowledgeBaseArticle | null;
  setSelectedArticle: (article: any) => void;
}) => (
  <Box>
    {category !== "All" && (
      <Heading size="md" pl="12px" mt="20px">
        {category}
      </Heading>
    )}
    <Divider mt="10px" />
    {articles.map((article) => {
      if (article.category !== category && category !== "All") {
        return null;
      }
      return (
        <Link
          key={article.articleId}
          _hover={{
            textDecoration: "none",
          }}
          _activeLink={{ bg: "gray.700", color: "white" }}
          borderRadius={{ lg: "lg" }}
          onClick={() => setSelectedArticle(article)}
        >
          <Stack
            backgroundColor={
              selectedArticle?.articleId === article.articleId
                ? "var(--chart-blue)"
                : "transparent"
            }
            spacing="1"
            py={{ base: "3", lg: "2" }}
            px={{ base: "3.5", lg: "3" }}
            fontSize="sm"
            lineHeight="1.25rem"
          >
            <Text fontWeight="medium">{article.title}</Text>
            <Text fontWeight="light">
              {moment(article.dateUpdated).calendar()}
            </Text>
          </Stack>
        </Link>
      );
    })}
  </Box>
);
