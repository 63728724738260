let APIEndpoint = "";

if (window.location.href.includes("beta.wealthbee.io")) {
  APIEndpoint = "https://beta-api.wealthbee.io/api/v1";
} else if (window.location.href.includes("localhost")) {
  APIEndpoint = "http://localhost:3123/api/v1";
} else {
  APIEndpoint = "https://api.wealthbee.io/api/v1";
}

export default APIEndpoint;
