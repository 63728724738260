import React from "react";
import { Stack } from "@chakra-ui/react";
import { MaintenanceModeToggleButton } from "../../../components/maintenance/MaintenanceMode";
import { PricingEnabledButton } from "./components/PricingEnabledButton";
import { InstrumentLogosButton } from "./components/InstrumentLogosButton";

export default function WebsiteSettings() {
  return (
    <Stack bg="var(--dark-gray)" p={4} borderRadius={8} spacing={"4"}>
      <MaintenanceModeToggleButton />
      <PricingEnabledButton />
      <InstrumentLogosButton />
    </Stack>
  );
}
