import { Box, HStack, Text } from "@chakra-ui/react";

const formatCurrency = (value: number) => {
  //Add commas etc
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};

export default function StatContainer({
  title,
  stat,
  change,
  changeSuffix,
  type,
}: {
  title: string;
  stat: number;
  change?: number;
  changeSuffix?: string;
  type: string;
}) {
  if (!stat) return null;
  //When aggregation function used on AGGrid like AVG
  if (typeof stat === "object" && stat?.value) {
    stat = parseFloat(stat.value * 100).toFixed(2);
  }
  return (
    <Box
      overflow="hidden"
      w="160px"
      h="55px"
      background={"var(--bg)"}
      borderRadius="6px"
      padding="8px"
      display={"flex"}
      flexDir={"column"}
      justifyContent={"space-between"}
    >
      <Text color="var(--dark-grey)" fontWeight={500} fontSize={"12px"}>
        {title}
      </Text>

      <HStack>
        {stat && (
          <Text
            color={stat > 0 ? "var(--green)" : "var(--red)"}
            fontWeight={700}
            fontSize={"14px"}
            w={"100%"}
            overflow={"hidden"}
            textOverflow={"ellipsis"}
            whiteSpace={"nowrap"}
            display={"block"}
            lineHeight={"1em"}
            maxH={"1em"}
          >
            {type === "NUMERIC" && stat}
            {type === "CURRENCY" && formatCurrency(stat)}
            {type === "PERCENTAGE" && stat + "%"}
            {type === "TEXT" && stat}
          </Text>
        )}
        <Text
          color={
            change > 0
              ? "var(--green)"
              : change < 0
              ? "var(--red)"
              : "var(--light-gray)"
          }
          fontStyle={"normal"}
          fontWeight={500}
          fontSize={"12px"}
          lineHeight={"140%"}
        >
          {change ? (
            <>
              {change.toFixed(2)}
              {changeSuffix}
            </>
          ) : (
            ""
          )}
        </Text>
      </HStack>
    </Box>
  );
}
