import {
  Box,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Component } from "react";
import { Panel } from "../../../../../components/design_library";
import React from "react";

export default class InteractiveBrokersAutoSyncInstructions extends Component {
  render() {
    return (
      <Panel>
        <Stack spacing="5">
          <Heading as="h3" size="lg">
            Instructions
          </Heading>

          <Text>
            NOTE: For AutoSync you should setup the "Historical Update" (other
            tab) and then additionally "Trade Confirmations" (below) this report
            will pick up any intraday trades.
          </Text>

          <Text>
            Once you have both reports configured you can add the AutoSync
            configuation above.
          </Text>

          <OrderedList>
            <ListItem>
              Log into the Interactive Brokers website, go to the 'Reports' page
              and 'Flex Queries' menu item
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr_as_1.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>
            <ListItem>
              Click the + to the right of 'Trade Confirmation Flex Query'
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr_as_2.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>

            <ListItem>
              Give it a “Query Name” and click on “Trade Confirmation”
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr_as_3.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>
            <ListItem>
              Select All fields via the Select All checkbox, then click Save
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr_as_4.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>
            <ListItem>
              In the list of headings select under 'Delivery Configuration':
              Change Format to CSV
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr_as_5.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>
            <ListItem>
              Under 'General Configuration' make sure all toggles are set to
              'No' and then Click 'Create'
            </ListItem>
            <ListItem>
              <Box my={4} boxSize="full">
                <Image
                  src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr_as_6.png"
                  style={{ width: "100%" }}
                />
              </Box>
            </ListItem>
          </OrderedList>
        </Stack>
      </Panel>
    );
  }
}
