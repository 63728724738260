import React from "react";
import FormBase from "./Form";
import { APIEndpoint, getCurrencies } from "../../api";
import { FormFrameworkComponentType } from "../../types/Enums";

export default function FormExample() {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [schema, setSchema] = React.useState<any>({
    instrumentSearchExample: {
      value: "",
      label: "Instrument Search Example",
      error: "",
      type: FormFrameworkComponentType.InstrumentSearch,
      required: true,
      enableCreateNew: true,
      instrumentType: "Equity",
    },
    textExample: {
      value: "",
      label: "Text Example",
      error: "",
      type: FormFrameworkComponentType.Text,
      required: true,
    },
    textAreaExample: {
      value: "",
      label: "Text Area Example",
      error: "",
      type: FormFrameworkComponentType.TextArea,
      required: true,
    },
    datePickerExample: {
      value: new Date().toISOString(),
      label: "Date Picker Example",
      error: "",
      type: FormFrameworkComponentType.Date,
      required: true,
    },
    selectExampleWithAPI: {
      value: new Date().toISOString(),
      label: "Select Example with API",
      error: "",
      type: FormFrameworkComponentType.Select,
      required: true,
      enum: ["Buy", "Sell"],
      enableCreateNew: false,
      api: getCurrencies, //Must return an array ONLY
    },
    selectExampleWithCreatable: {
      value: new Date().toISOString(),
      label: "Select Example where you can create new items",
      error: "",
      type: FormFrameworkComponentType.Select,
      required: true,
      enum: ["Buy", "Sell"],
      enableCreateNew: true,
    },
    radioExample: {
      value: new Date().toISOString(),
      label: "Radio Example",
      error: "",
      type: FormFrameworkComponentType.Radio,
      required: true,
      enum: ["Buy", "Sell"],
    },
    numberInputExample: {
      value: "",
      label: "Number Input Example",
      error: "",
      type: FormFrameworkComponentType.Number,
      required: true,
    },
  });

  const submitPayload = (payload: any) => {
    const token = localStorage.getItem("token");
    setIsLoading(true);

    return fetch(`${APIEndpoint}/metadata/forms`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `${token || ""}`,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSchema(data.schema);
          setIsLoading(false);
        }
      })
      .catch((error) => console.error("Error submitting form example:", error));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <FormBase
      title="Form Example"
      subtitle="This is a form example"
      onSubmit={submitPayload}
      submitText="Submit"
      schema={schema}
    />
  );
}
