import { InsightSearchPages } from "../types";
import APIEndpoint from "./APIEndpoint";

// Create a new insight
export const createInsight = async (insightData: any) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/insights/templates`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
    body: JSON.stringify({
      title: insightData.title,
      description: insightData.description,
      tags: insightData.tags,
      type: insightData.type,
      query: insightData.query,
      isPublic: insightData.isPublic,
      isDefaultInsight: insightData.isDefaultInsight,
      version: insightData.version,
      width: insightData.width,
      height: insightData.height,
    }),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating insight:", error));
};

// Update an insight
export const updateInsight = async (insightData: any) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/insights/templates/${insightData.insightId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
    body: JSON.stringify(insightData),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating insight:", error));
};

// Delete an insight
export const deleteInsight = async (insightId: number) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/insights/templates/${insightId}`, {
    method: "DELETE",
    headers: {
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error deleting insight:", error));
};

// Get a list of insights
export const getInsights = async (
  displaysPage: InsightSearchPages,
  search: string
) => {
  const token = localStorage.getItem("token");

  return fetch(
    `${APIEndpoint}/insights/templates?filter=${displaysPage}&search=${search}`,
    {
      method: "GET",
      headers: {
        Authorization: `${token || ""}`,
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => console.error("Error getting insights:", error));
};

// get the data for the insights
export const getInsightsDatasets = async (
  accountId: string,
  underlying?: string,
  tag?: string,
  strategy?: string,
  book?: string,
  positionGroup?: string,
  dateFrom?: string,
  dateTo?: string
) => {
  const token = localStorage.getItem("token");

  return fetch(
    `${APIEndpoint}/insights/datasets?underlying=${underlying}&tag=${tag}&book=${book}&positionGroup=${positionGroup}&dateFrom=${dateFrom}&dateTo=${dateTo}&strategy=${strategy}`,
    {
      method: "GET",
      headers: {
        Authorization: `${token || ""}`,
        AccountId: accountId,
      },
    }
  )
    .then((response) => response.json())
    .catch((error) => console.error("Error getting insights datasets:", error));
};
