import { Box, Stack } from "@chakra-ui/react";
import styles from "../../styles/Core.module.css";

export const HeaderContainer = (props: any) => {
  return (
    <Box>
      <Stack spacing={5}>{props.children}</Stack>
    </Box>
  );
};
