import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum, createGroupColumn } from "../../../components";
import convertSymbolToReadable from "../../../components/grid/utils/convertSymbolToReadable";
import Notes from "../../notes/Notes";

export const tradeColumnDefinitions: ColDef[] = [
  createGroupColumn("", "notes", ColumnTypeEnum.Text, {
    maxWidth: 65,
    cellRenderer: (params: any) => {
      if (params.data.notes || params.data.tags) {
        return <Notes notes={params.data.notes} tags={params.data.tags} />;
      } else {
        return null;
      }
    },
  }),

  createGroupColumn("Date", "tradeDate", ColumnTypeEnum.Date, {
    // rowDrag: true,
  }),
  createGroupColumn(
    "Symbol",
    "underlyingInstrument.symbol",
    ColumnTypeEnum.Text,
    {
      valueGetter: (params) => {
        return convertSymbolToReadable(params.data.instrument.symbol);
      },
    }
  ),

  createGroupColumn(
    "Underlying Symbol",
    "underlyingInstrument.symbol",
    ColumnTypeEnum.Text
  ),

  createGroupColumn("Position Group", "positionGroup", ColumnTypeEnum.Text),
  createGroupColumn("Book", "book", ColumnTypeEnum.Text),

  createGroupColumn("Strategy", "strategy", ColumnTypeEnum.Text),

  // createGroupColumn("Broker Trade Id", "brokerTradeId", ColumnTypeEnum.Text),
  createGroupColumn("Type", "tradeType", ColumnTypeEnum.Pill, {
    cellRendererParams: {
      exactMatch: true,
      colourMap: {
        Option: "var(--accent)",
        Equity: "var(--trade-stock)",
      },
      defaultColour: "var(--accent)",
    },
  }),
  createGroupColumn("Buy/Sell", "buySell", ColumnTypeEnum.Text),
  createGroupColumn("Put/Call", "instrument.putCall", ColumnTypeEnum.Pill, {
    cellRendererParams: {
      exactMatch: true,
      colourMap: {
        Put: "var(--trade-call)",
        Call: "var(--trade-put)",
      },
      defaultColour: "var(--accent)",
    },
  }),
  createGroupColumn("Strike", "instrument.strike", ColumnTypeEnum.Numeric),
  createGroupColumn(
    "Expiry Date",
    "instrument.expiry",
    ColumnTypeEnum.Date,
    {}
  ),
  createGroupColumn("Quantity", "quantity", ColumnTypeEnum.Numeric),
  createGroupColumn("Trade price", "price", ColumnTypeEnum.Currency, {
    cellRendererParams: {
      noFormatting: true,
    },
  }),
  createGroupColumn(
    "Market price",
    "priceData.price",
    ColumnTypeEnum.PriceDetail
  ),
  createGroupColumn(
    "Gross proceeds",
    "grossProceeds",
    ColumnTypeEnum.Currency,
    { cellRendererParams: { showDirection: true } }
  ),
  createGroupColumn("Net proceeds", "netProceeds", ColumnTypeEnum.Currency, {
    cellRendererParams: { showDirection: true },
  }),
  createGroupColumn("Fees", "fees", ColumnTypeEnum.Currency, {
    valueGetter: (params) => {
      return -Math.abs(parseFloat(params.data.fees + params.data.commission));
    },
  }),

  // createGroupColumn("Commission", "commission", ColumnTypeEnum.Numeric),
];
