import React, { useState, useEffect } from "react";
import { Box, VStack, HStack, Text, Progress, Heading } from "@chakra-ui/react";
import { InsightComparisonProps } from "../../../../../../types/Insight";
import NoDataAvailable from "../../../../../../components/insights/NoDataAvailable";

const ComparisonNumber = ({
  data,
  title,
  demo,
}: {
  data: InsightComparisonProps;
  title: string;
  demo?: boolean;
}) => {
  if (!data) return <NoDataAvailable title={title} />;
  const {
    prefix = "",
    value1 = 0,
    value2 = 0,
    suffix = "",
    decimals = 0,
    label1 = "",
    label2 = "",
  }: InsightComparisonProps = demo === true
    ? {
        prefix: "",
        value1: 40,
        value2: 60,
        suffix: "%",
        decimals: 2,
        label1: "Label 1",
        label2: "Label 2",
      }
    : data;

  if (!value1 || !value2) return <NoDataAvailable title={title} />;

  const [displayedValue1, setDisplayedValue1] = useState(0);
  const [displayedValue2, setDisplayedValue2] = useState(0);

  useEffect(() => {
    const animateValue1 = () => {
      setDisplayedValue1((prev) => {
        const difference = value1 - prev;
        if (Math.abs(difference) < 0.01) {
          return value1;
        }
        return prev + difference / 10;
      });
    };

    const interval1 = setInterval(animateValue1, 50);

    return () => clearInterval(interval1);
  }, [value1]);

  useEffect(() => {
    const animateValue2 = () => {
      setDisplayedValue2((prev) => {
        const difference = value2 - prev;
        if (Math.abs(difference) < 0.01) {
          return value2;
        }
        return prev + difference / 10;
      });
    };

    const interval2 = setInterval(animateValue2, 50);

    return () => clearInterval(interval2);
  }, [value2]);

  const formattedValue1 = displayedValue1.toFixed(decimals);
  const formattedValue2 = displayedValue2.toFixed(decimals);

  return (
    <Box w="full" p={4}>
      <VStack align="stretch" spacing={4}>
        <VStack align="stretch" spacing={1}>
          <Heading size="sm">{title}</Heading>
          <Text>
            {label1} vs {label2}
          </Text>
        </VStack>

        {!prefix && (
          <VStack spacing={4} w="full">
            <VStack spacing={1} align="center" w="full">
              <HStack justifyContent={"space-between"} w="full">
                <Text
                  fontSize="4xl"
                  fontWeight="bold"
                  color={
                    parseFloat(formattedValue1) > parseFloat(formattedValue2)
                      ? "var(--green)"
                      : "var(--red)"
                  }
                >
                  {prefix}
                  {formattedValue1}
                  {suffix}
                </Text>
                <Text fontSize="lg" color="var(--white)">
                  {label1}
                </Text>
              </HStack>
              <Progress
                borderRadius={4}
                value={parseFloat(formattedValue1)}
                size="lg"
                w="full"
                colorScheme={
                  parseFloat(formattedValue1) > parseFloat(formattedValue2)
                    ? "green"
                    : "red"
                }
              />
            </VStack>
            <VStack spacing={1} align="center" w="full">
              <HStack justifyContent={"space-between"} w="full">
                <Text
                  fontSize="4xl"
                  fontWeight="bold"
                  color={
                    parseFloat(formattedValue2) > parseFloat(formattedValue1)
                      ? "var(--green)"
                      : "var(--red)"
                  }
                >
                  {prefix}
                  {formattedValue2}
                  {suffix}
                </Text>
                <Text fontSize="lg" color="var(--white)">
                  {label2}
                </Text>
              </HStack>
              <Progress
                borderRadius={4}
                value={parseFloat(formattedValue2)}
                size="lg"
                w="full"
                colorScheme={
                  parseFloat(formattedValue2) > parseFloat(formattedValue1)
                    ? "green"
                    : "red"
                }
              />
            </VStack>
          </VStack>
        )}

        {prefix && (
          <VStack spacing={4} py="30px">
            <HStack spacing={1} align="center" w="full">
              <VStack justifyContent={"space-between"} w="full">
                <Text
                  fontSize="4xl"
                  fontWeight="bold"
                  color={
                    parseFloat(formattedValue1) > parseFloat(formattedValue2)
                      ? "var(--green)"
                      : "var(--red)"
                  }
                >
                  {prefix}
                  {formattedValue1}
                  {suffix}
                </Text>
                <Text fontSize="lg" color="var(--white)">
                  {label1}
                </Text>
              </VStack>
              <Text fontSize="lg" fontWeight={600}>
                vs
              </Text>
              <VStack justifyContent={"space-between"} w="full">
                <Text
                  fontSize="4xl"
                  fontWeight="bold"
                  color={
                    parseFloat(formattedValue2) > parseFloat(formattedValue1)
                      ? "var(--green)"
                      : "var(--red)"
                  }
                >
                  {prefix}
                  {formattedValue2}
                  {suffix}
                </Text>
                <Text fontSize="lg" color="var(--white)">
                  {label2}
                </Text>
              </VStack>
            </HStack>
          </VStack>
        )}
      </VStack>
    </Box>
  );
};

export default ComparisonNumber;
