import { Box } from "@chakra-ui/react";
import React from "react";

export default function () {
  return (
    <a href="https://discord.gg/5N9rJHhAbS" target="_blank" rel="noreferrer">
      <img
        src={"https://cdn.wealthbee.io/main/app/navbar/discord.svg"}
        alt="Join our Discord"
        style={{ width: "100px" }}
      />
    </a>
  );
}
