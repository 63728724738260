import React from "react";
import { useState } from "react";
import { Flex } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Allotment } from "allotment";
import { GridContainer, columnTypes, distinctValue } from "../../../components";
import { columnDefinitions } from "./columnDefinitions";
import { useConfigController } from "./useConfigController";
import { ConfigItem } from "../../../types";
import { EditConfig } from "./EditConfig";

export const Config = () => {
  const controller = useConfigController();

  const [selectedConfigItem, setSelectedConfigItem] = useState<ConfigItem>();
  return (
    <Flex h="full" w="full" direction="column">
      <Flex flex={1}>
        <Allotment>
          <GridContainer>
            <AgGridReact
              columnDefs={columnDefinitions} // Column Defs for Columns
              rowData={controller.items} // Row Data for Rows
              columnTypes={columnTypes} // Optional - custom column types
              rowSelection="single" // Options - allows click selection of rows
              suppressAggFilteredOnly={false}
              suppressAggFuncInHeader={true}
              rowHeight={31}
              animateRows={true}
              onRowSelected={(e) => {
                if (e.source === "api" || !e.node.isSelected()) return;
                if (!e.node.group && e.node.data) {
                  setSelectedConfigItem(e.node.data as ConfigItem);
                }
              }}
              aggFuncs={{
                distinct: distinctValue,
              }}
              autoGroupColumnDef={{
                pinned: "left",
                sortable: true,
                resizable: true,
                headerName: "Config Group",
              }}
              getRowId={(params) => params.data.id}
              onFirstDataRendered={(params) => {
                params.api.expandAll();
                params.api.sizeColumnsToFit();
              }}
            />
          </GridContainer>
          <Allotment.Pane
            minSize={600}
            maxSize={600}
            className="allotment-tutorial"
          >
            <EditConfig
              configItem={selectedConfigItem}
              updateConfigItem={controller.updateConfigItem}
            />
          </Allotment.Pane>
        </Allotment>
      </Flex>
    </Flex>
  );
};
