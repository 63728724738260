import { Box, Button, Flex, HStack, Switch } from "@chakra-ui/react";

import React from "react";

export default function CustomSwitch(props: any) {
  return (
    <Switch
      size="md"
      colorScheme="yellow"
      fontSize="12px"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      {...props}
    />
  );
}
