import { useEffect, useState } from "react";
import { getKnowledgeBaseArticles } from "../../api";
interface UseKnowledgeBaseController {
  articles: any[];
  categories: any[];
}

export const useKnowledgeBaseController = (): UseKnowledgeBaseController => {
  const [articles, setArticles] = useState([] as any[]);
  const [categories, setCategories] = useState([] as any[]);

  const getArticles = async () => {
    const data = await getKnowledgeBaseArticles(true);
    setArticles(data.articles);
    setCategories(data.metadata.categories);
  };

  useEffect(() => {
    getArticles();
  }, []);

  return {
    articles,
    categories,
  };
};
