import { Button } from "@chakra-ui/react";
import styles from "./index.module.css";
import "./style.css";

export const NegativeButton = (props: any) => {
  let overrideStyleString = `${styles.button} ${styles.negativeButton}`;

  if (props.dark === true) {
    overrideStyleString = overrideStyleString + ` ${styles.dark}`;
  }

  return (
    <Button {...props} className={overrideStyleString}>
      {props.children}
    </Button>
  );
};
