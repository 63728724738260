import { InstrumentPrice, PricingError, PricingIssue } from "../types";
import { APIEndpoint } from ".";
import { PricingPollingResponseObject } from "../types/PricingPoller";

const token = localStorage.getItem("token");

export const calculatePrice = async (
  accountId: number,
  marketPrice: number,
  underlyingPrice: number,
  volatility: number,
  strike: number,
  expiry: Date,
  optionType: string
): Promise<InstrumentPrice | undefined> => {
  const result = await fetch(`${APIEndpoint}/pricing/calc`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify({
      marketPrice,
      underlyingPrice,
      volatility,
      strike,
      expiry,
      optionType,
    }),
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data as InstrumentPrice;
    }
  }

  return undefined;
};

export const getQueue = async (): Promise<
  PricingPollingResponseObject | undefined
> => {
  const result = await fetch(`${APIEndpoint}/pricing/queues`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
    },
  });

  if (result.ok) {
    const data: PricingPollingResponseObject = await result.json();
    if (data.success) {
      return data;
    }
  }

  return undefined;
};

export const getAllPricingErrors = async (): Promise<PricingError[]> => {
  const result = await fetch(`${APIEndpoint}/pricing/errors`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
    },
  });

  if (result.ok) {
    const data = await result.json();
    return (data?.data as PricingError[]) ?? [];
  }

  return [];
};

export const getCurrentPricingIssues = async (): Promise<PricingIssue[]> => {
  const result = await fetch(`${APIEndpoint}/pricing/issues/current`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
    },
  });

  if (result.ok) {
    const data = await result.json();
    return (data?.data as PricingIssue[]) ?? [];
  }

  return [];
};

export const getEODPricingIssues = async (): Promise<PricingIssue[]> => {
  const result = await fetch(`${APIEndpoint}/pricing/issues/eod`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
    },
  });

  if (result.ok) {
    const data = await result.json();
    return (data?.data as PricingIssue[]) ?? [];
  }

  return [];
};
