import { useAtom, useAtomValue } from "jotai";
import { updateLayoutForAccount } from "../../../api";
import { AccountAtom } from "../../../store";
import { useNotifications } from "../../../hooks/useNotifications";
import { InsightTemplate } from "../../../types/Insight";
import { DashboardLayoutStateStore } from "../../../store/DashboardLayout";
import { useGridLayoutController } from "../canvas/useGridLayoutController";

export interface UseLayout {
  removeInsightFromLayouts: (insight: InsightTemplate) => void;
  addInsightToLayouts: (insight: InsightTemplate) => void;
}

interface LayoutItem {
  i: string;
  x: number;
  y: number;
  w: number;
  h: number;
  minW?: number;
  maxW?: number;
  minH?: number;
  maxH?: number;
  data?: InsightTemplate;
}

export const useLayout = (): UseLayout => {
  const [dashboardLayoutState, setDashboardLayoutState] = useAtom(
    DashboardLayoutStateStore
  );
  const accountState = useAtomValue(AccountAtom);
  const notifications = useNotifications();
  const gridLayout = useGridLayoutController();

  const updateLayouts = async (newLayoutData: any, message: string) => {
    const update = await updateLayoutForAccount(
      accountState?.selectedAccount?.id,
      {
        layout: newLayoutData,
      }
    );

    setDashboardLayoutState({
      ...dashboardLayoutState,
      layouts: newLayoutData,
      enabledInsights: update.enabledInsights,
    });

    notifications.createNotification(
      message,
      `Insight has been ${message.toLowerCase()} the dashboard.`
    );
    setTimeout(() => notifications.clearNotifications(), 3000);

    gridLayout.fetchLayouts();
  };

  const removeInsightFromLayouts = async (insight: InsightTemplate) => {
    const newLayoutData: { [key: string]: LayoutItem[] } = {
      ...dashboardLayoutState.layouts,
    };

    dashboardLayoutState.breakpoints.forEach((breakpoint) => {
      newLayoutData[breakpoint] = newLayoutData[breakpoint].filter(
        (item: LayoutItem) =>
          parseInt(item.i) !== parseInt(insight.insightId.toString())
      );
    });

    await updateLayouts(newLayoutData, "Removed");
  };

  const addInsightToLayouts = async (insight: InsightTemplate) => {
    setDashboardLayoutState((prevStat) => {
      return {
        ...prevStat,
        gridLoading: true,
      };
    });
    const newLayoutData: { [key: string]: LayoutItem[] } = {
      ...dashboardLayoutState.layouts,
    };

    dashboardLayoutState.breakpoints.forEach((breakpoint) => {
      //Need to add check here for type...
      const layoutItem: LayoutItem = {
        i: insight.insightId.toString(),
        x: 0,
        y: 0,
        w: insight.width || 1,
        h: insight.height || 1,
        data: insight,
      };

      newLayoutData[breakpoint].push(layoutItem);
    });

    await updateLayouts(newLayoutData, "Added");

    setDashboardLayoutState((prevStat) => {
      return {
        ...prevStat,
        gridLoading: false,
      };
    });
  };

  return {
    removeInsightFromLayouts,
    addInsightToLayouts,
  };
};
