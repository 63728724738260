import { APIEndpoint } from ".";

// Create a new account
export const getTradeConfirmationEmails = async () => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/trade-confirmations/emails`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error getting trade confirmations:", error)
    );
};

//get speicfic email

export const getSpecificTradeConfirmationEmail = async (id: number) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/trade-confirmations/emails/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) =>
      console.error("Error getting trade confirmations:", error)
    );
};

export const reprocessEmail = async (id: number) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/trade-confirmations/emails/${id}/reprocess`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error reprocessing email:", error));
};
