import React from "react";

interface ShowProps {
  if: boolean;
  children: any;
}

export const Show = (props: ShowProps) => {
  return props.if ? <>{props.children}</> : null;
};
