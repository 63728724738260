import { UseToastOptions, useToast } from "@chakra-ui/react";

export interface UseNotifications {
  createNotification: (title: string, description: string) => void;
  createTradeNotification: (title: string, description: string) => void;
  createPositionNotification: (title: string, description: string) => void;
  createTradeChangeNotification: (description: string) => void;
  clearNotifications: (type?: string) => void;
  createLongRunningNotification: (title: string, description: string) => void;
  clearLongRunningNotification: () => void;
  showErrorMessage: (error: any, title?: string) => void;
}

export const useNotifications = (): UseNotifications => {
  const toast = useToast();
  const toastId = "toast-custom-container";
  const toastDefaultConfig: UseToastOptions = {
    status: "loading",
    duration: 30000,
    isClosable: true,
    position: "bottom",
  };
  const toastDefaultConfigChange: UseToastOptions = {
    status: "info",
    duration: 10000,
    isClosable: true,
    position: "bottom",
  };

  const createNotification = (
    title: string,
    description: string,
    icon?: any
  ) => {
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        title: title,
        description: description,
        ...toastDefaultConfig,
      });
    }
  };

  const createTradeNotification = (title: string, description: string) => {
    if (!toast.isActive("toast-custom-container-trade")) {
      toast({
        id: "toast-custom-container-trade",
        title: title,
        description: description,
        ...toastDefaultConfig,
      });
    }
  };

  const createPositionNotification = (title: string, description: string) => {
    if (!toast.isActive("toast-custom-container-position")) {
      toast({
        id: "toast-custom-container-position",
        title: title,
        description: description,

        ...toastDefaultConfig,
      });
    }
  };

  const createTradeChangeNotification = (description: string) => {
    if (!toast.isActive("toast-custom-container-trade-change")) {
      toast({
        id: "toast-custom-container-trade-change",
        description: description,

        ...toastDefaultConfigChange,
      });
    }
  };

  const clearNotifications = (type?: string) => {
    if (type === undefined) {
      type = "default";
    }
    setTimeout(() => {
      if (type === "trade") {
        toast.close("toast-custom-container-trade");
      } else if (type === "position") {
        toast.close("toast-custom-container-position");
      } else if (type === "trade-change") {
        toast.close("toast-custom-container-trade-change");
      } else {
        toast.close("toast-custom-container");
      }
    }, 0);
  };

  const createLongRunningNotification = (
    title: string,
    description: string
  ) => {
    if (!toast.isActive("toast-custom-container-long-running")) {
      toast({
        ...toastDefaultConfig,
        id: "toast-custom-container-long-running",
        title: title,
        description: description,
        duration: null,
      });
    }
  };

  const clearLongRunningNotification = () => {
    setTimeout(() => {
      toast.close("toast-custom-container-long-running");
    }, 0);
  };

  const showErrorMessage = (error: any, title?: string) => {
    toast({
      ...toastDefaultConfig,
      status: "error",
      title: title ?? "Error",
      description: error,
      duration: 5000,
    });
  };

  return {
    createNotification,
    clearNotifications,
    createTradeNotification,
    createPositionNotification,
    createTradeChangeNotification,
    createLongRunningNotification,
    clearLongRunningNotification,
    showErrorMessage,
  };
};
