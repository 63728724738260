import { chakra, HTMLChakraProps, useColorMode } from "@chakra-ui/react";
import * as React from "react";
import colors from "../../../components/design_library/colors";

interface NavLinkProps extends HTMLChakraProps<"a"> {
  active?: boolean;
}

const DesktopNavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps>(
  (props, ref) => {
    const { active, ...rest } = props;

    return (
      <chakra.a
        ref={ref}
        display="inline-block"
        px="4"
        py="6"
        fontWeight="semibold"
        aria-current={active ? "page" : undefined}
        color={"white"}
        transition="all 0.2s"
        {...rest}
        _hover={{ color: "var(--accent)" }}
        _active={{ color: "var(--accent)" }}
        _activeLink={{
          color: "var(--accent)",
          fontWeight: "bold",
        }}
      />
    );
  }
);
DesktopNavLink.displayName = "DesktopNavLink";

export const MobileNavLink = (props: NavLinkProps) => {
  const { active, ...rest } = props;
  const { colorMode } = useColorMode();

  return (
    <chakra.a
      aria-current={active ? "page" : undefined}
      w="full"
      display="flex"
      alignItems="center"
      height="14"
      fontWeight="semibold"
      borderBottomWidth="1px"
      {...rest}
      color={colors.marketing.text.primary[colorMode]}
    />
  );
};

export const NavLink = {
  Mobile: MobileNavLink,
  Desktop: DesktopNavLink,
};
