import {
  Box,
  Flex,
  FlexProps,
  HStack,
  useColorMode,
  useDisclosure,
  VisuallyHidden,
} from "@chakra-ui/react";
import { Logo } from "./Logo";
import { NavLink } from "./NavLink";
import { NavMenu } from "./NavMenu";
import { Submenu } from "./Submenu";
import { ToggleButton } from "./ToggleButton";
import { links } from "./_data";
import { PrimaryButton } from "../../../components/design_library";
import colors from "../../../components/design_library/colors";
import { MaintenanceMode } from "../../../components/maintenance/MaintenanceMode";
const MobileNavContext = (props: FlexProps) => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <Flex
        align="center"
        justify="space-between"
        className="nav-content__mobile"
        {...props}
      >
        <HStack>
          <Box>
            <ToggleButton isOpen={isOpen} onClick={onToggle} />
          </Box>
          <Box as="a" rel="home" href="../">
            <Logo h="44px" iconColor="blue.400" maxW="200px" />
          </Box>
        </HStack>

        <Box visibility={{ base: "hidden", sm: "visible" }}>
          <PrimaryButton
            bg="transparent"
            as="a"
            href="../app/register"
            onClick={() => {}}
            color="var(--accent)"
          >
            Get started
          </PrimaryButton>
        </Box>
      </Flex>
      <NavMenu
        animate={isOpen ? "open" : "closed"}
        bg={"var(--bg)"}
        maxW="100vw"
      >
        {links.map((link, idx) =>
          link.children ? (
            <Submenu.Mobile key={idx} link={link} />
          ) : (
            <NavLink.Mobile key={idx} href={link.href}>
              {link.label}
            </NavLink.Mobile>
          )
        )}

        <PrimaryButton
          as="a"
          href="../app/register"
          isLoading={props.loading}
          mt="10px"
        >
          Sign in
        </PrimaryButton>
      </NavMenu>
    </>
  );
};

const DesktopNavContent = (props: any) => {
  const { colorMode, toggleColorMode } = useColorMode();

  let alreadyLoggedIn = false;
  if (localStorage.access_token) {
    alreadyLoggedIn = true;
  }

  return (
    <Flex
      className="nav-content__desktop"
      align="center"
      justify="space-between"
      {...props}
    >
      <Box as="a" href="../" rel="home" flex={1}>
        <VisuallyHidden>WealthBee</VisuallyHidden>
        <Logo h="46px" />
      </Box>

      <HStack spacing="1" minW="150px" justify="flex-end" flex={3}>
        <HStack
          id="nav__primary-menu"
          aria-label="Main Menu"
          listStyleType="none"
          display={"flex"}
          justifyContent="center"
        >
          {links.map((link, idx) => (
            <Box key={idx} id={`nav__menuitem-${idx}`}>
              {link.children ? (
                <Submenu.Desktop link={link} />
              ) : (
                <NavLink.Desktop
                  href={link.href}
                  color={colors.marketing.text.primary[colorMode]}
                >
                  {link.label}
                </NavLink.Desktop>
              )}
            </Box>
          ))}
        </HStack>
        <PrimaryButton
          as="a"
          href="../app/register"
          isLoading={props.loading}
          border="none !important"
          bg="var(--accent) !important"
        >
          Sign in
        </PrimaryButton>
      </HStack>
    </Flex>
  );
};

export const NavContent = {
  Mobile: MobileNavContext,
  Desktop: DesktopNavContent,
};
