import React from "react";
import { HStack, Text } from "@chakra-ui/react";
import { CustomToolTip } from "../../design_library";
import currencyFormatter from "currency-formatter";
import { BuySell, InstrumentType } from "../../../types";

export const CurrencyWithPercentageOfStock = (props: any) => {
  if (props.value === undefined) return null;
  if (props.value === 0) return null;

  const underlyingPrice = props?.data?.underlyingPriceData?.price;
  if (!underlyingPrice) return null;
  const price = props.value;
  let percentageOfStockPrice = 0;

  if (
    props.data.instrument.instrumentType === InstrumentType.Option &&
    props.data.quantity < 0 &&
    props.data.buySell === BuySell.Sell
  ) {
    percentageOfStockPrice = (price / underlyingPrice) * 100;
  }

  const precision = props.precision ? props.precison : 2;
  if (props.noFormatting) {
    return (
      <HStack alignItems="flex-start">
        <CustomToolTip
          label={`% of the current underlying price: ${
            percentageOfStockPrice.toFixed(2) + "%"
          }`}
        >
          <Text>
            {currencyFormatter.format(props.value, {
              code: "USD",
              precision,
            })}
          </Text>
        </CustomToolTip>
      </HStack>
    );
  }
};
