import React from "react";
import { Text, VStack } from "@chakra-ui/react";

export const TextColumn = (props: any) => {
  if (!props.value) return null;

  const fontWeight = props.node?.group ? 600 : 400;

  return (
    <VStack alignItems="flex-start">
      <Text fontWeight={fontWeight}>{props.value}</Text>
    </VStack>
  );
};
