import React from "react";
import { Box, Flex } from "@chakra-ui/react";

export const PillColumn = (props: any) => {
  const content = props.value;

  if (!content) return null;

  let bgColour = props.defaultColour ?? "var(--accent)";
  if (props.colourMap[props.value]) {
    bgColour = props.colourMap[props.value];
  }

  // don't use coloured pill when its an exact match and no matching value is found
  if (props.exactMatch && props.colourMap[props.value] === undefined) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <Box
          height="21px"
          lineHeight="100%"
          fontSize="11px"
          fontWeight={600}
          display="flex"
          justifyContent="center"
          alignItems="center"
          maxWidth="70px"
          color="var(--white)"
          padding="2px 12px"
          borderRadius="10px"
        >
          {content}
        </Box>
      </Box>
    );
  }

  return (
    <Flex justifyContent="flex-start" alignItems="center" height="100%">
      <Box
        height="21px"
        lineHeight="100%"
        fontSize="11px"
        fontWeight={400}
        display="flex"
        justifyContent="center"
        alignItems="center"
        maxWidth="70px"
        color="var(--white)"
        padding="2px 12px"
        borderRadius="10px"
        bg={bgColour}
      >
        {content}
      </Box>
    </Flex>
  );
};
