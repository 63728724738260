import { Box, Text, VStack } from "@chakra-ui/react";
import React from "react";

export default function FakeCalendarDate() {
  return (
    <Box
      width="180px"
      height="180px"
      display="flex"
      justifyContent={"space-between"}
      borderRadius="6px"
      alignItems="center"
      flexDir={"column"}
      border="1px solid var(--dark-gray)"
    >
      <Text zIndex={5} paddingTop="10px" fontWeight={500} color="var(--white)">
        Friday 13th
      </Text>
      <VStack zIndex={5} paddingBottom="50px">
        <Text zIndex={5} color="var(--green)" fontWeight={700} fontSize="20px">
          $12,735
        </Text>
        <Text zIndex={5} color="var(--semi-white)" fontSize={"14px"}>
          2 TRADES
        </Text>
      </VStack>
      <Box
        zIndex={0}
        width="180px"
        height="180px"
        background={"var(--calendarProfit)"}
        borderRadius="6px"
        opacity="0.8"
        pos={"absolute"}
      />
    </Box>
  );
}
