import { Box, Tooltip } from "@chakra-ui/react";
import styles from "./CustomTooltip.module.css";
import React from "react";
import { TooltipWithBounds } from "@vx/tooltip";

export const CustomToolTip = (props) => {
  return (
    <TooltipWithBounds
      label={props.label}
      className={styles.CustomToolTip}
      {...props}
    >
      <Box>{props.children}</Box>
    </TooltipWithBounds>
  );
};
