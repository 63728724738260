import { Box, Image } from "@chakra-ui/react";
import styles from "./OuterContainer.module.css";

export const OuterContainer = (props: any) => {
  return (
    <>
      <div className={styles.svgBackgroundImage}></div>

      {props.children}
      <div className={styles.blur}></div>
    </>
  );
};
