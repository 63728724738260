import { useAtomValue } from "jotai";
import { AccountAtom } from "../../../../store";
import { getConfig, updateConfig } from "../../../../api";
import { ConfigItem } from "../../../../types";
import { useEffect, useState } from "react";
import { Divider, HStack, Switch, Text } from "@chakra-ui/react";

export const PricingEnabledButton = () => {
  const account = useAtomValue(AccountAtom);
  const [pricingEnabled, setPricingEnabled] = useState(false);

  const checkConfig = async () => {
    if (account.selectedAccount) {
      const result = await getConfig(account.selectedAccount.id);
      result.filter((item: ConfigItem) => {
        if (item.key === "pricing_enabled") {
          setPricingEnabled(item.value === "true");
        }
      });
    }
  };

  const togglePricingEnabled = async () => {
    const config = await updateConfig(
      {
        namespace: "api",
        key: "pricing_enabled",
        value: pricingEnabled ? "false" : "true",
      },
      account.selectedAccount.id
    );

    setPricingEnabled(!pricingEnabled);
  };

  useEffect(() => {
    checkConfig();
  }, []);

  return (
    <HStack>
      <Switch isChecked={pricingEnabled} onChange={togglePricingEnabled} />
      <Text>Pricing</Text>
      <Divider orientation="vertical" h="20px" />
      <Text maxW="6xl" pl="20px">
        {pricingEnabled ? "Pricing is enabled" : "Pricing is disabled"}
      </Text>
    </HStack>
  );
};
