import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertIcon,
  Box,
  HStack,
  Heading,
  Image,
  Link,
  ListItem,
  OrderedList,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Panel } from "../../../../../components/design_library";

export const TastyWorksInstructions = () => {
  return (
    <Panel>
      <Stack maxW="4xl" spacing="5">
        <Heading as="h3" size="lg">
          Instructions
        </Heading>

        <HStack>
          <Text>Download your trade history as CSV file from:</Text>

          <Link
            color="var(--accent)"
            href="https://trade.tastyworks.com/index.html#/transactionHistoryPage"
            isExternal
          >
            TastyWorks Transaction History
            <ExternalLinkIcon mx="2px" />
          </Link>
        </HStack>

        <Alert status="warning">
          <AlertIcon />
          Do not download this file from the separate Tastyworks application,
          but use the website and your browser
        </Alert>

        <Box boxSize="full">
          <Image
            src="https://cdn.wealthbee.io/main/brokers/instructions/tastytrade-import.png"
            style={{ maxWidth: "100%" }}
            alt="TastyWorks Upload page"
          />
        </Box>

        <OrderedList pl="5" spacing="18px">
          <ListItem>
            On the left side menu choose "Activity" and then "History"
          </ListItem>
          <ListItem>
            Then setup the filter for a custom period of time and download it as
            csv file
          </ListItem>
          <ListItem fontWeight="500" pb="12px">
            The first line of your downloaded CSV should have the following:
          </ListItem>
          <ListItem as="em" mt="4px">
            Date/Time,Transaction Code,Transaction
            Subcode,Symbol,Buy/Sell,Open/Close,Quantity,Expiration
            Date,Strike,Call/Put,Price,Fees,Amount,Description,Account Reference
          </ListItem>
          <ListItem>Upload the CSV using the box above.</ListItem>
        </OrderedList>

        <Image
          src="https://cdn.wealthbee.io/main/brokers/instructions/tastyworks.png"
          style={{ maxWidth: "100%" }}
          alt="Tastyworks CSV"
        />
      </Stack>
    </Panel>
  );
};
