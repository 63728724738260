import { APIEndpoint } from ".";

export const getThemes = async () => {
  return fetch(APIEndpoint + "/grid/themes", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      return { success: false, title: "Server error", message: e.message };
    });
};

export const setTheme = async (theme: string) => {
  return fetch(APIEndpoint + "/grid/themes", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ theme }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      return { success: false, title: "Server error", message: e.message };
    });
};
