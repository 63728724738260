import moment from "moment";

export const dateComparator = (rowA: any, rowB: any) => {
  if (moment(rowA).isBefore(rowB)) {
    return 1;
  } else if (moment(rowA).isAfter(rowB)) {
    return -1;
  }
  return 0;
};
