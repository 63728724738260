import { APIEndpoint } from ".";
import { Account } from "../types/Account";

// Create a new account
export const createAccount = async (account: Account) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/accounts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
    body: JSON.stringify(account),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating account:", error));
};

// Read (get) all accounts
export const getAccounts = async () => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/accounts`, {
    method: "GET",
    headers: {
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error getting accounts:", error));
};

// Update an existing account
export const updateAccount = async (accountId: number, account: Account) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/accounts/${accountId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `${token || ""}`,
    },
    body: JSON.stringify(account),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating account:", error));
};

// Delete an existing account
export const deleteAccount = async (accountId: number) => {
  const token = localStorage.getItem("token");

  return fetch(`${APIEndpoint}/accounts/${accountId}`, {
    method: "DELETE",
    headers: {
      Authorization: `${token || ""}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error deleting account:", error));
};
