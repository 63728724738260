import { useAtomValue } from "jotai";
import { AccountAtom } from "../../../../store";
import { getConfig, updateConfig } from "../../../../api";
import { ConfigItem } from "../../../../types";
import { useEffect, useState } from "react";
import { Divider, HStack, Switch, Text } from "@chakra-ui/react";

export const InstrumentLogosButton = () => {
  const account = useAtomValue(AccountAtom);
  const [logosScanEnabled, setLogosScanEnabled] = useState(false);

  const checkConfig = async () => {
    if (account.selectedAccount) {
      const result = await getConfig(account.selectedAccount.id);
      result.filter((item: ConfigItem) => {
        if (
          item.key === "scanning_enabled" &&
          item.namespace === "instrumentLogosService"
        ) {
          setLogosScanEnabled(item.value === "true");
        }
      });
    }
  };

  const toggleScanningEnabled = async () => {
    const config = await updateConfig(
      {
        namespace: "instrumentLogosService",
        key: "scanning_enabled",
        value: logosScanEnabled ? "false" : "true",
      },
      account.selectedAccount.id
    );

    setLogosScanEnabled(!logosScanEnabled);
  };

  useEffect(() => {
    checkConfig();
  }, []);

  return (
    <HStack>
      <Switch isChecked={logosScanEnabled} onChange={toggleScanningEnabled} />
      <Text>Logos</Text>
      <Divider orientation="vertical" h="20px" />
      <Text maxW="6xl" pl="20px">
        {logosScanEnabled
          ? "Logo scanning is enabled"
          : "Logo scanning is disabled"}{" "}
      </Text>
    </HStack>
  );
};
