import { Box, useColorMode } from "@chakra-ui/react";

export const Panel = (props: any) => {
  const { colorMode } = useColorMode();

  return (
    <Box
      {...props}
      rounded={{ md: "lg" }}
      bg={"var(--bg)"}
      shadow="base"
      padding="5"
      mb="20px"
      colorMode={colorMode}
      sx={
        colorMode === "dark"
          ? {
              html: {
                "scrollbar-face-color": "var(--bg)",
                "scrollbar-base-color": "var(--bg)",
                "scrollbar-3dlight-color": "var(--bg)",
                "scrollbar-highlight-color": "var(--bg)",
                "scrollbar-track-color": "#000",
                "scrollbar-arrow-color": "#000",
                "scrollbar-shadow-color": "var(--bg)",
                "scrollbar-dark-shadow-color": "var(--bg)",
              },

              "&::-webkit-scrollbar": { width: "8px", height: "3px" },
              "&::-webkit-scrollbar-button": { "background-color": "#666;" },
              "&::-webkit-scrollbar-track": { "background-color": "var(--bg)" },
              "&::-webkit-scrollbar-track-piece": {
                "background-color": "#000",
              },
              "&::-webkit-scrollbar-thumb": {
                height: "50px",
                "background-color": "#666",
                "border-radius": "3px",
              },
              "&::-webkit-scrollbar-corner": {
                "background-color": "var(--bg)",
              },
              "&::-webkit-resizer": { "background-color": "#666" },
            }
          : {}
      }
    >
      {props.children}
    </Box>
  );
};
