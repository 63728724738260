import React from "react";
import FormBase from "../../../../components/forms/Form";
import { Box } from "@chakra-ui/react";
import { useAddEquityFormController } from "./useAddEquityFormController";

export default function AddEquityForm(props: any) {
  const controller = useAddEquityFormController(props);

  return (
    <Box>
      {props.goBackButton}
      <FormBase
        isLoading={controller.isLoading}
        title="Add Equity"
        subtitle="Use this form to add equity to your portfolio."
        onSubmit={controller.submitPayload}
        submitText="Create trade"
        schema={controller.schema}
      />
    </Box>
  );
}
