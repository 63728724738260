import React from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";

// !BP
import { ChevronDown, ChevronUp } from "../../design_library";

import currencyFormatter from "currency-formatter";

export const CurrencyColumn = (props: any) => {
  if (props.value === undefined) return null;
  // if (props.value === 0) return null;

  // determine this from the value.... inspect to see natural precision??
  const precision = props.precision ? props.precison : 2;

  if (props.noFormatting) {
    return (
      <VStack alignItems="flex-start">
        <Text>
          {currencyFormatter.format(props.value, {
            code: "USD",
            precision,
          })}
        </Text>
      </VStack>
    );
  }

  if (props.value < 0) {
    return (
      <HStack>
        {props.showDirection && (
          <ChevronDown
            backgroundColor={"transparent"}
            fill="var(--red)"
            strokeWidth={"1.5px"}
            stroke="var(--red)"
          />
        )}
        <VStack alignItems="flex-start">
          <Text color="var(--red)">
            {currencyFormatter.format(props.value, {
              code: "USD",
              precision,
            })}
          </Text>
        </VStack>
      </HStack>
    );
  } else {
    return (
      <HStack>
        {props.showDirection && (
          <ChevronUp
            backgroundColor={"transparent"}
            fill="var(--green)"
            strokeWidth={"1.5px"}
            stroke="var(--green)"
          />
        )}
        <VStack alignItems="flex-start">
          <Text color="var(--green)">
            {" "}
            {currencyFormatter.format(props.value, {
              code: "USD",
              precision,
            })}
          </Text>
        </VStack>
      </HStack>
    );
  }
};
