import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum, createGroupColumn } from "../../../components";
import moment from "moment";

export const columnDefinitions: ColDef[] = [
  createGroupColumn("topLevel", "topLevel", ColumnTypeEnum.Numeric, {
    rowGroup: true,
    hide: true,
  }),

  createGroupColumn("Date", "date", ColumnTypeEnum.Date, {
    sort: "asc",
    rowGroup: true,
    hide: true,
  }),

  createGroupColumn("Symbol", "instrument.symbol", ColumnTypeEnum.Logo, {
    // rowGroup: true,
    // hide: true,
  }),

  createGroupColumn("Type", "type", ColumnTypeEnum.Text, {}),
  createGroupColumn("Description", "description", ColumnTypeEnum.Text),
  createGroupColumn("Value", "value", ColumnTypeEnum.Currency, {
    cellRendererParams: {
      showDirection: true,
    },
    aggFunc: "sum",
  }),
  createGroupColumn("Currency", "currency", ColumnTypeEnum.Text),
];
