import React, { useEffect } from "react";
import {
  createKnowledgeBaseArticle,
  deleteKnowledgeBaseArticle,
  editKnowledgeBaseArticle,
  getKnowledgeBaseArticles,
  getSpecificKnowledgeBaseArticle,
} from "../../../api";
import { useState } from "react";
import {
  KnowledgeBaseArticle,
  KnowledgeBaseArticleStatus,
} from "../../../types/KnowledgeBaseArticle";
import "../../../components/design_library/WYSIWIGEditorStyling.css";

interface UseKnowledgeBaseAdminController {
  selectedArticle: any;
  setArticle: (article: KnowledgeBaseArticle) => void;
  articles: any[];
  categories: any[];
  sidebarIsScrolled: boolean;
  setSidebarIsScrolled: (value: boolean) => void;
  mainIsScrolled: boolean;
  setmMainIsScrolled: (value: boolean) => void;
  setSelectedArticle: (article: KnowledgeBaseArticle) => void;
  deleteArticle: (id: string) => void;
  saveArticle: (article: KnowledgeBaseArticle) => void;
  createArticle: () => void;
  loading: boolean;
  saveLoading: boolean;
  handleFileRead: (event: any) => void;
}

export const useKnowledgeBaseAdminController =
  (): UseKnowledgeBaseAdminController => {
    const [categories, setCategories] = React.useState([] as any[]);
    const [articles, setArticles] = React.useState([]);
    const [sidebarIsScrolled, setSidebarIsScrolled] = useState(false);
    const [mainIsScrolled, setmMainIsScrolled] = useState(false);
    const [selectedArticle, setArticle] = useState(null);
    const [loading, setLoading] = useState(false);

    const [saveLoading, setSaveLoading] = useState(false);

    const getAllArticles = async () => {
      const data = await getKnowledgeBaseArticles(false);
      setArticles(data.articles);
      setCategories(data.metadata.categories);
    };

    const createArticle = async () => {
      const article = await createKnowledgeBaseArticle({
        title: "New article",
        content: "This is a new article",
        category: "Other",
        status: KnowledgeBaseArticleStatus.Draft,
        order: 0,
      });

      if (article) {
        getAllArticles();
        setSelectedArticle(articles[articles.length - 1]);
      }
    };

    const setSelectedArticle = async (article: KnowledgeBaseArticle) => {
      const downloadedArticle = await getSpecificKnowledgeBaseArticle(
        article.articleId
      );
      setLoading(true);

      setArticle(downloadedArticle);
      setTimeout(() => {
        setLoading(false);
      }, 1);
    };

    const deleteArticle = async (id: string) => {
      if (
        window.confirm("Are you sure you want to delete this article?") ===
        false
      )
        return;
      const da = await deleteKnowledgeBaseArticle(id);
      await getAllArticles();
      setSelectedArticle(articles[0]);
    };

    const saveArticle = async (article: KnowledgeBaseArticle) => {
      setSaveLoading(true);
      if (!selectedArticle) return;
      const editedArticle = await editKnowledgeBaseArticle(
        selectedArticle.articleId,
        article
      );

      setTimeout(() => {
        setSaveLoading(false);
      }, 400);

      if (editedArticle) {
        await getAllArticles();
      }
    };

    const handleFileRead = async (file: any) => {
      const base64 = await convertBase64(file);
      return base64;
    };

    const convertBase64 = (file: any) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    useEffect(() => {
      getAllArticles();
    }, []);

    return {
      handleFileRead,
      selectedArticle,
      setArticle,
      articles,
      categories,
      sidebarIsScrolled,
      setSidebarIsScrolled,
      mainIsScrolled,
      setmMainIsScrolled,
      setSelectedArticle,
      deleteArticle,
      saveArticle,
      createArticle,
      loading,
      saveLoading,
    };
  };
