import { APIEndpoint } from ".";

export const getListOfBrokers = async () => {
  return fetch(APIEndpoint + "/uploads/brokers", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      console.log("API Call, error retrieving brokers", e);
    });
};

export const getListOfFiles = async () => {
  return fetch(APIEndpoint + "/uploads/files", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      console.log("API Call, error retrieving files", e);
    });
};

export const getSpecificFile = async (id: number) => {
  return fetch(APIEndpoint + "/uploads/files/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      console.log("API Call, error retrieving specific file", e);
    });
};

export const uploadFile = async (
  file: any,
  broker: string,
  accountId: number,
  book: string
) => {
  return fetch(APIEndpoint + "/uploads?broker=" + broker + "&book=" + book, {
    method: "POST",
    headers: {
      authorization: `${localStorage.getItem("token")}`,
      AccountId: accountId as any,
    },
    body: file,
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      console.log("API Call, error uploading file", e);
    });
};

export const markUploadAsReviewed = async (id: number) => {
  return fetch(APIEndpoint + "/uploads/files/" + id + "/reviewed", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      console.log("API Call, error marking upload as reviewed", e);
    });
};
