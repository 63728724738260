export interface KnowledgeBaseArticle {
  articleId?: number;
  title: string;
  content: string;
  dateCreated?: Date;
  dateUpdated?: Date;
  slug?: string;
  category?: string;
  status: KnowledgeBaseArticleStatus;
  order: number;
}

export enum KnowledgeBaseArticleStatus {
  Draft = "Draft",
  Published = "Published",
}
