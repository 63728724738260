import React from "react";
import {
  Box,
  Text,
  Flex,
  Icon,
  VStack,
  Avatar,
  HStack,
} from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";

// Sample Reviews Data
const reviews = [
  {
    id: 1,
    name: "John D.",
    review: "WealthBee has completely transformed how I track my futures. ",
    rating: 5,
  },

  {
    id: 3,
    name: "Alex P.",
    review:
      "The automated reporting feature saves me so much time. WealthBee’s platform is intuitive and powerful! Its the only platform that supports options.",
    rating: 5,
  },
  {
    id: 4,
    name: "Emily R.",
    review:
      "As an active trader, WealthBee’s trade grouping and P&L tracking is the best I’ve seen on any platform.",
    rating: 5,
  },
  {
    id: 5,
    name: "James K.",
    review:
      "WealthBee offers everything I need to manage my options efficiently. Highly recommend it to anyone serious about trading.",
    rating: 5,
  },
  {
    id: 6,
    name: "Olivia M.",
    review: "WealthBee has helped me become a more disciplined trader.",
    rating: 5,
  },
  {
    id: 7,
    name: "Daniel T.",
    review:
      "The ability to track all my trades and performance in one place is a game changer. ",
    rating: 5,
  },
];

// Single Review Card
const ReviewCard = ({ key, name, review, rating }) => (
  <Box
    borderWidth="1px"
    borderRadius="lg"
    padding="6"
    shadow="md"
    borderColor={"var(--bg)"}
    maxW="sm"
    mx="auto"
    bg="var(--dark-gray)"
  >
    <VStack align="start" spacing={3}>
      <HStack justifyContent={"space-between"}>
        <HStack fontSize="sm">
          <Avatar size="sm" name="" />
          <Text fontWeight="bold">{name}</Text>
        </HStack>
        <Flex justifySelf={"flex-end"}>
          {Array.from({ length: rating }, (_, i) => (
            <Icon as={FaStar} key={i} color="yellow.400" />
          ))}
        </Flex>
      </HStack>
      <Text fontSize="xs">{review}</Text>
    </VStack>
  </Box>
);

// Wall of Love Component
const WallOfLove = () => (
  <Box padding="10" pos="relative">
    <Flex wrap="wrap" justify="center" gap={6}>
      {reviews.map((review) => (
        <ReviewCard
          key={review.id}
          name={review.name}
          review={review.review}
          rating={review.rating}
        />
      ))}
    </Flex>
  </Box>
);

export default WallOfLove;
