import React from "react";
import {
  Box,
  Center,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { MdDragHandle, MdOutlineClose } from "react-icons/md";
import { GridContainer, columnTypes, distinctValue } from "../../../components";
import { AgGridReact } from "ag-grid-react";
import { useAtomValue } from "jotai";
import { TransactionsAtom } from "../../../store";
import { useEffect, useMemo, useRef, useState } from "react";
import { Trade, TradeEx } from "../../../types";
import { tradeColumnDefinitions } from "./tradeColumnDefinitions";
import StatContainer from "../../../components/design_library/StatContainer";
import "./Sash.css";
import { NotesModal } from "../../notes/Notes";
import { useGridContextMenuController } from "../../../components/grid/utils/useGridContextMenuController";
import mixpanel from "mixpanel-browser";
import "./TradesView.css";
import { KeyStatBar } from "./components/KeyStatBar";
import {
  CustomTab,
  CustomTabList,
  CustomTabPanels,
  CustomTabsContainer,
  CustomToolTip,
} from "../../../components/design_library";
import { AuditPageContent } from "../../../components/audit/Audit";
import { NotesTimeline } from "../../../components/timeline/NotesTimeline";
import { useGridState } from "../../../components/grid/useGridState";
import KnowledgeBaseHelper from "../../knowledgebase/KnowledgeBaseHelper";
import { useInstruments } from "../../../hooks";

interface TradesViewProps {
  onClose: () => void;
  tradeIds: string[];
  params: any;
}

export const TradesView = (props: TradesViewProps) => {
  const transactions = useAtomValue(TransactionsAtom);
  const [groupStatsAvailable, setGroupStatsAvailable] =
    useState<boolean>(false);

  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [noteModalOpen, setNoteModalOpen] = useState<boolean>(false);

  const gridState = useGridState({ gridName: "TradeView" });
  const instruments = useInstruments();

  useEffect(() => {
    if (props?.params?.node?.aggData) {
      setGroupStatsAvailable(true);
    } else {
      setGroupStatsAvailable(false);
    }
  }, [props.params]);

  const trades: TradeEx[] = useMemo(() => {
    const filteredTrades = transactions.trades.filter(
      (transaction: Trade) =>
        transaction.id && props.tradeIds.includes(transaction.id)
    );

    return filteredTrades.map((trade) => {
      return {
        ...trade,
        instrument: trade.instrumentId
          ? instruments.instruments.find((x) => x.id === trade.instrumentId)
          : undefined,
        underlyingInstrument: trade.underlyingInstrumentId
          ? instruments.instruments.find(
              (x) => x.id === trade.underlyingInstrumentId
            )
          : instruments.instruments.find((x) => x.id === trade.instrumentId),
      } as TradeEx;
    });
  }, [transactions.trades, props.tradeIds]);
  const contextController = useGridContextMenuController();
  const topLeftRef = useRef(null);

  return (
    <CustomTabsContainer height={"100%"}>
      <HStack
        w="full"
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <CustomTabList flex="1" p={0}>
          <HStack justifyContent={"space-between"}>
            <HStack>
              <CustomTab>Stats & Trades</CustomTab>
              <CustomTab>Timeline</CustomTab>

              <CustomTab isDisabled={!props.params?.node?.data ? true : false}>
                {!props.params?.node?.data ? (
                  <CustomToolTip label="Audit is only available on single positions">
                    Audit
                  </CustomToolTip>
                ) : (
                  <Text>Audit</Text>
                )}
              </CustomTab>
            </HStack>
          </HStack>
        </CustomTabList>

        <Center>
          <Text flex="2">
            <MdDragHandle className="grabHandleOnPane" />
          </Text>
        </Center>

        <IconButton
          justifyContent={"flex-end"}
          pr="20px"
          flex="1"
          size="lg"
          height="24px"
          variant="none"
          icon={<MdOutlineClose />}
          aria-label="close"
          onClick={props.onClose}
          ref={topLeftRef}
        />
      </HStack>
      <CustomTabPanels p={0} height={"100%"}>
        <TabPanel p="0" height={"100%"}>
          <Flex direction="column" h="full" className="allotment-tutorial">
            {props?.params?.node?.aggData && (
              <KeyStatBar
                isAggData={true}
                quantity={props?.params?.node?.aggData?.quantity}
                realisedPnL={props.params?.node?.aggData?.realisedPnL}
                netProceeds={props.params?.node?.aggData?.netProceeds}
                unRealisedPnL={props.params?.node?.aggData?.unRealisedPnL}
                unRealisedPnLPercentage={
                  props.params?.node?.aggData?.unRealisedPnLPercentage?.value
                }
                premiumReceived={props.params?.node?.aggData?.premiumReceived}
                costs={props.params?.node?.aggData?.costs}
                daysInPosition={
                  props.params?.node?.aggData?.daysInPosition?.value
                }
                // annualizedReturnPercentage={
                //   props.params.node.aggData.annualizedReturnPercentage.value
                // }
                // breakevenPoint={props.params.node.aggData.breakevenPoint}
                // risk={props.params.node.aggData.risk}
              />
            )}

            {props?.params?.node?.data && (
              <KeyStatBar
                quantity={props?.params?.node?.data?.quantity}
                realisedPnL={props.params?.node?.data?.realisedPnL}
                realisedPnLPercentage={
                  props.params?.node?.data?.realisedPnLPercentage
                }
                netProceeds={props.params?.node?.data?.netProceeds}
                unRealisedPnL={props.params?.node?.data?.unRealisedPnL}
                premiumReceived={props.params?.node?.data?.premiumReceived}
                costs={props.params?.node?.data?.costs}
                unRealisedPnLPercentage={
                  props.params?.node?.data?.unRealisedPnLPercentage
                }
                daysInPosition={props.params?.node?.data?.daysInPosition}
                annualizedReturnPercentage={
                  props.params?.node?.data?.annualizedReturnPercentage
                }
                breakevenPoint={props.params?.node?.data?.breakevenPoint}
                risk={props.params?.node?.data?.risk}
              />
            )}
            <GridContainer customClass={"gridContainerTradesViewHook"}>
              <AgGridReact
                rowHeight={31}
                reactiveCustomComponents={true}
                rowData={trades} // Row Data for Rows
                columnDefs={tradeColumnDefinitions} // Column Defs for Columns
                columnTypes={columnTypes} // Optional - custom column types
                suppressAggFilteredOnly={false}
                rowDragManaged={false}
                suppressAggFuncInHeader={true}
                getContextMenuItems={contextController.getContextMenuItems}
                suppressChangeDetection={false}
                aggFuncs={{
                  distinct: distinctValue,
                }}
                onRowDoubleClicked={(params) => {
                  setSelectedRow(params.data);
                  setNoteModalOpen(true);
                  mixpanel.track("Trade Double Clicked");

                  if (topLeftRef.current) {
                    topLeftRef.current.focus();
                  }
                }}
                onGridReady={gridState.onGridReady}
                onFirstDataRendered={gridState.onFirstDataRendered}
                onColumnEverythingChanged={gridState.onColumnChanged}
                onColumnResized={gridState.onColumnResized}
                onSortChanged={gridState.onColumnChanged}
              />
            </GridContainer>

            {noteModalOpen && (
              <NotesModal
                isOpen={noteModalOpen}
                onClose={() => {
                  setNoteModalOpen(false);
                  setSelectedRow(null);
                }}
                trade={selectedRow}
              />
            )}
          </Flex>
        </TabPanel>

        <TabPanel p={0} m={0} height={"100%"}>
          <NotesTimeline trades={trades} />
        </TabPanel>
        <TabPanel p={0} m={0} height={"100%"}>
          <AuditPageContent
            trades={trades}
            isDisabled={!props.params?.node?.data ? true : false}
          />
        </TabPanel>
      </CustomTabPanels>
    </CustomTabsContainer>
  );
};
