import { Box, Button, Container, Stack } from "@chakra-ui/react";
import { FormHeader } from "./FormHeader";

export const FormStructure = (props: {
  children: React.ReactNode;
  title: string;
  subtitle: string;
  onSubmit: () => void;
  isLoading?: boolean;
  submitText?: string;
}) => {
  return (
    <Box as="section" bg="bg.surface" pt="6" pb={{ base: "4", md: "8" }}>
      <FormHeader title={props.title} subtitle={props.subtitle} />
      <Stack spacing="2">{props.children}</Stack>
      <Button
        mt="4"
        type="submit"
        size="lg"
        w="full"
        onClick={(e) => {
          e.preventDefault();
          props.onSubmit();
        }}
        isLoading={props.isLoading}
      >
        {props.submitText || "Submit"}
      </Button>
    </Box>
  );
};
