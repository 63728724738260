import {
  Avatar,
  AvatarGroup,
  Box,
  Heading,
  HStack,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { PrimaryButton } from "../design_library";
import { BiRightArrow, BiRightArrowAlt } from "react-icons/bi";
import { StarIcon } from "@chakra-ui/icons";
import DiscordInvite from "../misc/DiscordInvite";
import WallOfLove from "./walloflove/WallOfLove";

export default function CTAHeaderText() {
  return (
    <Box
      pt={{ base: "80px", lg: "80px" }}
      pl={{ md: "0px", base: "100px", lg: "20px" }}
      pr={{ md: "0px", base: "100px", lg: "20px" }}
      flex="1"
      display={"flex"}
      flexDirection="column"
      minW="lg"
      alignItems={{
        base: "center",
        md: "center",
        lg: "center",
      }}
      textAlign={{ base: "center", md: "center", lg: "left" }}
      justifyContent={{ base: "center", md: "center", lg: "flex-start" }}
    >
      <Image
        src="https://cdn.wealthbee.io/main/app/authentication/candles.svg"
        pos="absolute"
        w="full"
        left="50%"
        transform={"translateX(-50%)"}
        justifyContent={"center"}
        zIndex={0}
      />

      <Heading
        as="h1"
        pos={"relative"}
        fontSize={{ base: "32px", md: "48px", lg: "64px" }}
        fontWeight="700"
        lineHeight="1.2"
        letterSpacing="tight"
        color={"var(--white)"}
        textTransform="uppercase"
        maxW="830px"
        textAlign={"center"}
      >
        Track, Analyze, Succeed:{" "}
        <Text color="var(--accent) !important">
          {" "}
          Your All-in-One Trading Journal
        </Text>
      </Heading>

      <Heading
        as="h2"
        fontSize="24px"
        pt="20px"
        textAlign={"center"}
        maxW="800px"
        fontWeight="400"
        lineHeight="1.4"
        color={"var(--white)"}
      >
        WealthBee is your all-in-one trading journal and financial insights
        tool. Seamlessly monitor your portfolio, analyze your trades, and grow
        your wealth across
        <span className="rainbow"> stocks, options, and futures.</span>
      </Heading>

      <HStack justify={"flex-start"} alignItems="center" mt="22px">
        <PrimaryButton
          id="cta_1"
          as="a"
          href="../app/register"
          onClick={() => {
            localStorage.setItem("ftk", "true");
          }}
          color={"var(--white)"}
          border="none !important"
          bg="var(--accent-dark) !important"
          mt="20px"
          fontSize="18px !important"
          minW="300px"
          h="60px"
        >
          Start 30 day free trial now
          <Box pl="6px">
            <BiRightArrowAlt />
          </Box>
        </PrimaryButton>
      </HStack>

      <AvatarGroup size="md" max={11111} mt="60px" pos="relative">
        <Avatar name="" src="https://i.pravatar.cc/100" />
        <Avatar name="" src="https://i.pravatar.cc/100?img=2" />
        <Avatar name="" src="https://i.pravatar.cc/100?img=3" />
        <Avatar name="" src="https://i.pravatar.cc/100?img=4" />
        <Avatar name="" src="https://i.pravatar.cc/100?img=5" />
        <Avatar name="" src="https://i.pravatar.cc/100?img=6" />
        <Avatar name="" src="https://i.pravatar.cc/100?img=7" />
        <Avatar name="" src="https://i.pravatar.cc/100?img=8" />
        <Avatar name="" src="https://i.pravatar.cc/100?img=9" />
        <Avatar name="" src="https://i.pravatar.cc/100?img=10" />
        <Avatar name="" src="https://i.pravatar.cc/100?img=11" />
      </AvatarGroup>

      <HStack p="6px" mt="14px" borderRadius={"50px"} mb="30px">
        <StarIcon color="var(--accent)" />
        <StarIcon color="var(--accent)" />
        <StarIcon color="var(--accent)" />
        <StarIcon color="var(--accent)" />
        <StarIcon color="var(--accent)" />
      </HStack>

      <WallOfLove />
    </Box>
  );
}
