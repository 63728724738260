import React, { useMemo } from "react";
import { Flex, InputGroup, InputLeftAddon, Input } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { PrimaryButton } from "../../components/design_library";
import { Brokers } from "../../types";
import {
  AddOrEditConfigurationProps,
  useAddOrEditConfigurationController,
} from "./useAddOrEditAutoSyncConfigurationController";
import styles from "./AutoSync.module.css";
import { Show } from "../../components";
import { IBKR_AutoSync } from "./Brokers/IBKR/IBKR_AutoSync";

const supportedBrokers = [Brokers.IBKR];

export const AddOrEditAutoSyncConfiguration = (
  props: AddOrEditConfigurationProps
) => {
  const controller = useAddOrEditConfigurationController(props);

  const brokerOptions = useMemo(() => {
    return Object.values(supportedBrokers).map((item) => {
      return { value: item, label: item };
    });
  }, []);

  return (
    <Flex direction="column" w="full">
      <Show if={controller.new}>
        <InputGroup size="sm">
          <InputLeftAddon>Broker</InputLeftAddon>
          <Select
            className={styles.autoSyncBrokerSelect}
            placeholder="Select a broker to configure"
            size="sm"
            value={
              controller.selectedBroker
                ? brokerOptions.find(
                    (x) => x.value === controller.selectedBroker
                  )
                : undefined
            }
            options={brokerOptions}
            onChange={(e) => {
              controller.setSelectedBroker(e?.value as Brokers);
            }}
          />
        </InputGroup>
      </Show>

      <Show if={!controller.new}>
        <InputGroup size="sm">
          <InputLeftAddon>Broker</InputLeftAddon>
          <Input disabled={true} value={controller.selectedBroker} />
        </InputGroup>
      </Show>

      <Flex flex={1} w="full" my={2}>
        <Show if={controller.selectedBroker === Brokers.IBKR}>
          <IBKR_AutoSync settings={controller.settings} close={props.close} />
        </Show>
      </Flex>

      <Show if={controller.selectedBroker === undefined}>
        <Flex mb={1} direction="row-reverse">
          <PrimaryButton
            size="sm"
            onClick={() => {
              props.close();
            }}
          >
            Close
          </PrimaryButton>
        </Flex>
      </Show>
    </Flex>
  );
};
