import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum, createGroupColumn } from "../../../../components";

export const pricesColumnDefinitions: ColDef[] = [
  createGroupColumn("Timestamp", "timestamp", ColumnTypeEnum.Text),
  createGroupColumn("Symbol", "symbol", ColumnTypeEnum.Text),
  createGroupColumn("Instrument Type", "instrumentType", ColumnTypeEnum.Text),
  createGroupColumn("Expiry", "expiry", ColumnTypeEnum.Date),
  createGroupColumn("PriceType", "priceType", ColumnTypeEnum.Text),
  createGroupColumn("Source", "source", ColumnTypeEnum.Text),
  createGroupColumn("Price", "price", ColumnTypeEnum.Text),
];
