import { Component } from "react";
import { Box, Center, Heading, Stack } from "@chakra-ui/layout";
import { Meta } from "./components";
//import NavBar from "./legacy/homepage/menu/App";

export default class FourOhFour extends Component {
  componentDidMount() {
    window.location.href = "/";
  }

  render() {
    return (
      <>
        <Meta
          title={"WealthBee - 404"}
          description={
            "Automated trade tracking and analysis platform designed for stock and options traders. Eliminate manual entry and import trades directly from your brokerage account."
          }
          canonical={"https://wealthbee.io/404"}
        />

        {/* <NavBar></NavBar> */}
        <Box backgroundColor="#1a202c" mt="-72px" height="72px"></Box>
        <Box backgroundColor="#1a202c" height="100vh">
          <Center>
            <Stack>
              <Box>
                <Heading color="white" pt="40" textAlign="center" size="4xl">
                  404
                </Heading>
              </Box>
              <Box>
                <Heading color="white" pt="5" textAlign="center" size="lg">
                  Page cannot be found
                </Heading>
              </Box>
            </Stack>
          </Center>
        </Box>
      </>
    );
  }
}
