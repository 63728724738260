import { HStack, Text } from "@chakra-ui/react";
import { FastTag, StockLogo } from "../../design_library";

export const UnderlyingRender = (params: any) => {
  let underlyingPrice;
  try {
    underlyingPrice = parseFloat(
      params?.node?.aggData["underlyingPriceData.price"]
    ).toFixed(2);
  } catch (e) {
    console.error(e);
  }
  return (
    <HStack>
      <StockLogo size="20" stock={params?.value} />
      <Text fontWeight={600}>{params.value}</Text>

      {!isNaN(underlyingPrice) && parseFloat(underlyingPrice) !== 0 && (
        <FastTag size="xs" padding={"6px !important"} fontSize="12px">
          ${underlyingPrice}
        </FastTag>
      )}

      <Text fontWeight={400}>({params.node.allChildrenCount}) </Text>
    </HStack>
  );
};
