import {
  Box,
  Heading,
  HStack,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import NavWrapper from "./NavWrapper";
import { BiLinkExternal } from "react-icons/bi";

export default function TutorialVideo() {
  return (
    <NavWrapper previousEnabled={false} nextDisabled={true}>
      <Heading as="h1" fontSize="32px" mb={4}>
        Tutorial
      </Heading>
      <Text fontSize="16px" mb={4}>
        Welcome to WealthBee, please watch this tutorial to learn how WealthBee
        works. If you have any questions, please don't hesitate to contact us.
      </Text>

      <Text>We will cover:</Text>

      <UnorderedList>
        <ListItem>Creating your portfolio</ListItem>
        <ListItem>Uploading your trades</ListItem>
        <ListItem>Categorising and grouping transactions</ListItem>
        <ListItem>Viewing metrics and analytics</ListItem>
      </UnorderedList>

      <HStack mt="10px">
        <Link
          fontSize="16px"
          href="https://www.youtube.com/embed/atZ2cSdQ_kA?si=qvPV-BVmPc-hQZDG"
          isExternal
          color="var(--accent)"
        >
          Click here to watch the tutorial
        </Link>
        <BiLinkExternal color="var(--accent)" />
      </HStack>
    </NavWrapper>
  );
}
