import { atom } from "jotai";

export interface PricingQueueState {
  active: Boolean;
}

const buildInitialState = (): PricingQueueState => {
  const state: any = {
    active: false,
  };

  return state;
};

export const PricingQueueAtom = atom<PricingQueueState>(buildInitialState());
