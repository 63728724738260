import {
  chakra,
  HTMLChakraProps,
  Image,
  useColorMode,
  useToken,
} from "@chakra-ui/react";
import * as React from "react";

export const Logo = (
  props: HTMLChakraProps<"svg"> & { iconColor?: string }
) => {
  const { iconColor = "currentColor", ...rest } = props;
  const color = useToken("colors", iconColor);
  return (
    <chakra.svg aria-hidden width="260px" {...rest}>
      {useColorMode().colorMode === "dark" ? (
        <image
          height={"35"}
          id="img1"
          href="https://cdn.wealthbee.io/main/logos/wealthbee-logo-with-text.svg"
        />
      ) : (
        <image
          height="45"
          id="img1"
          href="https://cdn.wealthbee.io/main/logos/wealthbee-logo-with-text.svg"
        />
      )}
    </chakra.svg>
  );
};
