import React, { useEffect } from "react";
import { getCurrencies } from "../../api";
import "react-datepicker/dist/react-datepicker.css";
import { FormStructure, SchemaMapper } from "./components";

export default function FormBase(props: {
  schema: any;
  title: string;
  subtitle: string;
  isLoading: boolean;
  onSubmit: (payload: any) => void;
  submitText: string;
}) {
  const [schema, setSchema] = React.useState({});

  const [currencyList, setCurrencyList] = React.useState([] as string[]);

  useEffect(() => {
    getCurrencies().then((data) => {
      setCurrencyList(data.currencies);
    });
  }, []);

  const onChange = (
    key: string,
    value: string | object,
    isCreatable: boolean
  ) => {
    if (isCreatable) {
      setSchema((prevState) => {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            value,
            error: "",
          },
        };
      });
      return;
    } else {
      setSchema((prevState) => {
        return {
          ...prevState,
          [key]: {
            ...prevState[key],
            value,
            error: "",
          },
        };
      });
    }
  };

  useEffect(() => {
    setSchema(props.schema ?? {});
  }, [props.schema]);

  return (
    <FormStructure
      isLoading={props.isLoading}
      title={props.title}
      subtitle={props.subtitle}
      onSubmit={(e) => {
        props.onSubmit(schema);
      }}
      submitText={props.submitText}
    >
      <SchemaMapper schema={schema} onChange={onChange} />
    </FormStructure>
  );
}
