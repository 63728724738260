import { APIEndpoint } from ".";

// Create a new account
export const getVersion = async () => {
  const result = await fetch(`${APIEndpoint}/app/version`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (result.ok) {
    const data = await result.json();
    return data.version;
  }
};
