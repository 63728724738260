import { useAtom } from "jotai";
import { AppStateAtom } from "../../store";
import { useState } from "react";
import { subscribe } from "../../api/Subscribe";
import { useToast } from "@chakra-ui/react";
import { checkToken } from "../../api";
import { useUser } from "../../hooks/useUser";
import { useNotifications } from "../../hooks/useNotifications";

interface SubscriptionController {
  subscribe: () => void;
  checkSubscription: () => void;

  loading: boolean;
}

export const useSubscriptionController = () => {
  const [appState, setAppState] = useAtom(AppStateAtom);
  const [loading, setLoading] = useState(false);
  const notifications = useNotifications();
  const user = useUser();

  const performSubscribe = () => {
    setLoading(true);
    subscribe().then((res) => {
      if (res?.success === true) {
        window.location.href = res.session.url;
        setLoading(false);
      } else {
        notifications.createNotification("Something went wrong!", res?.message);

        setTimeout(() => {
          notifications.clearNotifications();
        }, 1600);

        setLoading(false);
      }
    });
  };

  const checkSubscriptionStatus = () => {
    console.log("Checking subscription status...", appState.subscriptionActive);

    if (appState.subscriptionActive === true) {
      subscribeSuccess();
    } else {
      user.refreshUserProfile();

      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  };

  const subscribeSuccess = () => {
    notifications.createNotification(
      "Subscription active",
      "You can now access all premium features!"
    );

    setTimeout(() => {
      notifications.clearNotifications();
    }, 1600);
    setLoading(false);
  };

  return {
    subscribe: performSubscribe,
    checkSubscription: checkSubscriptionStatus,
    loading,
  } as SubscriptionController;
};
