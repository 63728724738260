import React from "react";
import { tourData } from "./TourData";
import { TourProvider } from "@reactour/tour";
import Tutorial from "./Tutorial";
import "./Tutorial.css";
import "./components/Rainbow.css";

export default function TourWrapper(props: any) {
  return (
    <TourProvider
      className="reactour_helper_large"
      components={{
        Navigation: ({ currentStep, gotoStep, steps }) => {
          return null;
        },
        Arrow: (position) => {
          return null;
        },
      }}
      scrollSmooth
      badgeContent={({ totalSteps, currentStep }) =>
        currentStep + 1 + "/" + totalSteps
      }
      styles={{
        highlightedArea: (base, { x, y, width, height }) => ({
          ...base,
          stroke: "var(--accent)",
          strokeWidth: 3,
          display: "block",
          width: width - 8,
          height: height - 6,
          x: x + 5,
          y: y + 3,
          rx: 10,
          pointerEvents: "none",
        }),
        popover: (base) => ({
          ...base,
          "--reactour-accent": "var(--accent)",
          borderRadius: "6px",
          color: "var(--white)",
          backgroundColor: "var(--bg)",
          border: "1px solid var(--accent)",
        }),
        maskArea: (base) => ({
          ...base,
          rx: 15,
        }),
        maskWrapper: (base) => ({
          ...base,
          color: "var(--bg)",
          opacity: 0.9,
        }),

        badge: (base) => ({
          ...base,
          left: "auto",
          right: "-0.8125em",
          display: "none", //Added for video
        }),
        // controls: (base) => ({ ...base, marginTop: 100 }),
        close: (base) => ({
          ...base,
          right: "auto",
          left: 8,
          top: 8,
          display: "none",
        }),
      }}
      steps={tourData}
      // onClickMask={({ setCurrentStep, currentStep, steps, setIsOpen }) => {
      //   if (steps) {
      //     if (currentStep === steps.length - 1) {
      //       setIsOpen(false);
      //     }
      //   }
      // }}
    >
      <>
        {props?.children}
        <Tutorial />
      </>
    </TourProvider>
  );
}
