import { Box, Image, Spinner, VStack } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { AppStateAtom } from "../../store";
import { useSubscriptionController } from "./useSubscriptionStatusController";
import { useEffect, useState } from "react";
import { PrimaryButton } from "../design_library";
import { NegativeButton } from "../design_library/NegativeButton";
import { checkToken } from "../../api";
import mixpanel from "mixpanel-browser";
import React from "react";

export const SubscriptionStatus = () => {
  const [appState, setAppState] = useAtom(AppStateAtom);
  const controller = useSubscriptionController();
  const [cancelButtonClicked, setCancelButtonClicked] = useState(false);
  const [subscriptionSpinner, setSubscriptionSpinner] = useState(false);

  const checkTokenAndRedirect = async () => {
    const token = await checkToken();
    const urlParams = new URLSearchParams(window.location.search);
    const result = urlParams.get("result");
    if (result === "paid" && appState.subscriptionActive !== true) {
      controller.checkSubscription();
      mixpanel.track("User successfully subscribed");
    } else if (result === "cancelled") {
      setAppState((state) => ({ ...state, subscriptionActive: false }));
      mixpanel.track("User cancelled stripe transaction");

      setCancelButtonClicked(true);
    } else if (!token.user.subscriptionStatus) {
      controller.subscribe();
    } else if (token.user.subscriptionStatus === true) {
      console.log("Subscribed");

      setAppState((state) => ({ ...state, subscriptionActive: true }));
    } else {
      // window.location.reload();
    }
  };

  useEffect(() => {
    checkTokenAndRedirect();
  }, [appState.subscriptionActive]);

  if (cancelButtonClicked) {
    return (
      <>
        <VStack
          width={"100vw"}
          height={"100vh"}
          pos={"fixed"}
          top="0"
          left="0"
          backgroundColor={"var(--bg)"}
          opacity={1}
          zIndex={8888}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Image
            height={"35"}
            mb="20px"
            src="https://cdn.wealthbee.io/main/logos/wealthbee-logo-with-text.svg"
            zIndex={99999}
          />
          <PrimaryButton
            px="100px"
            isLoading={subscriptionSpinner}
            py="20px"
            onClick={() => {
              setSubscriptionSpinner(true);
              controller.subscribe();
            }}
          >
            Subscribe
          </PrimaryButton>

          <NegativeButton
            bg="transparent"
            size="xs"
            mt="60px"
            onClick={() => {
              if (window.confirm("Are you sure you want to log out?")) {
                window.localStorage.removeItem("token");
                window.location.href = "../app/login";
              }
            }}
          >
            Log out
          </NegativeButton>
        </VStack>
      </>
    );
  } else if (appState.subscriptionActive !== true) {
    return (
      <>
        <Box
          width={"100vw"}
          height={"100vh"}
          pos={"fixed"}
          top="0"
          left="0"
          backgroundColor={"var(--bg)"}
          opacity={1}
          zIndex={99999}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Spinner size="xl" />
        </Box>
      </>
    );
  } else {
    return null;
  }
};
