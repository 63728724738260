import APIEndpoint from "./APIEndpoint";

export const GetFullListOfUsers = async () => {
  return fetch(`${APIEndpoint}/users`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error getting users:", error));
};

export const GetImpersonateToken = async (userId: number) => {
  return fetch(`${APIEndpoint}/users/userToken`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      authorization: `${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({ userId }),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error getting token:", error));
};
