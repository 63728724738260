import React from "react";
import {
  Flex,
  InputGroup,
  Input,
  InputLeftAddon,
  Text,
  FormErrorMessage,
  FormControl,
  Heading,
  OrderedList,
  ListItem,
  Box,
  Image,
  HStack,
  Spacer,
  Button,
} from "@chakra-ui/react";
import {
  IBKRAutoSyncControllerProps,
  useIBKRAutoSyncController,
} from "./useIBKRAutoSyncController";
import { PrimaryButton } from "../../../../components/design_library";
import { Show } from "../../../../components";

export const IBKR_AutoSync = (props: IBKRAutoSyncControllerProps) => {
  const controller = useIBKRAutoSyncController(props);
  return (
    <Flex direction="column" w="full">
      <FormControl isInvalid={!controller.validToken}>
        <InputGroup size="sm" my={2}>
          <InputLeftAddon w="225px">Token</InputLeftAddon>
          <Show if={controller.readOnly === true}>
            <Text size="sm">{controller.config.token}</Text>
          </Show>
          <Show if={!controller.readOnly}>
            <Input
              size="sm"
              type="text"
              value={controller.config.token}
              onChange={(e) =>
                controller.setConfig({
                  ...controller.config,
                  token: e.target.value,
                })
              }
            />
          </Show>
        </InputGroup>
        <FormErrorMessage>Token is required.</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!controller.validQueryId}>
        <InputGroup size="sm" my={2}>
          <InputLeftAddon w="225px">Trades & Cashflows Query Id</InputLeftAddon>
          <Show if={controller.readOnly === true}>
            <Text size="sm">{controller.config.tradesAndCashflowsQueryId}</Text>
          </Show>
          <Show if={!controller.readOnly}>
            <Input
              size="sm"
              type="text"
              value={controller.config.tradesAndCashflowsQueryId}
              onChange={(e) =>
                controller.setConfig({
                  ...controller.config,
                  tradesAndCashflowsQueryId: e.target.value,
                })
              }
            />
          </Show>
        </InputGroup>
        <FormErrorMessage>At least 1 Query Id is required.</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!controller.validQueryId}>
        <InputGroup size="sm" my={2}>
          <InputLeftAddon w="225px">
            Trade Confirmations Query Id
          </InputLeftAddon>
          <Show if={controller.readOnly === true}>
            <Text size="sm">{controller.config.tradeConfirmationsQueryId}</Text>
          </Show>
          <Show if={!controller.readOnly}>
            <Input
              size="sm"
              type="text"
              value={controller.config.tradeConfirmationsQueryId}
              onChange={(e) =>
                controller.setConfig({
                  ...controller.config,
                  tradeConfirmationsQueryId: e.target.value,
                })
              }
            />
          </Show>
        </InputGroup>
        <FormErrorMessage>At least 1 Query Id is required.</FormErrorMessage>
      </FormControl>

      <HStack mb={1}>
        <Spacer />
        <Button
          size="sm"
          colorScheme="green"
          disabled={!controller.validToken || !controller.validQueryId}
          onClick={() => {
            if (controller.validToken && controller.validQueryId) {
              controller.testConfig();
            }
          }}
          ml={2}
        >
          Test
        </Button>

        <Show if={!controller.readOnly}>
          <Button
            size="sm"
            ml={2}
            disabled={!controller.validToken || !controller.validQueryId}
            onClick={() => {
              if (controller.validToken && controller.validQueryId) {
                controller.saveConfig();
              }
            }}
          >
            Save
          </Button>
        </Show>
        <Show if={props.close !== undefined}>
          <Button
            size="sm"
            onClick={() => {
              props.close!();
            }}
            ml={2}
          >
            Close
          </Button>
        </Show>
      </HStack>

      <Flex direction="column">
        <Heading as="h3" size="lg" mb={1}>
          Instructions
        </Heading>
        <OrderedList>
          <ListItem>
            Log into the Interactive Brokers website, go to the 'Reports' page
            and 'Flex Queries' menu item. You should have setup both queries, if
            not see the instructions on our add assets page (next to the
            settings on the top right of the screen). Afterwards, click the COG
            icon to the right of 'Flex Web Service Configuration' shown below in
            the screenshot at the bottom right.
          </ListItem>
          <ListItem>
            <Box my={4} boxSize="full">
              <Image
                src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr_fws_1.png"
                style={{ width: "100%" }}
              />
            </Box>
          </ListItem>
          <ListItem>
            Generate a token and paste in the "Token" box at the top of this
            window, for period choose 1 year.
          </ListItem>
          <ListItem>
            <Box my={4} boxSize="full">
              <Image
                src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr_fws_2.png"
                style={{ width: "100%" }}
              />
            </Box>
          </ListItem>
          <ListItem>
            For each report "Activity Flex Query" (Trades & Cashflows Query Id)
            and the "Trade Confirmation Flex Query" (Trade Confirmations Query
            Id) you need to add the query id to the appropriate boxes at the top
            of this window.
          </ListItem>
          <ListItem>
            <Box my={4} boxSize="full">
              <Image
                src="https://cdn.wealthbee.io/main/brokers/instructions/ibkr/ibkr_fws_3.png"
                style={{ width: "100%" }}
              />
            </Box>
          </ListItem>
          <ListItem>
            You can click test to validate you've connected correctly. Save the
            configuration and you are done.
          </ListItem>
        </OrderedList>
      </Flex>
    </Flex>
  );
};
