import { APIEndpoint } from ".";

export const GetGoogleOAuthLogin = async (code: string, scope: string) => {
  return fetch(
    APIEndpoint +
      "/users/auth/google/callback?code=" +
      code +
      "&scope=" +
      scope,
    {
      method: "GET",
    }
  )
    .then((res) => res.json())
    .then((res) => {
      if (res.success === true) {
        return res;
      } else {
        return res;
      }
    })
    .catch((e) => {
      return { success: false, title: "Server error", message: e.message };
    });
};
