import { HStack, Text } from "@chakra-ui/react";
import React from "react";
import { FaObjectGroup } from "react-icons/fa";

export const PositionGroupRender = (params: any) => {
  const positionName =
    params.node.aggData.positionName ?? params.node.aggData.positionGroup;
  return (
    <HStack>
      {!positionName ? (
        <>
          <Text color="var(--red)">
            <FaObjectGroup />
          </Text>
          <Text fontWeight={500} color="var(--red)">
            No position group ({params.node.allChildrenCount})
          </Text>
        </>
      ) : (
        <>
          <Text>
            <FaObjectGroup />
          </Text>
          <Text fontWeight={500}>
            {positionName}({params.node.allChildrenCount})
          </Text>
        </>
      )}
    </HStack>
  );
};
