import {
  Button,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Spacer,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef } from "react";
import { Instrument } from "../../../../types";
import { usePricingToolController } from "./usePricingToolController";
import moment from "moment";

interface PricingToolProps {
  instrument?: Instrument;
}

export const PricingTool = (props: PricingToolProps) => {
  const underlyingPriceRef = useRef<HTMLInputElement>(null);
  const volatilityRef = useRef<HTMLInputElement>(null);
  const strikeRef = useRef<HTMLInputElement>(null);
  const expiryRef = useRef<HTMLInputElement>(null);
  const putRef = useRef<HTMLInputElement>(null);
  const callRef = useRef<HTMLInputElement>(null);
  const marketPriceRef = useRef<HTMLInputElement>(null);

  const controller = usePricingToolController(props.instrument);

  const calc = () => {
    if (
      !underlyingPriceRef.current ||
      !marketPriceRef.current ||
      !strikeRef.current ||
      !expiryRef.current ||
      !volatilityRef.current ||
      !putRef.current ||
      !callRef.current
    )
      return;

    controller.calc(
      parseFloat(underlyingPriceRef.current!.value!),
      parseFloat(marketPriceRef.current!.value! as any),
      parseFloat(strikeRef.current!.value!),
      new Date(expiryRef.current!.value),
      parseFloat(volatilityRef.current!.value!),
      callRef.current.checked ? "Call" : "Put"
    );
  };

  useEffect(() => {
    if (props.instrument) {
      volatilityRef.current!.value =
        props.instrument.volatility?.toString() ?? "0";
      strikeRef.current!.value = props.instrument.strike?.toString() ?? "0";
      expiryRef.current!.value = props.instrument.expiry
        ? moment(props.instrument.expiry).format("YYYY-MM-DD")
        : "";
    }
  }, [props.instrument]);

  return (
    <Flex h="100%" w="100%" direction="column" ml={1} px={2} py={1}>
      <HStack py={2}>
        <Text>Pricing Tool</Text>
      </HStack>

      <VStack my={2} spacing={4}>
        <HStack spacing={4} px={4} w="100%">
          <InputGroup size="sm">
            <InputLeftAddon w="150px">Underlying Price</InputLeftAddon>
            <Input type="number" ref={underlyingPriceRef} size="sm" w="150px" />
          </InputGroup>

          <InputGroup size="sm">
            <InputLeftAddon w="150px">Market Price</InputLeftAddon>
            <Input type="number" ref={marketPriceRef} size="sm" w="150px" />
          </InputGroup>
        </HStack>

        <HStack spacing={4} px={4} w="100%">
          <InputGroup size="sm">
            <InputLeftAddon w="150px">Strike</InputLeftAddon>
            <Input type="number" ref={strikeRef} size="sm" w="150px" />
          </InputGroup>

          <InputGroup size="sm">
            <InputLeftAddon w="150px">Expiry</InputLeftAddon>
            <Input type="date" ref={expiryRef} size="sm" w="150px" />
          </InputGroup>
        </HStack>

        <HStack spacing={1} px={4} w="100%">
          <InputGroup size="sm">
            <InputLeftAddon w="150px">Historical Vol</InputLeftAddon>
            <Input type="number" ref={volatilityRef} size="sm" w="150px" />
          </InputGroup>

          <RadioGroup size="sm">
            <HStack>
              <Radio ref={putRef} value="Put" w="100px" pl="20px">
                Put
              </Radio>
              <Radio ref={callRef} value="Call" w="100px" pl="20px">
                Call
              </Radio>
            </HStack>
          </RadioGroup>

          <Spacer />
        </HStack>

        <HStack py={2}>
          <Spacer />
          <Button
            colorScheme="green"
            size="sm"
            width="150px"
            onClick={() => {
              calc();
            }}
          >
            Calc
          </Button>
        </HStack>

        <HStack spacing={4} px={4} w="100%">
          <VStack spacing={4} px={4}>
            <Text fontSize="14px" w="100%" textAlign="left">
              Theoretical Price (Historical Volatility)
            </Text>
            <Flex
              h="32px"
              w="275px"
              py={1}
              px="12px"
              border="1px solid"
              borderColor="gray.600"
              fontSize="14px"
            >
              {controller.pricingResult?.theoreticalPrice}
            </Flex>
          </VStack>

          <VStack spacing={4} px={4}>
            <Text fontSize="14px" w="100%" textAlign="left">
              Implied Vol (Market Price)
            </Text>
            <Flex
              h="32px"
              w="275px"
              py={1}
              px="12px"
              border="1px solid"
              borderColor="gray.600"
              fontSize="14px"
            >
              {controller.pricingResult?.impliedVolatility}
            </Flex>
          </VStack>
        </HStack>
      </VStack>
    </Flex>
  );
};
