import React from "react";
import { TooltipWithBounds } from "@vx/tooltip";
import { Flex, Text, VStack } from "@chakra-ui/react";
import { CustomToolTip } from "../../../../../../components/design_library";
import currencyFormatter from "currency-formatter";

type TooltipProps = {
  tooltipData: {
    date: string;
    key: string;
    value: string;
  };
  tooltipLeft: number;
  tooltipTop: number;
};

export const Tooltip = ({
  tickFormat,
  tooltipData,
  tooltipLeft,
  tooltipTop,
}: TooltipProps) => (
  <CustomToolTip key={Math.random()} top={tooltipTop} left={tooltipLeft}>
    <Text>
      {tickFormat(tooltipData.domain)}{" "}
      {currencyFormatter.format(tooltipData.value, {
        code: "USD",
        0: "",
      })}
    </Text>
  </CustomToolTip>
);

interface NewTooltipProps {
  label: string;
  value: number;
  domain: string;
}

export const NewTooltip = (props: NewTooltipProps) => {
  return (
    <VStack>
      <Text fontWeight={600}>{props.domain}</Text>
      <Text>{props.label}</Text>
      <Text>
        {currencyFormatter.format(props.value, {
          code: "USD",
          0: "",
        })}
      </Text>
    </VStack>
  );
};
