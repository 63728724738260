import React, { useEffect, useRef } from "react";
import { Stack, Text } from "@chakra-ui/react";
import { Required } from "./Required";

import { CreatableSelect, Select } from "chakra-react-select";
import { Show } from "../../misc";
export const MultiSelect = (props: {
  label: string;
  value: string;
  field: string;
  required: boolean;
  enums: string[];
  error: string;
  enableCreateNew?: boolean;
  api?: () => Promise<any>;
  onChange: (field: string, value: string) => void;
}) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [options, setOptions] = React.useState([] as any[]);
  const initialised = useRef<boolean>(false);

  useEffect(() => {
    if (props.enums) {
      setOptions(
        props.enums.map((x) => {
          return {
            value: x,
            label: x,
          };
        })
      );
    }
  }, [props.enums]);

  useEffect(() => {
    if (initialised.current === false) {
      initialised.current = true;

      if (props.api) {
        setIsLoading(true);
        props.api().then((data) => {
          setOptions(data);
        });
      } else {
        setIsLoading(false);
      }
    }

    if (options.length > 0) setIsLoading(false);
  }, [props.api, options]);

  if (isLoading === true) return <div>Loading...</div>;

  return (
    <Stack spacing="4" mt="2" mb="1">
      <Show if={props.label}>
        <Text>
          {props.label}
          {props.required && <Required />}
        </Text>
      </Show>
      {props.enableCreateNew === true && (
        <CreatableSelect
          tagVariant="solid"
          value={
            props.value
              ? options.find((x) => x.value === props.value)
              : undefined
          }
          options={options}
          onChange={(e) => props.onChange(props.field, e.value)}
        />
      )}
      {!props.enableCreateNew === true && (
        <Select
          tagVariant="solid"
          value={
            props.value
              ? options.find((x) => x.value === props.value)
              : undefined
          }
          options={options}
          onChange={(e) => {
            props.onChange(props.field, e.value);
          }}
        />
      )}
      <Text color="red.500">{props.error}</Text>
    </Stack>
  );
};
