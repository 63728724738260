import React, { useState } from "react";
import { Flex, Image, Link, VStack } from "@chakra-ui/react";
import { FileUploader } from "react-drag-drop-files";
import { uploadImage } from "../../../api";

export default function Images() {
  const [file, setFile] = useState(null);
  const [url, setUrl] = useState("");
  const handleChange = async (file: any) => {
    setFile(file);
    const result = await uploadImage(file);
    setUrl(result.directory);
  };

  return (
    <VStack bg="var(--bg)" pt="20px" h="100%">
      <FileUploader handleChange={handleChange} name="file" />
      <Link href={url} target="_blank" rel="noreferrer" mt="20px">
        {url}
      </Link>
      <Image src={url} maxW="1200px" mt="40px" />
    </VStack>
  );
}
