import { ColDef } from "ag-grid-community";
import { ColumnTypeEnum, createGroupColumn } from "../../../components";

export const columnDefinitions: ColDef[] = [
  createGroupColumn("Namespace", "namespace", ColumnTypeEnum.Text, {
    rowGroup: true,
    sort: "asc",
    hide: true,
  }),
  createGroupColumn("Key", "key", ColumnTypeEnum.Text),
  createGroupColumn("Value", "value", ColumnTypeEnum.Text),
];
