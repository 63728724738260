import { createIcon } from "@chakra-ui/react";

const FileUpload = createIcon({
  path: (
    <path
      d="M16 2L21 7V21.008C20.9997 21.2712 20.895 21.5235 20.7088 21.7095C20.5226 21.8955 20.2702 22 20.007 22H3.993C3.73038 21.9982 3.47902 21.8931 3.29322 21.7075C3.10742 21.5219 3.00209 21.2706 3 21.008V2.992C3 2.444 3.445 2 3.993 2H16ZM13 12H16L12 8L8 12H11V16H13V12Z"
      stroke="var(--light-gray)"
      fill="transparent"
    />
  ),
  displayName: "FileUpload",
});

export default FileUpload;
