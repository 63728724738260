import { FileUploader } from "react-drag-drop-files";

import {
  AddIcon,
  ChevronRightIcon,
  SearchIcon,
  WarningIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Input,
  InputRightElement,
  VStack,
  HStack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  InputGroup,
  Flex,
  Divider,
  Image,
} from "@chakra-ui/react";
import SearchItem from "../../components/SearchItem";
import FileUpload from "../../components/FileUploadIcon";
import { TastyWorksInstructions } from "./instructions";
import FidelityInstructions, {
  FidelityEmailForTradeConfirmations,
} from "./instructions/FidelityInstructions";

import { useBrokerUploadController } from "./useBrokerUploadController";
import { GoBack } from "../../components/GoBack";
import {
  SchwabEmailForTradeConfirmations,
  SchwabInstructions,
} from "./instructions/SchwabInstructions";
import InteractiveBrokersInstructions from "./instructions/InteractiveBrokersInstructions";
import { Spinner } from "../../../../components";
import { DropDown } from "../../../../components/design_library";
import { useAtomValue } from "jotai";
import { TransactionsAtom } from "../../../../store";
import { useTour } from "@reactour/tour";
import ETradeInstructions from "./instructions/eTradeInstructions";
import React from "react";
import { AutoSync } from "../../../AutoSync/AutoSync";
import InteractiveBrokersAutoSyncInstructions from "./instructions/InteractiveBrokersAutoSyncInstructions";
import { HiDeviceMobile } from "react-icons/hi";
import { RiEmotionUnhappyFill } from "react-icons/ri";

const fileTypes = ["XLSX", "XLS", "CSV", "TXT"];

const BrokerUpload = (props: any) => {
  const controller = useBrokerUploadController(props);
  const transactionsState = useAtomValue(TransactionsAtom);

  const books = transactionsState.books.map((book: any) => {
    return { value: book, label: book };
  });

  const { isOpen, currentStep, steps, setIsOpen, setCurrentStep, setSteps } =
    useTour();

  const hasTrades = transactionsState.trades.length > 0;

  return (
    <Box className="tutorial-modal-upload" minH="480px">
      <VStack
        alignItems="flex-start"
        justifyContent={"flex-start"}
        mt="10px"
        mb="12px"
      >
        {!controller.selectedBroker && props.goBackButton}

        <HStack justifyContent={"space-between"} w={"100%"}>
          {controller.selectedBroker && (
            <Box pb="20px">
              <GoBack
                onClick={() => {
                  controller.setSelectedBroker("");
                  controller.setErrorMessage("");
                  controller.setSuccessfulUpload(false);
                }}
              >
                Go back to import selection
              </GoBack>
            </Box>
          )}

          {!controller.successfulUpload && (
            <>
              {!controller.loading && (
                <>
                  {controller.selectedBroker && (
                    <Box>
                      <DropDown
                        value={{
                          value: controller.selectedBook,
                          label:
                            controller.selectedBook === "undefined" ||
                            controller.selectedBook === ""
                              ? "No book selected"
                              : controller.selectedBook,
                        }}
                        onChange={(e: any) => {
                          console.log(controller.selectedBook);
                          controller.setSelectedBook(e.value);
                        }}
                        options={[
                          { value: "undefined", label: "No book selected" },
                          ...books,
                        ]}
                        placeholder={"No book selected"}
                      />
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </HStack>
      </VStack>

      {controller.loading && (
        <VStack h="200px" justifyContent={"center"}>
          <Spinner size="xl" />
          <Text fontSize="xl" fontWeight={600} mt="4px">
            Retrieving brokers...
          </Text>
        </VStack>
      )}

      {!controller.successfulUpload && (
        <>
          <HStack wrap={"wrap"}>
            {!controller.loading && (
              <Box w={"3xl"}>
                {controller.selectedBroker && (
                  <Box
                    onClick={() => {
                      controller.setSelectedBroker("");
                    }}
                  >
                    <FileUploader
                      handleChange={(file: any) => {
                        controller.uploadFile(file);
                      }}
                      enctype="multipart/form-data"
                      name="file"
                      types={fileTypes}
                      disableDragAndDrop={true}
                      children={
                        <Box
                          maxW="100%"
                          maxH="100%"
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          flexDir={"column"}
                          className={"drop-zone"}
                          background="var(--dark-gray)"
                          border="4px dashed var(--gray)"
                          borderRadius={"8px"}
                          py="80px"
                          cursor={"pointer"}
                          mb="10px"
                        >
                          {controller.uploading && (
                            <Box
                              display="flex"
                              justifyContent={"center"}
                              alignItems={"center"}
                              flexDir={"column"}
                              mb="10px"
                            >
                              <Spinner size="xl" mb="12px" />
                              <Text>Uploading...</Text>
                            </Box>
                          )}
                          {!controller.uploading && (
                            <>
                              {!controller.errorMessage ? (
                                <FileUpload h="40px" w="40px" />
                              ) : (
                                <WarningIcon
                                  color="var(--red)"
                                  h="40px"
                                  w="40px"
                                />
                              )}
                              <Text fontWeight={600} mt="2">
                                {controller.selectedBroker}
                              </Text>
                              {controller.errorMessage ? (
                                <Text
                                  fontWeight={500}
                                  fontSize={"md"}
                                  color="var(--red)"
                                >
                                  {controller.errorMessage}
                                </Text>
                              ) : (
                                <Text fontWeight={500} fontSize={"sm"}>
                                  Drag & drop file to upload or click on this
                                  box
                                </Text>
                              )}
                              <Text fontWeight={500} fontSize={"xs"} pt="4px">
                                Supported formats: {fileTypes.join(", ")}
                              </Text>
                            </>
                          )}
                        </Box>
                      }
                    />
                  </Box>
                )}

                {!controller.selectedBroker && (
                  <Box pb="36px">
                    <InputGroup>
                      <Input
                        onChange={(e) => {
                          controller.search(e.target.value);
                        }}
                        value={controller.textSearch}
                        placeholder={`Search by broker name`}
                      />
                      <InputRightElement>
                        <SearchIcon />
                      </InputRightElement>
                    </InputGroup>
                    <VStack
                      alignItems={"flex-start"}
                      maxH={"540px"}
                      overflowY="scroll"
                      mt="20px"
                      sx={{
                        html: {
                          "scrollbar-face-color": "var(--bg)",
                          "scrollbar-base-color": "var(--bg)",
                          "scrollbar-3dlight-color": "var(--bg)",
                          "scrollbar-highlight-color": "var(--bg)",
                          "scrollbar-track-color": "blue",
                          "scrollbar-arrow-color": "blue",
                          "scrollbar-shadow-color": "var(--bg)",
                          "scrollbar-dark-shadow-color": "var(--bg)",
                        },

                        "&::-webkit-scrollbar": {
                          width: "0px",
                          height: "0px",
                          display: "none",
                        },
                        "&::-webkit-scrollbar-button": {
                          "background-color": "transparent",
                          display: "none",
                        },
                        "&::-webkit-scrollbar-track": {
                          "background-color": "var(--bg)",
                        },
                        "&::-webkit-scrollbar-track-piece": {
                          "background-color": "var(--gray)",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          height: "50px",
                          "background-color": "#666",
                          "border-radius": "3px",
                        },
                        "&::-webkit-scrollbar-corner": {
                          "background-color": "var(--bg)",
                        },
                        "&::-webkit-resizer": {
                          "background-color": "#666",
                        },
                      }}
                    >
                      {controller.textSearch.length > 0 &&
                        controller.filteredResults.map((item: any) => {
                          return (
                            <SearchItem
                              item={item}
                              setSelectedbroker={controller.setSelectedBroker}
                            />
                          );
                        })}

                      {controller.textSearch.length === 0 &&
                        controller.dataList.map((item: any) => {
                          return (
                            <SearchItem
                              item={item}
                              setSelectedbroker={controller.setSelectedBroker}
                            />
                          );
                        })}
                      {controller.textSearch.length > 0 &&
                        controller.filteredResults.length === 0 && (
                          <Box
                            className={"uploadAddTradeSearchRow"}
                            width="100%"
                            background="var(--dark-gray)"
                            border="1px solid var(--gray)"
                            borderRadius={"8px"}
                            padding="12px"
                          >
                            <HStack justifyContent={"space-between"}>
                              <HStack>
                                <Box onClick={() => {}}>
                                  <Text fontSize="20px" fontWeight={500}>
                                    We haven't found that broker.
                                  </Text>
                                  <Text
                                    fontSize="17px"
                                    color="var(--semi-white)"
                                    fontWeight={400}
                                  >
                                    Please message support and we will add
                                    support.
                                  </Text>
                                </Box>
                              </HStack>
                            </HStack>
                          </Box>
                        )}

                      <Box
                        width="100%"
                        background="var(--dark-gray)"
                        border="1px solid var(--gray)"
                        borderRadius={"8px"}
                        padding="12px"
                        onClick={() => {
                          window.$crisp.push(["do", "chat:open"]);
                          window.$crisp.push([
                            "do",
                            "message:send",
                            [
                              "text",
                              `Hi! I'd like to get help with uploading my trades! `,
                            ],
                          ]);
                        }}
                        cursor={"pointer"}
                      >
                        <HStack justifyContent={"space-between"}>
                          <HStack>
                            <SearchIcon w="40px" h="40px" />
                            <Box>
                              <Text fontSize="20px" fontWeight={500}>
                                Don't see your broker?
                              </Text>
                              <Text
                                fontSize="17px"
                                color="var(--semi-white)"
                                fontWeight={400}
                              >
                                Click here to request its addition.
                              </Text>
                            </Box>
                          </HStack>
                          <ChevronRightIcon w="40px" h="40px" />
                        </HStack>
                      </Box>
                    </VStack>
                  </Box>
                )}
              </Box>
            )}

            {controller.selectedBroker === "TASTYWORKS" && (
              <TastyWorksInstructions />
            )}
            {controller.selectedBroker === "FIDELITY" && (
              <Box>
                <Tabs isFitted>
                  <TabList>
                    <Tab>Historic Uploads</Tab>
                    <Tab>Automatic Updates</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <FidelityInstructions />
                    </TabPanel>
                    <TabPanel>
                      <FidelityEmailForTradeConfirmations />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            )}
            {controller.selectedBroker === "SCHWAB" && (
              <Box>
                <Tabs isFitted>
                  <TabList>
                    <Tab>Historic Uploads</Tab>
                    <Tab>Automatic Updates</Tab>
                  </TabList>

                  <TabPanels>
                    <TabPanel>
                      <SchwabInstructions />
                    </TabPanel>
                    <TabPanel>
                      <SchwabEmailForTradeConfirmations />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              </Box>
            )}
            {controller.selectedBroker === "INTERACTIVEBROKERS" && (
              <Tabs w="full">
                <TabList>
                  <Tab>Historic Uploads</Tab>
                  <Tab>Auto Sync</Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <InteractiveBrokersInstructions />
                  </TabPanel>
                  <TabPanel>
                    <Flex w="full" direction="column">
                      <AutoSync />
                      <Divider my={4} />
                      <InteractiveBrokersAutoSyncInstructions />
                    </Flex>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
            {controller.selectedBroker === "ETRADE" && <ETradeInstructions />}
          </HStack>
        </>
      )}
    </Box>
  );
};

export default BrokerUpload;
