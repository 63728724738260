const convertSymbolToReadable = (symbol: string) => {
  try {
    const parts = symbol.split("_");
    const underlyingSymbol = parts[0];
    const expiry = parts[1];
    const strike = parts[2];
    const putCall = parts[3];

    if (!expiry || !strike || !putCall) {
      return symbol;
    } else {
      return `${underlyingSymbol} ${expiry.substring(4, 6)}/${expiry.substring(
        6
      )}/${expiry.substring(0, 4)} ${parseFloat(strike) / 100} ${putCall}`;
    }
  } catch (e) {
    return symbol;
  }
};

export default convertSymbolToReadable;
