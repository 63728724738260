import {
  Box,
  Center,
  Divider,
  HStack,
  Heading,
  Image,
  Switch,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useAtomValue } from "jotai";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { AccountAtom } from "../../store";
import { getConfig, updateConfig } from "../../api";
import { ConfigItem } from "../../types";

export function MaintenanceMode() {
  const timeOfMaintenance = new Date("2024-06-06T11:37:59Z");
  const backOnline = new Date("2024-06-06T11:59:00Z");
  const account = useAtomValue(AccountAtom);

  const [displayed, setDisplayed] = useState<boolean>(false);

  useEffect(() => {
    if (window.location.search.includes("maintenance")) {
      setDisplayed(false);
    } else {
      checkConfig();
    }
  }, []);

  const checkConfig = async () => {
    if (account.selectedAccount) {
      const result = await getConfig(account.selectedAccount.id);
      result.filter((item: ConfigItem) => {
        if (item.key === "maintenance_mode") {
          setDisplayed(item.value === "true");
        }
      });
    }
  };

  if (!displayed) {
    return null;
  }

  return (
    <Box
      bg="var(--bg)"
      borderRadius={8}
      color="white"
      textAlign="center"
      position={"fixed"}
      top={"0"}
      left="0"
      w={"100vw"}
      h={"100vh"}
      zIndex={9999999}
    >
      <Center mt="100px">
        <VStack justifyContent={"center"}>
          <Image
            src="https://cdn.wealthbee.io/main/logos/wealthbee-logo-with-text.png"
            alt="Logo"
            w="100px"
            h="100px"
          />
          <Heading>Maintenance Mode</Heading>
          <Text>
            We're upgrading our systems and will be back online shortly!
          </Text>
          {/* <Text mt="40px">
            Started at {moment.utc(timeOfMaintenance).calendar()} UTC
          </Text>
          <Text>We expect online {moment.utc(backOnline).calendar()} UTC</Text>
      */}
        </VStack>
      </Center>
    </Box>
  );
}

export const MaintenanceModeToggleButton = () => {
  const account = useAtomValue(AccountAtom);
  const [maintenaceModeEnabled, setMaintenanceModeEnabled] =
    useState<boolean>(false);

  const checkConfig = async () => {
    if (account.selectedAccount) {
      const result = await getConfig(account.selectedAccount.id);
      result.filter((item: ConfigItem) => {
        if (item.key === "maintenance_mode") {
          if (item.value === "true") {
            setMaintenanceModeEnabled(true);
          } else {
            setMaintenanceModeEnabled(false);
          }
        }
      });
    }
  };

  const toggleMaintenanceMode = async () => {
    const config = await updateConfig(
      {
        namespace: "website",
        key: "maintenance_mode",
        value: maintenaceModeEnabled ? "false" : "true",
      },
      account.selectedAccount.id
    );

    setMaintenanceModeEnabled(!maintenaceModeEnabled);
  };

  useEffect(() => {
    checkConfig();
  }, []);

  return (
    <HStack>
      <Switch
        isChecked={maintenaceModeEnabled}
        onChange={toggleMaintenanceMode}
      />
      <Text>Maintenance Mode</Text>
      <Divider orientation="vertical" h="20px" />
      <Text maxW="6xl" pl="20px">
        To view the site while maintenace mode is enabled at{" "}
        <a target="#" href="?maintenance" style={{ color: "var(--accent)" }}>
          ?maintenance
        </a>{" "}
        to the end of your URL
      </Text>
    </HStack>
  );
};
