import React, { useState, useEffect } from "react";
import { VStack, HStack, Text } from "@chakra-ui/react";
import styles from "./InsightNumber.module.css";
import { InsightNumberProps } from "../../../../../../types/Insight";

const InsightNumber = ({
  data,
  demo,
  title,
}: {
  data: InsightNumberProps;
  demo?: boolean;
  title: string;
}) => {
  if (data === undefined) return null;
  const {
    prefix,
    value,
    suffix,
    change,
    changeSuffix,
    decimals,
  }: InsightNumberProps =
    demo === true
      ? {
          prefix: "$",
          value: 123456,
          suffix: "",
          change: 1234,
          changeSuffix: "",
          decimals: 2,
        }
      : data;

  const [displayedValue, setDisplayedValue] = useState(value);
  const [displayedChange, setDisplayedChange] = useState(change);
  const [flashClass, setFlashClass] = useState("");

  useEffect(() => {
    const valueInterval = setInterval(() => {
      setDisplayedValue((prev) => {
        const difference = value - prev;
        if (Math.abs(difference) < 0.01) {
          clearInterval(valueInterval);
          return value;
        }
        return prev + difference / 10;
      });
    }, 1);

    return () => clearInterval(valueInterval);
  }, [value]);

  useEffect(() => {
    const changeInterval = setInterval(() => {
      setDisplayedChange((prev) => {
        const difference = value - prev;
        if (Math.abs(difference) < 0.01) {
          clearInterval(changeInterval);
          return change;
        }
        return prev + difference / 10;
      });
    }, 1);

    return () => clearInterval(changeInterval);
  }, [value]);

  useEffect(() => {
    if (value > 0) {
      setFlashClass(styles.flashGreen);
    } else if (value < 0) {
      setFlashClass(styles.flashRed);
    } else {
      setFlashClass("");
    }
    const timer = setTimeout(() => {
      setFlashClass("");
    }, 1000);

    return () => clearTimeout(timer);
  }, [value]);

  let formattedValue = displayedValue.toFixed(decimals);
  formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  let updatedPrefix = prefix;
  if (value < 0) {
    formattedValue = formattedValue.substring(1);
    updatedPrefix = "-" + prefix;
  }

  return (
    <VStack spacing="0" h="70%" justifyContent={"center"}>
      <Text
        className={`${styles.statNumber} ${flashClass}`}
        color={
          value > 0 ? "var(--green)" : value < 0 ? "var(--red)" : "gray.500"
        }
      >
        {updatedPrefix}
        {formattedValue}
        {suffix}
      </Text>
      <Text> {title}</Text>
    </VStack>
  );
};

export default InsightNumber;
