import { IBKRAutoSyncConfig } from "./IBKR_AutoSyncConfig";
import { Brokers, SyncSetting } from "../../../../types";
import { useEffect, useMemo, useState } from "react";
import { AccountAtom } from "../../../../store";
import { useAtomValue } from "jotai";
import { addOrUpdateSyncSetting, testSyncSetting } from "../../../../api";
import { useNotifications } from "../../../../hooks/useNotifications";

export interface IBKRAutoSyncControllerProps {
  settings?: SyncSetting;
  close?: () => void;
  readOnly?: boolean;
}

interface IBKRAutoSyncControllerResponse {
  readOnly?: boolean;
  config: IBKRAutoSyncConfig;
  setConfig: (config: IBKRAutoSyncConfig) => void;
  validToken: boolean;
  validQueryId: boolean;
  saveConfig: () => void;
  testConfig: () => void;
}

export const useIBKRAutoSyncController = (
  props: IBKRAutoSyncControllerProps
): IBKRAutoSyncControllerResponse => {
  const notifications = useNotifications();
  const accountState = useAtomValue(AccountAtom);
  const [config, setConfig] = useState<IBKRAutoSyncConfig>({});

  const validToken = useMemo(() => {
    if (!config.token) return false;
    return true;
  }, [config.token]);

  const validQueryId = useMemo(() => {
    if (!config.tradesAndCashflowsQueryId && !config.tradeConfirmationsQueryId)
      return false;
    return true;
  }, [config.tradeConfirmationsQueryId, config.tradesAndCashflowsQueryId]);

  const saveConfig = async () => {
    if (accountState.selectedAccount?.id) {
      const payload: any = {
        accountId: accountState.selectedAccount?.id,
        broker: Brokers.IBKR,
        name: "IBKR Auto Sync Settings",
        settings: JSON.stringify(config),
      };
      if (props.settings && props.settings.syncSettingId) {
        payload.syncSettingId = props.settings.syncSettingId;
      }

      try {
        await addOrUpdateSyncSetting(
          payload as SyncSetting,
          accountState.selectedAccount?.id
        );
        notifications.createNotification("Auto Sync Settings", "Saved");
      } catch (error) {
        notifications.showErrorMessage("Auto Sync Settings", error as any);
      }

      if (props.close) {
        props.close();
      }
    }
  };

  const testConfig = async () => {
    if (accountState.selectedAccount?.id) {
      const payload: any = {
        accountId: accountState.selectedAccount?.id,
        broker: Brokers.IBKR,
        name: "IBKR Auto Sync Settings",
        settings: JSON.stringify(config),
      };
      if (props.settings && props.settings.syncSettingId) {
        payload.syncSettingId = props.settings.syncSettingId;
      }

      try {
        const result = await testSyncSetting(
          payload as SyncSetting,
          accountState.selectedAccount?.id
        );
        if (result) {
          notifications.createNotification(
            "Auto Sync Settings - Valid",
            "Successfully Tested"
          );
        } else {
          notifications.createNotification(
            "Auto Sync Settings - Failed",
            "Unable to verify settings"
          );
        }
      } catch (error) {
        notifications.showErrorMessage("Auto Sync Settings", error as any);
      }
    }
  };

  useEffect(() => {
    if (props.settings?.settings) {
      setConfig(JSON.parse(props.settings.settings));
    }
  }, [props.settings]);

  return {
    readOnly: props.readOnly,
    config,
    setConfig,
    validToken,
    validQueryId,
    saveConfig,
    testConfig,
  };
};
