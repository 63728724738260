import React from "react";
import { HStack, Text, VStack } from "@chakra-ui/react";

// !BP
import { ChevronDown, ChevronUp } from "../../design_library";

import currencyFormatter from "currency-formatter";

export const CurrencyWithPercentage = (props: any) => {
  if (props.value === undefined) return null;
  if (props.value === 0) return null;

  const precision = props.precision ? props.precison : 2;
  if (props.value < 0) {
    return (
      <HStack>
        {props.showDirection && (
          <ChevronDown
            backgroundColor={"transparent"}
            fill="var(--red)"
            strokeWidth={"1.5px"}
            stroke="var(--red)"
          />
        )}
        <Text color="var(--red)">
          {currencyFormatter.format(props.value, {
            code: "USD",
            precision,
          })}
        </Text>
        {props.node.aggData && props?.node.aggData[props.percentageField] && (
          <Percentage value={-props.node.aggData[props.percentageField]} />
        )}
        {props.data && props?.data[props.percentageField] && (
          <Percentage value={-props.data[props.percentageField]} />
        )}
      </HStack>
    );
  } else {
    return (
      <HStack>
        {props.showDirection && (
          <ChevronUp
            backgroundColor={"transparent"}
            fill="var(--green)"
            strokeWidth={"1.5px"}
            stroke="var(--green)"
          />
        )}
        <VStack alignItems="flex-start">
          <Text color="var(--green)">
            {" "}
            {currencyFormatter.format(props.value, {
              code: "USD",
              precision,
            })}
          </Text>
        </VStack>
        {props.node.aggData && props?.node.aggData[props.percentageField] && (
          <Percentage
            value={Math.abs(props.node.aggData[props.percentageField])}
          />
        )}
        {props.data && props?.data[props.percentageField] && (
          <Percentage value={Math.abs(props.data[props.percentageField])} />
        )}
      </HStack>
    );
  }
};

const Percentage = (props: any) => {
  if (props.value > 0) {
    return (
      <Text color="var(--green)">({parseFloat(props.value).toFixed(0)}%)</Text>
    );
  } else {
    return (
      <Text color="var(--red)">({parseFloat(props.value).toFixed(0)}%)</Text>
    );
  }
};
