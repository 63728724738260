import { Button } from "@chakra-ui/react";
import styles from "./index.module.css";
import "./style.css";

export const PrimaryButton = (props: any) => {
  let overrideStyleString = `${styles.button} ${styles.primaryButton}`;

  return (
    <Button className={overrideStyleString} {...props}>
      {props.children}
    </Button>
  );
};
