import React, { useEffect, useState } from "react";
import {
  getListOfFiles,
  getSpecificFile,
  markUploadAsReviewed,
} from "../../../api";
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Center,
} from "@chakra-ui/react";
import moment from "moment";
import fileDownload from "js-file-download";
import { Spinner } from "../../../components";

export default function Files() {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<string>(null);

  const getFiles = async () => {
    setLoading(true);
    const fullListOfFiles = await getListOfFiles();
    setFiles(fullListOfFiles.files);
    setLoading(false);
  };

  const getSpecificFileFunc = async (id: string) => {
    setLoading(true);
    const specificFile = await getSpecificFile(id);
    setSelectedFile(specificFile);

    fileDownload(
      specificFile.file.csvContent,
      `${specificFile.file.broker}-${specificFile.file.userId}-${specificFile.file.createdAt}.csv`
    );

    setLoading(false);
  };

  useEffect(() => {
    getFiles();
  }, []);

  const markFileAsReviewed = async (id: string) => {
    setLoading(true);
    await markUploadAsReviewed(id);
    getFiles();
    setLoading(false);
  };

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <Box bg="var(--dark-gray)" p={4} borderRadius={8}>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Broker</Th>
            <Th>User Email</Th>
            <Th>User ID</Th>
            <Th>Uploaded At</Th>
            <Th>Download</Th>
            <Td>Status</Td>
            <Td>Message</Td>
          </Tr>
        </Thead>
        <Tbody>
          {files.map((file) => (
            <Tr key={file.id}>
              <Td>{file.broker}</Td>
              <Td>{file.userEmail}</Td>
              <Td>{file.id}</Td>
              <Td>{moment(file.createdAt).calendar()}</Td>
              <Td>
                <Button
                  onClick={() => getSpecificFileFunc(file.id)}
                  colorScheme="blue"
                  size="xs"
                >
                  Download
                </Button>
              </Td>
              <Td>
                <Button
                  size="xs"
                  colorScheme={file.status === "REVIEWED" ? "green" : "red"}
                  onClick={() => markFileAsReviewed(file.id)}
                >
                  {file.status}
                </Button>
              </Td>
              <Td>{file.adminReviewMessage}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
}
