export const distinctValue = (props: any) => {
  if (props.colDef?.type === "text" || props.colDef?.type === "dateColumn") {
    if (props.values && props.values.length > 0) {
      let value = props.values[0] ?? "";

      const commonValues = Array.from(new Set(props.values));

      // idea here is that if all values are the same, return that value
      // else if there are multiple different values, return "*"
      if (commonValues.length === 1) {
        return commonValues[0];
      } else if (commonValues.length > 1) {
        return "*";
      }

      // if (props.values.includes("1-1-2")) {
      //   console.log("1-1-2");
      // }

      // for (let i = 1; i < props.values.length; i++) {
      //   const current = props.values[i] ?? "";
      //   // get first non blank value
      //   if (current !== "" && value === "") {
      //     value = current;
      //   }

      //   if (current !== "" && current !== value) {
      //     return "";
      //   }
      // }
      // return value;
    }
  }
  return "";
};
