import { ChevronLeftIcon } from "@chakra-ui/icons";
import { HStack, Text } from "@chakra-ui/react";

export const GoBack = (props: any) => {
  return (
    <HStack cursor="pointer" onClick={props.onClick}>
      <ChevronLeftIcon w="28px" h="28px" />
      <Text fontWeight={500} fontSize="14px" color="var(--light-gray)">
        {props.children}
      </Text>
    </HStack>
  );
};
