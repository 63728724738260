import {
  Box,
  Center,
  Stack,
  HStack,
  Badge,
  Avatar,
  Text,
  List,
  ListItem,
  Heading,
} from "@chakra-ui/react";
import NavBar from "../homepage/menu/App";
import Footer from "../homepage/footer/App";
import { Helmet } from "react-helmet";
import { features } from "./data";
import { useEffect, useState } from "react";

export const Roadmap = () => {
  const [featureList, setFeatureList] = useState([]);
  const [completedFeatures, setCompletedFeatures] = useState([]);
  const [toDoList, setToDoList] = useState([]);

  useEffect(() => {
    // Sort feature list by date, split into 2 lists based on status
    const sortedFeatures = features.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
    const completedFeatures = sortedFeatures.filter(
      (feature) => feature.status === "Completed"
    );
    const inProgressFeatures = sortedFeatures.filter(
      (feature) => feature.status === "In Progress"
    );
    const toDoListFeatures = sortedFeatures.filter(
      (feature) => feature.status === "To Do"
    );

    setCompletedFeatures(completedFeatures);
    setFeatureList(inProgressFeatures);
    setToDoList(toDoListFeatures);
  }, []);

  return (
    <Box background={"var(--bg)"}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Roadmap</title>
        <link rel="canonical" href="https://wealthbee.io/roadmap" />
      </Helmet>
      <NavBar />
      <Center
        maxW="lg"
        mx="auto"
        minH={"89vh"}
        alignItems={"flex-start"}
        mt="20px"
      >
        <Stack spacing="5" flex="1" px="20px">
          <Stack spacing="1">
            <Heading as="h1" size="lg" color="fg.emphasized">
              Roadmap
            </Heading>
            <Text color="fg.muted" textStyle="sm">
              Here are some of the things that will be added in future versions
            </Text>
          </Stack>
          <List listStyleType="none">
            <Stack spacing="3" width="full">
              {featureList.map((feature) => (
                <ListItemCustom issue={feature} />
              ))}
              {toDoList.map((issue) => (
                <ListItemCustom issue={issue} />
              ))}
              {completedFeatures.map((issue) => (
                <ListItemCustom issue={issue} />
              ))}
            </Stack>
          </List>
        </Stack>
      </Center>
      <Footer />
    </Box>
  );
};

const ListItemCustom = ({ issue }) => {
  return (
    <ListItem
      key={issue.id}
      value={issue.id}
      bg="var(--dark-gray)"
      p="4"
      boxShadow="sm"
      position="relative"
      borderRadius="lg"
    >
      <Stack shouldWrapChildren spacing="4">
        <Text textStyle="sm" fontWeight="medium" color="fg.emphasized">
          {issue.title}
        </Text>
        <Text textStyle="xs" color="fg.subtle" fontWeight={300}>
          {issue.description}
        </Text>
        <HStack justify="space-between">
          <HStack>
            <Badge
              colorScheme={
                issue.type === "Epic"
                  ? "purple"
                  : issue.type === "Task"
                  ? "blue"
                  : "red"
              }
              size="sm"
            >
              {issue.type}
            </Badge>
            <Badge
              colorScheme={
                issue.status === "Completed"
                  ? "green"
                  : issue.status === "In Progress"
                  ? "yellow"
                  : "red"
              }
              size="sm"
            >
              {issue.status}
            </Badge>
          </HStack>
          {/* <HStack spacing="3">
            <Text textStyle="xs" color="fg.subtle" fontWeight="medium">
              {issue.id}
            </Text>
          </HStack> */}
        </HStack>
      </Stack>
    </ListItem>
  );
};
