import React from "react";
import { Stack, Text, Textarea } from "@chakra-ui/react";
import { Required } from "./Required";

export const TextareaInput = (props: {
  label: string;
  value: string;
  field: string;
  required: boolean;
  error: string;
  onChange: (field: string, value: string) => void;
}) => {
  return (
    <Stack spacing="4" mt="2" mb="1">
      <Text>
        {props.label}
        {props.required && <Required />}
      </Text>
      <Textarea
        required={props.required}
        value={props.value}
        onChange={(e) => props.onChange(props.field, e.target.value)}
      />
      <Text color="red.500">{props.error}</Text>
    </Stack>
  );
};
