import { atom } from "jotai";

export interface DashboardLayoutState {
  layouts: {};
  breakpoints: string[];
  currentBreakpoint: string;
  gridLoading: boolean;
  enabledInsights: any[];
}

const buildInitialState = (): DashboardLayoutState => {
  const state: any = {
    layouts: {
      lg: [],
      md: [],
      sm: [],
      xs: [],
      xxs: [],
      defaultState: true,
    },
    breakpoints: ["lg", "md", "sm", "xs", "xxs"],
    currentBreakpoint: "lg",
    gridLoading: true,
    enabledInsights: [],
  };

  return state;
};

export const DashboardLayoutStateStore = atom<DashboardLayoutState>(
  buildInitialState()
);
