import { useState } from "react";
import { useAtom } from "jotai";
import { AppStateAtom } from "../../../store";
import { resetPassword } from "../../../api";
import { useToast } from "@chakra-ui/react";
import { useUser } from "../../../hooks/useUser";
import mixpanel from "mixpanel-browser";
import { useNotifications } from "../../../hooks/useNotifications";
import { MdEmail } from "react-icons/md";

interface ResetPasswordController {
  email: string;
  setEmail: (email: string) => void;
  reset: () => void;
  loading: boolean;
}

export const useResetPasswordController = () => {
  const [appState, setAppState] = useAtom(AppStateAtom);
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const toast = useToast();
  const user = useUser();
  const notifications = useNotifications();

  const performReset = async () => {
    mixpanel.track("Reset Password Attempt", {
      email: email,
    });
    console.log("Resetting password with email: ", email);
    setLoading(true);

    user.setLoading(true);
    console.log(`Reseting with email: ${email}`);
    setTimeout(() => {
      resetPassword(email)
        .then((res) => {
          mixpanel.track("Reset Password Success", {
            email: email,
            success: res.success,
          });
          setLoading(false);

          notifications.createNotification(
            "Password Reset",
            "Check your email for the new password"
          );

          setTimeout(() => {
            notifications.clearNotifications("");
          }, 5000);
        })
        .catch((e: any) => {
          mixpanel.track("Reset Password Failure", {
            email: email,
          });

          console.log("API Call, error call reset", e);
          user.logUserOut();
          setLoading(false);
        });
    }, 200);
  };

  return {
    email,
    setEmail,
    loading,
    reset: performReset,
  } as ResetPasswordController;
};
