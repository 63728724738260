import { useState } from "react";

interface UploadsController {
  setDisplayedPanel: (value: string) => void;
  setIsOpen: (value: boolean) => void;
  displayedPanel: string;
  isOpen: boolean;
}

export const useUploadsController = () => {
  const [displayedPanel, setDisplayedPanel] = useState(""); // broker upload
  const [isOpen, setIsOpen] = useState(false);

  return {
    displayedPanel,
    setDisplayedPanel,
    isOpen,
    setIsOpen,
  } as UploadsController;
};
