import { useEffect, useRef, useState } from "react";
import { useAtomValue } from "jotai";
import { ConfigItem } from "../../../types";
import { getConfig, updateConfig } from "../../../api";
import { AccountAtom } from "../../../store";

interface UseConfigController {
  items: ConfigItem[];
  updateConfigItem: (configItem: ConfigItem) => void;
}

export const useConfigController = (): UseConfigController => {
  const initialised = useRef(false);
  const [configItems, setConfigItems] = useState<ConfigItem[]>([]);

  const account = useAtomValue(AccountAtom);

  const loadConfig = async () => {
    if (account.selectedAccount) {
      const result = await getConfig(account.selectedAccount.id);
      setConfigItems(result);
    }
  };

  const updateConfigItem = async (configItem: ConfigItem) => {
    if (configItem) {
      await updateConfig(configItem, account.selectedAccount.id);
      loadConfig();
    }
  };

  useEffect(() => {
    if (initialised.current) return;
    initialised.current = true;
    loadConfig();
  }, [account]);

  return {
    items: configItems,
    updateConfigItem,
  };
};
