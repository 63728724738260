import React, { useState, useEffect } from "react";
import { VStack, HStack, Text, Heading, Box } from "@chakra-ui/react";
import styles from "./InsightNumber.module.css";
import {
  InsightNumberProps,
  InsightTableProps,
} from "../../../../../../types/Insight";
import { DynamicTable } from "../../../../../../components/table/DynamicTable";

const InsightTable = ({
  data,
  demo,
  title,
}: {
  data: InsightTableProps;
  demo?: boolean;
  title: string;
}) => {
  if (data === undefined) return null;

  return (
    <Box>
      <Text
        zIndex={-11111}
        textAlign={"center"}
        size="sm"
        color="var(--text-primary)"
        transform={"translateY(-20px)"}
        maxW={"90%"}
        margin={"auto"}
      >
        {title}
      </Text>
      <VStack
        transform={"translateY(-30px)"}
        zIndex={-11111}
        spacing="0"
        h="97%"
        w="100%"
        justifyContent={"center"}
      >
        <DynamicTable data={data.rows} />
      </VStack>
    </Box>
  );
};

export default InsightTable;
