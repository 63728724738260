import { ConfigItem } from "../types";
import { APIEndpoint } from ".";

const token = localStorage.getItem("token");

export const getConfig = async (accountId: number): Promise<ConfigItem[]> => {
  const result = await fetch(`${APIEndpoint}/config`, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  });

  if (result.ok) {
    const data = await result.json();
    if (data.success && data.data) {
      return data.data as ConfigItem[];
    }
  }

  return [];
};

export const updateConfig = async (config: ConfigItem, accountId: number) => {
  return fetch(`${APIEndpoint}/config`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(config),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating config:", error));
};
