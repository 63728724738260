import { HStack, Tag, TagLabel } from "@chakra-ui/react";
import { motion } from "framer-motion";

export const FloatingIcons = ({
  textList,
}: {
  textList: { value: string; color: string; offset: number }[];
}) => {
  return (
    <HStack wrap={"wrap"} justifyContent={"center"}>
      {textList.map((text, index) => {
        return (
          <motion.div
            drag
            dragConstraints={{
              top: -50,
              left: -50,
              right: 50,
              bottom: 50,
            }}
            animate={{
              // scale: [1, 1.5, 1],
              rotate: [0, 0],
              translateY: [0, text.offset, 0],
            }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            <Tag
              mt="10px"
              bg={text.color}
              paddingLeft="4"
              paddingRight="4"
              paddingTop="2"
              paddingBottom="2"
              fontSize={"16px"}
              borderRadius="full"
              variant="solid"
            >
              <TagLabel>{text.value}</TagLabel>
            </Tag>
          </motion.div>
        );
      })}
    </HStack>
  );
};
