import { useEffect, useState } from "react";
import {
  Box,
  Center,
  Divider,
  HStack,
  Stack,
  StackDivider,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { Trade } from "../../types";
import moment from "moment";
import convertSymbolToReadable from "../grid/utils/convertSymbolToReadable";
import { useTransactions } from "../../hooks/useTransactions";
import { useAtomValue } from "jotai";
import { AccountAtom } from "../../store";
import { useDebounce } from "../../features/hooks";
import NoteEditor from "../texteditor/NoteEditor";
import React from "react";

interface NotesTimelineProps {
  trades: Trade[];
}

const CustomDivider = (props: any) => {
  const { thisTrade, lastTrade } = props;

  const daysBetween = moment
    .utc(thisTrade?.tradeDate)
    .diff(moment.utc(lastTrade?.tradeDate, "YYYY-MM-DDTHH:mm:ss.SSSZ"), "days");

  let firstTrade = false;
  let finalTrade = false;

  if (lastTrade === undefined) {
    firstTrade = true;
  }
  if (lastTrade === null) {
    finalTrade = true;
  }

  return (
    <HStack mt="20px">
      <Divider orientation="horizontal" />
      {daysBetween === 0 ? (
        <Text
          minW="80px"
          textAlign={"center"}
          color="var(--light-gray)"
          fontSize={"12px"}
        >
          Same day
        </Text>
      ) : (
        <Text
          minW="80px"
          textAlign={"center"}
          color="var(--light-gray)"
          fontSize={"12px"}
        >
          {firstTrade === false &&
            finalTrade === false &&
            daysBetween + " days"}
          {firstTrade && " First trade"}
          {finalTrade && " Last trade"}
        </Text>
      )}
      <Divider orientation="horizontal" />
    </HStack>
  );
};

export const NotesTimeline = (props: NotesTimelineProps) => {
  return (
    <Stack
      spacing="4"
      overflow={"scroll"}
      maxH="100%"
      minH="100%"
      pb="200px"
      borderBottom={"0px"}
    >
      {props.trades.map((trade, index) => {
        return (
          <Stack key={"note-post-" + index} fontSize="sm" px="4" spacing="0.5">
            <HStack wrap="wrap" alignItems={"flex-start"} display="flex">
              <Box flex="1" pt="4">
                <Text fontWeight="700" color="var(--grey)">
                  {convertSymbolToReadable(trade.instrument.symbol)}{" "}
                  {trade.quantity}{" "}
                  <span
                    style={{
                      color:
                        trade.buySell === "Buy" ? "var(--green)" : "var(--red)",
                    }}
                  >
                    {trade.buySell}
                  </span>{" "}
                  at {trade.price} {trade.currency}
                </Text>
                <Text color="var(--light-gray)">
                  Trade date: {moment(trade.tradeDate).calendar()}
                </Text>
              </Box>
              <Box w="full">
                <NoteEditor
                  trade={trade}
                  menuBarEnabled={false}
                  tagsEnabled={false}
                />
              </Box>
            </HStack>
            <CustomDivider
              thisTrade={trade}
              lastTrade={index > 0 ? props.trades[index + 1] : null}
            />
          </Stack>
        );
      })}
    </Stack>
  );
};

const NoteTextArea = (props: { trade: Trade }) => {
  const [note, setNote] = useState<string>("");

  useEffect(() => {
    setNote(props.trade.notes || "");
  }, [props.trade]);

  const transactions = useTransactions();
  const accountState = useAtomValue(AccountAtom);

  const updateTradeNote = async (trade: Trade, note: string) => {
    transactions.modifyNote(note, [trade], accountState?.selectedAccount?.id);
  };

  const onNoteFieldChange = useDebounce(updateTradeNote, 450);

  const handleKeyDown = (e) => {
    e.target.style.height = "inherit";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  return (
    <Textarea
      flex="4"
      onClick={(e) => handleKeyDown(e)}
      mt="2"
      border={"1px solid rgba(123,123,123,0.3)"}
      borderRadius={"4px"}
      onChange={(e) => {
        handleKeyDown(e);
        setNote(e.target.value);
        onNoteFieldChange(props.trade, e.target.value);
      }}
      color={"var(--light-gray)"}
      value={note || ""}
      placeholder="No notes recorded"
    />
  );
};
