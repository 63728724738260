import { useAtomValue } from "jotai";
import { AccountAtom, AppStateAtom } from "../store";
import { useEffect, useRef } from "react";
import { usePositions } from "./usePositions";

export interface UseInitialise {}

// This hook is used to initialise the app when the user is authenticated
export const useInitialise = (): UseInitialise => {
  const initialised = useRef(false);
  const appState = useAtomValue(AppStateAtom);
  const accountState = useAtomValue(AccountAtom);

  const positions = usePositions();

  const init = () => {
    positions.fetchPositions(accountState.selectedAccount?.id);
  };

  useEffect(() => {
    if (
      !initialised.current &&
      appState.authed &&
      accountState.selectedAccount?.id
    ) {
      initialised.current = true;
      init();
    }
  }, [appState.authed, accountState.selectedAccount]);

  return {};
};
