import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  getTradeConfirmationEmails,
  reprocessEmail,
} from "../../../api/TradeConfirmationEmails";
import moment from "moment";
import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
export default function TradeConfirmation() {
  const [emails, setEmails] = useState([]);
  const [htmlContent, setHtmlContent] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getConfirmations = async () => {
    setLoading(true);
    setTimeout(async () => {
      const fullListOfEmails = await getTradeConfirmationEmails();
      setEmails(fullListOfEmails.data);
      setLoading(false);
    }, 500);
  };

  useEffect(() => {
    getConfirmations();
  }, []);

  const reprocess = async (id: string) => {
    reprocessEmail(id);
    getConfirmations();
  };

  if (loading) {
    return <Text>Loading...</Text>;
  }

  return (
    <Box bg="var(--dark-gray)" p={4} borderRadius={8}>
      <Tabs h="full" w="full" isLazy={true}>
        <TabList>
          <Tab>Schwab</Tab>
          <Tab>Fidelity</Tab>
          <Tab>TBC</Tab>
        </TabList>

        <TabPanels h="calc(100% - 35px)">
          <TabPanel h="full" w="full">
            <CustomTabPanel
              emails={emails.filter((email) => email.broker === "SCHWAB")}
              setHtmlContent={setHtmlContent}
              onOpen={onOpen}
              reprocess={reprocess}
            />
          </TabPanel>
          <TabPanel h="full" w="full">
            <CustomTabPanel
              emails={emails.filter((email) => email.broker === "FIDELITY")}
              setHtmlContent={setHtmlContent}
              onOpen={onOpen}
              reprocess={reprocess}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody color="black" backgroundColor={"white"}>
            <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

const CustomTabPanel = ({ emails, setHtmlContent, onOpen, reprocess }) => {
  return (
    <TabPanel h="full" w="full">
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Broker</Th>
            <Th>User ID</Th>
            <Th>Account ID</Th>
            <Th>HTML</Th>
            <Td>Status</Td>
            <Td>Created At</Td>
            <Td>Reprocess</Td>
            <Td>Message</Td>
          </Tr>
        </Thead>
        <Tbody>
          {emails.map((email) => {
            return (
              <Tr key={email.id}>
                <Td>{email.broker}</Td>
                <Td>{email.userId}</Td>
                <Td>{email.accountId}</Td>
                <Td>
                  <Button
                    onClick={() => {
                      setHtmlContent(email.htmlContent);
                      onOpen();
                    }}
                  >
                    View
                  </Button>
                </Td>
                <Td>{email.status}</Td>
                <Td>{moment(email.createdAt).calendar()}</Td>
                <Td>
                  <Button
                    onClick={() => {
                      reprocess(email.id);
                    }}
                  >
                    Reprocess
                  </Button>
                </Td>
                <Td>{email.errorMessage}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TabPanel>
  );
};
