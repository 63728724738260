import { APIEndpoint } from ".";
import { CashFlow } from "../types/Cashflow";

const token = localStorage.getItem("token");

// Create a new cash flow
export const createCashFlow = async (cashFlow: CashFlow, accountId: number) => {
  return fetch(`${APIEndpoint}/cashflows`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(cashFlow),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating cash flow:", error));
};

// Bulk create new cash flows
export const createManyCashFlows = async (
  cashFlows: CashFlow[],
  accountId: number
) => {
  return fetch(`${APIEndpoint}/cashflows/bulk`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(cashFlows),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error creating cash flows:", error));
};

// Read (get) all cash flows
export const getCashFlows = async (accountId: number) => {
  return fetch(`${APIEndpoint}/cashflows`, {
    method: "GET",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error getting cash flows:", error));
};

// Update an existing cash flow
export const updateCashFlow = async (
  cashFlowId: number,
  cashFlow: CashFlow,
  accountId: number
) => {
  return fetch(`${APIEndpoint}/cashflows/${cashFlowId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token || "",
      AccountId: accountId as any,
    },
    body: JSON.stringify(cashFlow),
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error updating cash flow:", error));
};

// Delete an existing cash flow
export const deleteCashFlow = async (cashFlowId: number, accountId: number) => {
  return fetch(`${APIEndpoint}/cashflows/${cashFlowId}`, {
    method: "DELETE",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error deleting cash flow:", error));
};

// Delete all cash flows
export const deleteAllCashFlows = async (accountId: number) => {
  return fetch(`${APIEndpoint}/cashflows`, {
    method: "DELETE",
    headers: {
      Authorization: token || "",
      AccountId: accountId as any,
    },
  })
    .then((response) => response.json())
    .catch((error) => console.error("Error deleting all cash flows:", error));
};
