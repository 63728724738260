import { useState } from "react";
import { Brokers, SyncSetting } from "../../types";

export interface AddOrEditConfigurationProps {
  settings?: SyncSetting;
  close: () => void;
}

interface useAddOrEditConfigurationControllerResponse {
  new: boolean;
  settings?: SyncSetting;
  selectedBroker?: Brokers;
  setSelectedBroker: (broker: Brokers) => void;
}

export const useAddOrEditConfigurationController = (
  props: AddOrEditConfigurationProps
): useAddOrEditConfigurationControllerResponse => {
  const [selectedBroker, setSelectedBroker] = useState<Brokers | undefined>(
    props.settings?.broker
  );

  return {
    new: props.settings === undefined,
    settings: props.settings,
    selectedBroker,
    setSelectedBroker,
  };
};
