import React, { useCallback, useEffect, useMemo } from "react";
import { Text, Flex, HStack, Switch, Spacer } from "@chakra-ui/react";
import { Instrument, InstrumentType } from "../../../../types";
import { TbArrowBigRightFilled } from "react-icons/tb";
import moment from "moment";
import { Show, GridContainer, columnTypes } from "../../../../components";
import { AgGridReact } from "ag-grid-react";
import { columnDefinitions } from "./columnDefinitions";
import { RowSelectedEvent } from "ag-grid-community";

interface InstrumentListProps {
  parent?: Instrument;
  children?: Instrument[];
  selectInstrument: (instrument: Instrument) => void;
}

export const InstrumentList = (props: InstrumentListProps) => {
  const [live, setLive] = React.useState(true);

  const [selectedInstrument, setSelectedInstrument] =
    React.useState<Instrument>();

  const instruments = useMemo(() => {
    if (props.children === undefined) return [props.parent];
    return [
      props.parent,
      ...props.children.filter((instrument) => {
        if (instrument.instrumentType !== InstrumentType.Option) return false;
        if (live) {
          return (
            instrument.expiry &&
            moment(instrument.expiry).endOf("day").isAfter(moment())
          );
        }
        return true;
      }),
    ];
  }, [props.children, live]);

  useEffect(() => {
    if (props.selectInstrument && selectedInstrument) {
      props.selectInstrument(selectedInstrument);
    }
  }, [selectedInstrument]);

  useEffect(() => {
    setSelectedInstrument(props.parent);
  }, [props.parent, props.children]);

  return (
    <Flex h="100%" w="100%" direction="column">
      <HStack py={2}>
        <Text>Instruments ({instruments.length ?? 0})</Text>
        <Spacer />
        <Switch
          id="isChecked"
          colorScheme="green"
          isChecked={live}
          onChange={(e: any) => {
            setLive(!live);
          }}
          size="sm"
          mr={2}
        >
          {live ? "Live Options" : "All Options"}
        </Switch>
      </HStack>

      <GridContainer>
        <AgGridReact
          rowData={instruments} // Row Data for Rows
          columnDefs={columnDefinitions} // Column Defs for Columns
          columnTypes={columnTypes} // Optional - custom column types
          suppressMultiSort={false}
          suppressAggFilteredOnly={false}
          rowSelection="single"
          rowHeight={31}
          suppressAggFuncInHeader={true}
          getRowId={(params) => {
            return params.data?.id;
          }}
          onRowSelected={(e: RowSelectedEvent) => {
            if (e.node.isSelected()) {
              setSelectedInstrument(e.node.data as Instrument);
            }
          }}
          onGridReady={(params) => {
            params.api.autoSizeAllColumns();
          }}
          suppressChangeDetection={false}
          onFirstDataRendered={(params) => {
            params.api.expandAll();
            params.api.sizeColumnsToFit();
          }}
        />
      </GridContainer>
    </Flex>
  );
};
