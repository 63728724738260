import { useEffect, useState } from "react";
import {
  getAllPricingErrors,
  getCurrentPricingIssues,
  getEODPricingIssues,
} from "../../../../api";
import { PricingError, PricingIssue } from "../../../../types";

interface UseInstrumentIssuesController {
  missingPrices: PricingIssue[];
  missingEODPrices: PricingIssue[];
  pricingErrors: PricingError[];
}

interface LocalState {
  missingPrices: PricingIssue[];
  missingEODPrices: PricingIssue[];
  pricingErrors: PricingError[];
}

export const useInstrumentIssuesController =
  (): UseInstrumentIssuesController => {
    const [issues, setIssues] = useState<LocalState>({
      missingPrices: [],
      missingEODPrices: [],
      pricingErrors: [],
    });

    const loadIssues = async () => {
      const errors = await getAllPricingErrors();
      const missingPrices = await getCurrentPricingIssues();
      const missingEODPrices = await getEODPricingIssues();
      setIssues({
        missingPrices: missingPrices ?? [],
        missingEODPrices: missingEODPrices ?? [],
        pricingErrors: errors ?? [],
      });
    };

    useEffect(() => {
      loadIssues();
    }, []);

    return {
      missingPrices: issues.missingPrices,
      missingEODPrices: issues.missingEODPrices,
      pricingErrors: issues.pricingErrors,
    };
  };
